import React, { useEffect, useState } from "react";
import API from "../components/api2";
import { circles } from "../images";
import Header from "../components/Header";
import {
  aepsToBank,
  aepsToMain,
  fetchPayoutsTrans,
  getWallet,
  updatePayoutsTrans,
  verifyMerchBankList,
} from "../urls";
import Button from "../components/Button";
import Swal from "sweetalert2";
import loaderGif from "../gif/buffer.gif";
import Select from "react-select";
import { GrPowerReset } from "react-icons/gr";
import { FaTimes, FaCheck } from "react-icons/fa";
import Modal from "react-modal";
import { GrFormView } from "react-icons/gr";

const AepsWalletTransfer = () => {
  const [aepsBalance, setAepsBalance] = useState(0);
  const [transferOption, setTransferOption] = useState("");
  const [amount, setAmount] = useState("");
  const [isAmountInputVisible, setIsAmountInputVisible] = useState(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBankSelectorVisible, setIsBankSelectorVisible] = useState(false);
  const [bankSelection, setBankSelection] = useState("");
  const [bankName, setBankName] = useState([]);
  const [selectedResId, setSelectedResId] = useState(null);
  const [paymentMode, setPaymentMode] = useState("");
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalData, setModalData] = useState(null);

  // Fetch balance
  useEffect(() => {
    API({
      ...getWallet,
      onSuccess: (res) => {
        const aepsClosingBalance =
          parseFloat(res.data.data.aeps_closing_balance) || 0;
        setAepsBalance(aepsClosingBalance);
      },
      onError: (error) => {
        console.error("Error fetching balance: ", error);
      },
    });
  }, []);

  const handleViewDetails = (res_id) => {
    setSelectedRow(true);
    setModalData(null);
    API({
      ...fetchPayoutsTrans,
      params: { res_id: res_id },
      onSuccess: (res) => {
        const data = res.data.data;
        setModalData(data);
      },
      onError: (error) => {
        console.error("Error fetching payouts: ", error);
      },
    });
  };

  // fetch payouts
  const fetchPayout = (limit, offset, reset = false) => {
    setLoading(true);
    API({
      ...fetchPayoutsTrans,
      params: { limit, offset },
      onSuccess: (res) => {
        const newData = res?.data?.data?.table_data || [];
        setTableData((prevData) =>
          reset ? newData : [...prevData, ...newData]
        );
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching payouts: ", error);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchPayout(10, 0, true);
  }, []);

  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + 10);
    fetchPayout(10, offset + 10);
  };

  const handleUpdatePayout = (res_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the payout for this transaction?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        API({
          ...updatePayoutsTrans,
          body: { res_id },
          onSuccess: (res) => {
            setIsLoading(false);
            Swal.fire({
              title: `${res.data.message}`,
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          },
          onError: (error) => {
            setIsLoading(false);
            Swal.fire({
              icon: "error",
              title: error.response.data.message,
            });
          },
        });
      }
    });
  };

  const handleTransferOptionChange = (e) => {
    setTransferOption(e.target.value);
    setIsAmountInputVisible(false);
    setIsBankSelectorVisible(false);
    setBankSelection("");
    setSelectedResId(null);
  };

  const handleSelectClick = () => {
    if (transferOption === "bank") {
      setIsBankSelectorVisible(true);
    } else {
      setIsAmountInputVisible(true);
    }
  };

  const handleBankChange = (selectedOption) => {
    setBankSelection(selectedOption ? selectedOption.value : "");
    setSelectedResId(selectedOption ? selectedOption.res_id : null);
    setIsAmountInputVisible(true);
    const bankName = selectedOption?.ben_bank_name?.toLowerCase();
    if (bankName && bankName.includes("rbl")) {
      setPaymentMode("FT");
    }
    if (paymentMode === "FT") {
      setPaymentMode("NEFT");
    }
  };

  const handleBackClick = () => {
    setIsAmountInputVisible(false);
    setIsBankSelectorVisible(false);
    setBankSelection(null);
    setSelectedResId(null);
    setPaymentMode("");
    setAmount("");
  };

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  };

  const handleAmountChange = (e) => {
    const enteredAmount = parseFloat(e.target.value);
    setAmount(e.target.value);
    setIsConfirmEnabled(enteredAmount > 9 && enteredAmount <= aepsBalance);
  };

  const handleConfirmTransfer = () => {
    Swal.fire({
      title: "Please confirm to continue",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enter your 6-digit UPIN",
          input: "password",
          inputAttributes: {
            maxlength: 6,
            minlength: 6,
            inputMode: "numeric",
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          preConfirm: (upin) => {
            if (!/^\d{6}$/.test(upin)) {
              Swal.showValidationMessage("Please enter a valid 6-digit UPIN");
              return false;
            }
            return upin;
          },
        }).then((result) => {
          if (result.isConfirmed && result.value) {
            const upin = result.value;

            if (transferOption === "bank") {
              transferToBank(upin);
            } else {
              transferToMain(upin);
            }
          }
        });
      }
    });
  };

  const transferToBank = (upin) => {
    setIsLoading(true);

    const requestBody = {
      transaction_amount: parseInt(amount, 10),
      upin: upin,
      res_id: selectedResId,
      payment_mode: paymentMode,
    };

    API({
      ...aepsToBank,
      body: requestBody,
      onSuccess: (res) => {
        setIsLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const transferToMain = (upin) => {
    setIsLoading(true);
    API({
      ...aepsToMain,
      body: { transaction_amount: amount, upin: upin },
      onSuccess: (res) => {
        setIsLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // get logged user banks
  useEffect(() => {
    API({
      ...verifyMerchBankList,
      onSuccess: (res) => {
        const bankOptions = res?.data?.data?.map((bank) => ({
          value: bank.ben_account_number,
          label: bank.ben_account_number,
          res_id: bank.res_id,
          ben_bank_name: bank.ben_bank_name,
        }));

        setBankName(bankOptions.filter((option) => option.value !== ""));
      },
      onError: (error) => {
        console.error("Error fetching accounts: ", error);
      },
    });
  }, []);

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            AEPS Fund Settlement
          </h2>
        </div>
        {isLoading ? (
          <div
            className="loading-container"
            style={{ textAlign: "center", marginTop: "100px" }}
          >
            <img
              src={loaderGif}
              alt="loading"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        ) : (
          <>
            <div className="row">
              <div
                className={`${
                  !isAmountInputVisible && !isBankSelectorVisible
                    ? "col-5"
                    : "col-12"
                }`}
              >
                {/* Wallet info */}
                <div className="mt-4 px-5">
                  <h3
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                  >
                    AEPS Wallet Information :
                  </h3>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                    className="mb-0 mt-4"
                  >
                    AEPS Wallet Balance :{" "}
                    <span
                      className="ms-2"
                      style={{ color: "#AD8352", fontFamily: "Montserrat" }}
                    >
                      Rs {aepsBalance}
                    </span>
                  </p>
                </div>

                {/* Transfer money option */}
                <div className="mt-5 px-5">
                  {!isAmountInputVisible && !isBankSelectorVisible && (
                    <>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                        className="mb-0 mt-4"
                      >
                        Select where to transfer the money :
                      </p>
                      <div className="mt-4">
                        <label
                          style={{
                            color: "#696666",
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          className="d-flex align-items-center"
                        >
                          <input
                            type="radio"
                            value="bank"
                            checked={transferOption === "bank"}
                            onChange={handleTransferOptionChange}
                            className="me-3"
                          />
                          Move to Bank
                        </label>
                        <label
                          style={{
                            color: "#696666",
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          className="d-flex align-items-center mt-3"
                        >
                          <input
                            type="radio"
                            value="mainWallet"
                            checked={transferOption === "mainWallet"}
                            onChange={handleTransferOptionChange}
                            className="me-3"
                          />
                          Move to Main Wallet
                        </label>
                      </div>
                    </>
                  )}

                  {isBankSelectorVisible && (
                    <>
                      {/* Payment Mode Selection */}
                      <div className="row">
                        <div className="col">
                          <label
                            style={{
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#696666",
                            }}
                          >
                            Payment Mode:
                          </label>
                          <div className="row mt-1">
                            <>
                              <div className="col-4">
                                <input
                                  type="radio"
                                  id="neft"
                                  name="paymentMode"
                                  value="NEFT"
                                  onChange={handlePaymentModeChange}
                                  disabled={paymentMode === "FT"}
                                />
                                <label
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                  }}
                                  className="ms-2"
                                  htmlFor="neft"
                                >
                                  NEFT
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  type="radio"
                                  id="imps"
                                  name="paymentMode"
                                  value="IMPS"
                                  onChange={handlePaymentModeChange}
                                  disabled={paymentMode === "FT"}
                                />
                                <label
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                  }}
                                  className="ms-2"
                                  htmlFor="imps"
                                >
                                  IMPS
                                </label>
                              </div>
                            </>
                            {paymentMode === "FT" && (
                              <div className="col-4">
                                <input
                                  type="radio"
                                  id="fundTransfer"
                                  name="paymentMode"
                                  value="FT"
                                  onChange={handlePaymentModeChange}
                                  checked
                                  disabled
                                />
                                <label
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                  }}
                                  className="ms-2"
                                  htmlFor="fundTransfer"
                                >
                                  Fund Transfer
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {(paymentMode === "NEFT" ||
                        paymentMode === "IMPS" ||
                        paymentMode === "FT") && (
                        <>
                          <div className="row mt-4 align-items-center">
                            <div className="col-6">
                              <label
                                style={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  color: "#696666",
                                }}
                              >
                                Select Account Number:
                              </label>
                              <Select
                                placeholder="Select account"
                                options={bankName}
                                value={
                                  bankName.find(
                                    (b) => b.value === bankSelection
                                  ) || null
                                }
                                onChange={handleBankChange}
                                getOptionLabel={(e) =>
                                  `${e.label} (${e.ben_bank_name})`
                                }
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "rgba(255, 231, 159,0.4)",
                                    borderRadius: "10px",
                                    border: "none",
                                    boxShadow: "none",
                                    "&:focus": {
                                      outline: "none",
                                    },
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {(paymentMode === "NEFT" ||
                    isAmountInputVisible ||
                    paymentMode === "IMPS" ||
                    paymentMode === "FT") && (
                    <>
                      <label
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#696666",
                        }}
                        className="mb-0 mt-4"
                      >
                        Enter Amount to Transfer
                      </label>
                      <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        className="form-control mt-2"
                        placeholder="Amount"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "15px",
                        }}
                      />
                      <p
                        style={{
                          color: "#696666",
                          fontSize: "13px",
                          marginTop: "5px",
                        }}
                      >
                        Note: Amount should be more than Rs 9 and less than or
                        equal to the AEPS Wallet balance.
                      </p>
                    </>
                  )}
                </div>

                <div className="mt-4 px-5">
                  <div className="d-flex justify-content-between  align-items-center mt-4">
                    {isAmountInputVisible || isBankSelectorVisible ? (
                      <Button children="Back" onClick={handleBackClick} />
                    ) : null}

                    <Button
                      children={
                        isAmountInputVisible || isBankSelectorVisible
                          ? "Confirm Transfer"
                          : "Select"
                      }
                      onClick={
                        isAmountInputVisible
                          ? handleConfirmTransfer
                          : handleSelectClick
                      }
                      disabled={
                        (!isAmountInputVisible &&
                          !isBankSelectorVisible &&
                          !transferOption) ||
                        (isAmountInputVisible && !isConfirmEnabled) ||
                        (isBankSelectorVisible && !bankSelection)
                      }
                    />
                  </div>
                </div>
              </div>
              {!isAmountInputVisible && !isBankSelectorVisible && (
                <div className="col-7 mt-4">
                  <h3
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                  >
                    Payout Transactions
                  </h3>
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100vh - 290px)",
                    }}
                  >
                    <table className="table table-striped table-hover">
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#194280",
                          color: "#fff",
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "600",
                          textAlign: "center",
                          border: "1px solid black",
                          zIndex: 1000,
                        }}
                      >
                        <tr>
                          <th>Merchant ID</th>
                          <th>Amount</th>
                          <th>Mode</th>
                          <th>Status</th>
                          <th colSpan={2}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.length === 0 ? (
                          <tr>
                            <td
                              colSpan="5"
                              style={{ textAlign: "center", color: "#9E9E9E" }}
                            >
                              No transactions found
                            </td>
                          </tr>
                        ) : (
                          tableData?.map((row, index) => (
                            <tr
                              key={row.res_id}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  index % 2 === 0 ? "#f9f9f9" : "#fff",
                                fontFamily: "Montserrat, sans-serif",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              <td>{row.merchant_id}</td>
                              <td>{row.amount}</td>
                              <td>{row.mode}</td>
                              <td>
                                <span
                                  style={{
                                    backgroundColor:
                                      row.status === "FAILED"
                                        ? "#FFCDD2"
                                        : row.status === "Failure"
                                        ? "#FFCDD2"
                                        : row.status === "reversed"
                                        ? "#FFEBEE"
                                        : row.status === "processed"
                                        ? "#E8F5E9"
                                        : row.status === "success"
                                        ? "#C8E6C9"
                                        : "#FFECB3",
                                    color:
                                      row.status === "FAILED"
                                        ? "#B71C1C"
                                        : row.status === "reversed"
                                        ? "#C62828"
                                        : row.status === "Failure"
                                        ? "#B71C1C"
                                        : row.status === "processed"
                                        ? "#1B5E20"
                                        : row.status === "success"
                                        ? "#2E7D32"
                                        : "#BF360C",
                                    padding: "5px 10px",
                                    borderRadius: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  {row.status === "reversed"
                                    ? "failed"
                                    : row.status}
                                </span>
                              </td>
                              <td>
                                {row.status === "reversed" ||
                                row.status === "Failure" ||
                                row.status === "FAILED" ? (
                                  <FaTimes
                                    style={{
                                      color: "#C62828",
                                      fontSize: "20px",
                                    }}
                                  />
                                ) : row.status === "processed" ||
                                  row.status === "success" ? (
                                  <FaCheck
                                    style={{
                                      color: "#1B5E20",
                                      fontSize: "20px",
                                    }}
                                  />
                                ) : (
                                  <GrPowerReset
                                    style={{
                                      cursor: "pointer",
                                      color: "#194280",
                                      fontSize: "20px",
                                    }}
                                    onClick={() =>
                                      handleUpdatePayout(row.res_id)
                                    }
                                  />
                                )}
                              </td>
                              <td>
                                <GrFormView
                                  style={{
                                    color: "#194280",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleViewDetails(row.res_id)}
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      {!loading && tableData.length > 0 && (
                        <p
                          className="mb-0"
                          style={{
                            fontFamily: "Montserrat",
                            color: "#194280",
                            cursor: "pointer",
                          }}
                          onClick={handleLoadMore}
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Load More"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={!!selectedRow}
        onRequestClose={() => setSelectedRow(null)}
        contentLabel="Transaction Details"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px",
            padding: "30px",
            backgroundColor: "#fff",
            zIndex: 1050,
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1040,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #e0e0e0",
            paddingBottom: "15px",
            marginBottom: "25px",
          }}
        >
          <h2
            style={{
              fontSize: "24px",
              margin: 0,
              color: "#333",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
            }}
          >
            Transaction Details
          </h2>
          <button
            style={{
              background: "none",
              border: "none",
              fontSize: "24px",
              color: "#888",
              cursor: "pointer",
              padding: "5px",
              borderRadius: "50%",
              transition: "color 0.2s",
            }}
            onClick={() => setSelectedRow(null)}
            onMouseEnter={(e) => (e.target.style.color = "#ff4747")}
            onMouseLeave={(e) => (e.target.style.color = "#888")}
          >
            &times;
          </button>
        </div>

        <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
          {modalData ? (
            Object.keys(modalData).map((key, index) => (
              <div
                className="col-6"
                key={key}
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "black",
                    borderRight: "1px solid #f0f0f0",
                  }}
                >
                  {key.toUpperCase().replace(/_/g, " ")} :
                </div>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#333",
                    paddingLeft: "15px",
                  }}
                >
                  {modalData[key] !== "" && modalData[key] !== null
                    ? key === "fees" || key === "amount" || key === "tax"
                      ? `₹ ${modalData[key]}`
                      : modalData[key]
                    : "N/A"}
                </div>
              </div>
            ))
          ) : (
            <p style={{ textAlign: "center", color: "#888", fontSize: "16px" }}>
              Loading...
            </p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AepsWalletTransfer;
