import React, { useState } from "react";
import Select from "react-select";
import passengerIcon from "../../../../images/services/travel/passengerIcon.svg";
import InputForm from "../../../../components/InputForm";
import API from "../../../../components/api2";
import { passengerDetails } from "../../../../urls";
import tripRender from "../../../../gif/busSearch.gif";
import Swal from "sweetalert2";
import BookTicket from "./BookTicket";
import { IoMdArrowRoundBack } from "react-icons/io";

const PassengerDetails = ({
  tripId,
  selectedBoardingPoint,
  selectedDroppingPoint,
  sourceId,
  destinationId,
  selectedSeatsDetails,
  setProceedToPay,
}) => {
  const initialFormData = selectedSeatsDetails.map(() => ({
    name: "",
    age: "",
    address: "",
    gender: null,
    idType: null,
    idNumber: "",
  }));

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [commonMobile, setCommonMobile] = useState("");
  const [commonEmail, setCommonEmail] = useState("");

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const idTypeOptions = [
    { value: "Pancard", label: "Pancard" },
    { value: "Aadhaar", label: "Aadhaar" },
    { value: "Passport", label: "Passport" },
    { value: "VoterId", label: "Voter ID" },
  ];
  const handleInputChange = (e, index) => {
    const { id, value } = e.target;

    if (id === "mobile" && value.length > 10) {
      return;
    }

    if (id === "mobile" && isNaN(value)) {
      return;
    }

    const newFormData = [...formData];
    newFormData[index][id] = value;
    setFormData(newFormData);
  };

  const handleGenderChange = (selectedOption, index) => {
    const newFormData = [...formData];
    newFormData[index].gender = selectedOption;
    setFormData(newFormData);
  };

  const handleIdTypeChange = (selectedOption, index) => {
    const newFormData = [...formData];
    newFormData[index].idType = selectedOption;
    setFormData(newFormData);
  };

  const isFormValid = () => {
    return (
      commonMobile &&
      commonEmail &&
      formData.every((passenger) => {
        return (
          passenger.name &&
          passenger.age &&
          passenger.address &&
          passenger.gender &&
          passenger.idType &&
          passenger.idNumber
        );
      })
    );
  };
  const handleSubmit = () => {
    setLoading(true);
    const passengerData = {
      availableTripId: tripId.tripId,
      boardingPointId: selectedBoardingPoint,
      droppingPointId: selectedDroppingPoint,
      bus_name: tripId.travels,
      bus_type: tripId.busType,
      source: sourceId,
      destination: destinationId,
      inventoryItems: selectedSeatsDetails.map((seat, index) => ({
        seatName: seat.name,
        fare: seat.fare,
        serviceTax: seat.serviceTax,
        operatorServiceCharge: seat.operatorServiceChargeAbsolute,
        ladiesSeat: seat.ladiesSeat,
        passenger: {
          name: formData[index].name,
          mobile: commonMobile,
          email: commonEmail,
          age: formData[index].age,
          gender: formData[index].gender ? formData[index].gender.value : "",
          address: formData[index].address,
          primary: index === 0 ? "1" : "0",
          idType: formData[index].idType ? formData[index].idType.value : "",
          idNumber: formData[index].idNumber,
          title:
            formData[index].gender?.value === "Male"
              ? "Mr."
              : formData[index].gender?.value === "Female"
              ? "Mrs."
              : "Mx.",
        },
      })),
      serviceCharge: "0.00",
    };

    API({
      ...passengerDetails,
      body: passengerData,
      onSuccess: (res) => {
        setLoading(false);
        setResponseData(res.data.data);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          setIsSuccess(true);
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <div className="mt-3">
      {isSuccess ? (
        <BookTicket
          responseData={responseData}
          setIsSuccess={setIsSuccess}
          selectedSeatsDetails={selectedSeatsDetails}
          commonMobile={commonMobile}
          commonEmail={commonEmail}
        />
      ) : (
        <>
          <div
            style={{ cursor: "pointer" }}
            className="d-flex align-items-center justify-content-end"
            onClick={() => setProceedToPay(false)}
          >
            <IoMdArrowRoundBack />
            <p className="mb-0 ms-1 fw-bold">Select Seats</p>
          </div>
          {/* <h2
            className="text-center"
            style={{
              fontSize: "24px",
              fontWeight: "500",
              textDecoration: "underline",
            }}
          >
            Passenger Details
          </h2> */}
          {loading ? (
            <div className="text-center">
              <img
                src={tripRender}
                alt="Loading..."
                style={{ width: "400px" }}
              />
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center  mb-2">
                <img src={passengerIcon} alt="Passenger Icon" />
                <p
                  className="mb-0 ms-2"
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Passenger Information
                </p>
              </div>

              <div
                style={{ maxHeight: "calc(100vh - 330px)", overflowY: "auto" }}
                className="row align-items-center mt-2"
              >
                <form className="col-12">
                  <div className="row mb-2 p-2 mb-0">
                    <div className="col-4">
                      <label
                        className="mb-2"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgba(105, 102, 102, 1)",
                          fontFamily: "Montserrat",
                        }}
                        htmlFor="common-mobile"
                      >
                        Enter Mobile
                      </label>
                      <input
                        className="p-2"
                        style={{
                          border: "1px solid #ADACAC",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        type="number"
                        id="common-mobile"
                        autoComplete="off"
                        placeholder="Enter Mobile"
                        value={commonMobile}
                        maxLength={10}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          if (value.length <= 10) {
                            setCommonMobile(value);
                          }
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <label
                        className="mb-2"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgba(105, 102, 102, 1)",
                          fontFamily: "Montserrat",
                        }}
                        htmlFor="common-email"
                      >
                        Enter Email
                      </label>
                      <input
                        className="p-2"
                        style={{
                          border: "1px solid #ADACAC",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        type="email"
                        id="common-email"
                        autoComplete="off"
                        placeholder="Enter Email"
                        value={commonEmail}
                        onChange={(e) => setCommonEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  {selectedSeatsDetails.map((seat, index) => (
                    <div
                      key={index}
                      className={`row p-2 mb-0 ${index >= 1 ? "mt-1" : ""}`}
                    >
                      <h4
                        className="mb-0"
                        style={{ color: "#696666", fontSize: "17px" }}
                      >
                        Passenger {index + 1}{" "}
                        <span style={{ fontSize: "20px" }}>|</span> Seat No.{" "}
                        {seat.name}
                      </h4>

                      {[
                        {
                          field: "name",
                          type: "text",
                          placeholder: "Enter Name",
                        },
                        {
                          field: "age",
                          type: "number",
                          placeholder: "Enter Age",
                        },
                        {
                          field: "address",
                          type: "text",
                          placeholder: "Enter Address",
                        },
                        {
                          field: "gender",
                          type: "select",
                          placeholder: "Select Gender",
                          options: genderOptions,
                        },
                        {
                          field: "idType",
                          type: "select",
                          placeholder: "Select ID Type",
                          options: idTypeOptions,
                        },
                        {
                          field: "idNumber",
                          type: "text",
                          placeholder: "Enter ID Number",
                        },
                      ].map(({ field, type, placeholder, options }) => {
                        if (type === "select") {
                          return (
                            <div className="col-2" key={field}>
                              <label
                                className="mb-2"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "rgba(105, 102, 102, 1)",
                                  fontFamily: "Montserrat",
                                  marginTop: "9px",
                                }}
                                htmlFor={`${field}-${index}`}
                              >
                                {placeholder}
                              </label>
                              <Select
                                id={`${field}-${index}`}
                                options={options}
                                value={formData[index][field]}
                                onChange={(option) =>
                                  field === "gender"
                                    ? handleGenderChange(option, index)
                                    : handleIdTypeChange(option, index)
                                }
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "1px solid #ADACAC",
                                    borderRadius: "10px",
                                    boxShadow: "none",
                                  }),
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    position: "absolute",
                                    top: "100%",
                                    left: "0",
                                    right: "0",
                                    zIndex: 9999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                placeholder={placeholder}
                              />
                            </div>
                          );
                        }

                        return (
                          <div className="col-2 mb-2" key={field}>
                            <label
                              className="mb-2"
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "rgba(105, 102, 102, 1)",
                                fontFamily: "Montserrat",
                                marginTop: "9px",
                              }}
                              htmlFor={`${field}-${index}`}
                            >
                              {placeholder}
                            </label>
                            <input
                              className="p-2"
                              style={{
                                border: "1px solid #ADACAC",
                                width: "100%",
                                borderRadius: "10px",
                              }}
                              type={type}
                              id={field}
                              autoComplete="off"
                              placeholder={placeholder}
                              value={formData[index][field]}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </form>
              </div>

              <div className="text-center mt-3">
                <button
                  className="px-3 py-2 border-0 text-white"
                  style={{
                    backgroundColor: "#FFA201",
                    borderRadius: "10px",
                    fontWeight: "500",
                    opacity: isFormValid() ? 1 : 0.5,
                    pointerEvents: isFormValid() ? "auto" : "none",
                  }}
                  onClick={handleSubmit}
                  disabled={!isFormValid() || loading}
                >
                  Proceed To Book
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PassengerDetails;
