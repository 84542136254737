import React, { useState, useEffect } from "react";
import API from "../../components/api2";
import { addMerchBank, dmtBankList, dmtIfscList } from "../../urls";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { circles } from "../../images";
import { useNavigate } from "react-router-dom";
import InputForm from "../../components/InputForm";
import Swal from "sweetalert2";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";

const AddAccount = () => {
  const defaultPageData = {
    ben_name: "",
    ben_bank_name: "",
    ben_ifsc: "",
    ben_account_number: "",
    mobile_number: "",
  };

  const [pageData, setPageData] = useState(defaultPageData);
  const [bankList, setBankList] = useState([]);
  const [ifscList, setIfscList] = useState([]);
  const [ifscLoading, setIfscLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageDataFunc = (event) => {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    API({
      ...dmtBankList,
      onSuccess: (res) => {
        const data = res.data?.data || [];
        const bankOptions = data.map((bank) => ({
          value: bank.bank_id,
          label: bank.bank_name,
        }));
        setBankList(bankOptions);
      },
      onError: (error) => console.error("Error fetching bank list:", error),
    });
  }, []);

  const handleBankSelectChange = (selectedOption) => {
    setPageData((prev) => ({
      ...prev,
      ben_bank_name: selectedOption.label,
      ben_ifsc: "",
    }));
    setIfscList([]);
    setIfscLoading(true);

    Swal.fire({
      title: "Loading IFSC codes...",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    const loaderTimeout = setTimeout(() => {
      setIfscLoading(false);
      Swal.close();
    }, 10000);

    API({
      ...dmtIfscList,
      params: { bank: selectedOption.label },
      onSuccess: (res) => {
        clearTimeout(loaderTimeout);
        const data = res.data?.data || [];
        setIfscList(data.map((i) => ({ value: i.ifsc, label: i.ifsc })));
        Swal.close();
        setIfscLoading(false);
      },
      onError: (error) => {
        clearTimeout(loaderTimeout);
        // console.error("Error fetching IFSC list:", error);
        Swal.close();
        setIfscLoading(false);
      },
    });
  };

  const handleIfscSelectChange = (selectedOption) => {
    setPageData((prev) => ({ ...prev, ben_ifsc: selectedOption.value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = {
      ben_name: pageData.ben_name,
      ben_ifsc: pageData.ben_ifsc,
      ben_bank_name: pageData.ben_bank_name,
      ben_account_number: pageData.ben_account_number,
      mobile_number: pageData.mobile_number,
    };

    API({
      ...addMerchBank,
      body: payload,
      onSuccess: (res) => {
        Swal.fire({ title: res.data.message }).then(() => {
          setPageData(defaultPageData);
          handleBack();
          setIsSubmitting(false);
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: error.response?.data?.message || "An error occurred",
        });
        setIsSubmitting(false);
      },
    });
  };

  const handleBack = () => {
    dispatch(changePage({ page: "allAccounts" }));
    navigate("/");
  };

  const allFieldsFilled = Object.values(pageData).every(
    (val) => val.trim() !== ""
  );

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 pt-4 me-2 position-relative overflow-y-auto">
        <img
          src={circles}
          alt="circles"
          style={{ position: "absolute", right: 0, top: 0 }}
        />
        <h2
          className="pb-3 mb-0"
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Beneficiary
        </h2>

        {/* Form Fields */}
        <div className="row mt-4">
          <div className="col-6 mb-3">
            <InputForm
              type="text"
              placeholder="Beneficiary Name"
              value={pageData.ben_name}
              onChange={pageDataFunc}
              id="ben_name"
            />
          </div>
          <div className="col-6">
            <label className="trans_text mt-2 mb-2">
              Beneficiary Bank Name
            </label>
            <Select
              options={bankList}
              value={bankList.find(
                (option) => option.label === pageData.ben_bank_name
              )}
              onChange={handleBankSelectChange}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  border: "0",
                  boxShadow: state.isFocused ? "none" : "none",
                }),
              }}
              isDisabled={ifscLoading}
              placeholder="Select Bank"
            />
          </div>
          <div className="col-6">
            <label className="trans_text mt-2 mb-2">
              Beneficiary IFSC Code
            </label>
            <Select
              options={ifscList}
              value={ifscList.find(
                (option) => option.value === pageData.ben_ifsc
              )}
              onChange={handleIfscSelectChange}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  border: "0",
                  boxShadow: state.isFocused ? "none" : "none",
                }),
              }}
              isDisabled={ifscLoading || !pageData.ben_bank_name}
              placeholder="Select IFSC"
            />
          </div>
          <div className="col-6">
            <InputForm
              type="number"
              placeholder="Beneficiary Account Number"
              value={pageData.ben_account_number}
              onChange={pageDataFunc}
              id="ben_account_number"
              pattern="[0-9]*"
              maxLength={20}
            />
          </div>
          <div className="col-6 mt-3">
            <InputForm
              type="number"
              placeholder="Mobile Number"
              value={pageData.mobile_number}
              onChange={pageDataFunc}
              id="mobile_number"
              pattern="[0-9]*"
              maxLength={10}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between my-4">
          <Button onClick={handleBack}>Back</Button>
          <Button
            onClick={onSubmit}
            type="submit"
            disabled={isSubmitting || !allFieldsFilled}
            style={{
              opacity: isSubmitting || !allFieldsFilled ? 0.5 : 1,
              cursor:
                isSubmitting || !allFieldsFilled ? "not-allowed" : "pointer",
            }}
          >
            {isSubmitting ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddAccount;
