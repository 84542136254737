import React, { useState } from "react";
import Header from "../components/Header";
import SideNavbar from "../components/SideNavbar";
import { connect } from "react-redux";
import pages from "../pages/index";
import { helpDesk } from "../images";
import HelpDeskModal from "../components/HelpDeskModal";

const pagesWithHiddenHeaderAndSideNavbar = [
  "setUpin",
  "verifyOTP",
  "firstLogin",
  "getQuestions",
  "validateUser",
  "validateQues",
  "verifyMail",
  "forgetPassword",
  "resetPassword",
  "changePassword",
  "login",
  "otpVerifyForget",
  "verifyForgotMail",
  "helpDesk",
  "validateQuesForget",
  "mobileRecharge",
  "dthRecharge",
  "serviceTabs",
  "verifyMailOtp",
  "remitterReg",
  "addBeneficiary",
  "queryRemitter",
  "checkCustomer",
  "mobileRechargeNew",
  "checkCustomerNew",
  "ekycPage",
];

const Page = ({ page, pageTitle }) => {
  let PageComponent = pages["home"];
  if (page in pages) {
    PageComponent = pages[page];
  } else {
    console.log("Page not Found.");
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const shouldHideHeaderAndSideNavbar =
    pagesWithHiddenHeaderAndSideNavbar.includes(page);

  const contentStyle = shouldHideHeaderAndSideNavbar
    ? { height: "100vh", width: "100%" }
    : {
        height: "calc(100vh - 60px)",
        width: "100%",
        backgroundColor: pagesWithHiddenHeaderAndSideNavbar.includes(page)
          ? "transparent"
          : "#E7E8E9",
      };

  const containerClass = shouldHideHeaderAndSideNavbar
    ? "d-flex flex-column"
    : "d-flex flex-column me-2";

  const isHelpDeskVisible = !pagesWithHiddenHeaderAndSideNavbar.includes(page);

  return (
    <>
      {!shouldHideHeaderAndSideNavbar}
      <div className="d-flex min-vh-100 " style={contentStyle}>
        {!shouldHideHeaderAndSideNavbar && (
          <div className="h-100" style={{ width: "300px" }}>
            <SideNavbar />
          </div>
        )}
        <div
          style={{
            width: shouldHideHeaderAndSideNavbar
              ? "100%"
              : "calc(100% - 300px)",
          }}
          className={containerClass}
        >
          <PageComponent />
          {isHelpDeskVisible && (
            <img
              className="helpdesk-icon"
              src={helpDesk}
              alt="helpdesk-icon"
              style={{
                cursor: "pointer",
                pointerEvents: "all",
                bottom: "40px",
                position: "absolute",
              }}
              onClick={openModal}
            />
          )}
          {isModalOpen && (
            <HelpDeskModal show={isModalOpen} onClose={closeModal} />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  page: state.dashboard.page,
  pageTitle: state.dashboard.pageTitle,
});

export default connect(mapStateToProps)(Page);
