import React, { useEffect, useState } from "react";
import { backArrow, circles, noImage2 } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile, updateProfile } from "../urls";
import API from "../components/api2";
import Alert from "../components/Alert";
import Swal from "sweetalert2";
import Button from "../components/Button";

const ProfileSettings = () => {
  const [profileData, setProfileData] = useState([]);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [activeTab, setActiveTab] = useState("personal");
  const [walletColor, setWalletColor] = useState(false);
  const [contactSupport, setContactSupport] = useState({});
  const [editableFields, setEditableFields] = useState({
    name: "",
    designation: "",
    email_id: "",
    contact_no: "",
    employee_code: "",
    father_name: "",
    qualification: "",
    mother_name: "",
    spouse_name: "",
    gender: "",
    blood_group: "",
    address: "",
    pin: "",
    country: "",
    photo: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [businessInfo, setBusinessInfo] = useState(null);

  // alert
  const hideAlert = () => {
    setShowAlertUpdate(false);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };
  const handleInputChange = (fieldName, value) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "home",
      })
    );

    navigate("/");
  };

  //   fetch profile details
  const fetchProfile = () => {
    setLoading(true);
    API({
      ...getProfile,
      onSuccess: (res) => {
        const data = res.data.data;
        setProfileData(data);
        setEditableFields(data);

        if (data?.business_info) {
          const {
            brand_id,
            rd,
            ad,
            ld,
            contact_support_phone,
            contact_support_email,
          } = data.business_info;

          const hierarchy = [
            { label: "Brand", value: brand_id },
            { label: "Super Distributor", value: rd },
            { label: "Master Distributor", value: ad },
            { label: "Distributor", value: ld },
          ].filter((item) => item.value);

          setBusinessInfo(hierarchy);
          setContactSupport({
            phone: contact_support_phone,
            email: contact_support_email,
          });
        }
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching profile: ", error);
        setLoading(false);
      },
    });
  };

  // handle update
  const handleUpdate = () => {
    setLoading(true);
    const { user_id, photo, ...updatedFields } = editableFields;

    const formData = new FormData();

    Object.entries(updatedFields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (photo instanceof File) {
      formData.append("photo", photo);
    }

    formData.append("employee_code", user_id);

    API({
      ...updateProfile,
      body: formData,
      isFormData: true,
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto overflow-y-auto py-4 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center pb-4"
        >
          <img
            className="ms-3"
            style={{ cursor: "pointer", pointerEvents: "all" }}
            src={backArrow}
            onClick={changePageFunc}
            alt="back"
          />
          <h2
            className="mb-0 ms-3"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Profile
          </h2>
        </div>
        {loading ? (
          <div className="text-center mt-5" pt-5>
            <span
              style={{ color: "#194280" }}
              className="spinner-border"
              role="status"
            ></span>
            <p className="mt-2 text-secondary">Loading profile...</p>
          </div>
        ) : (
          <>
            <div className="d-flex border-bottom">
              {["personal", "business"].map((tab) => (
                <button
                  key={tab}
                  className={`flex-grow-1 btn border-0 py-2 ${
                    activeTab === tab ? "fw-bold text-dark" : "text-secondary"
                  }`}
                  style={{
                    background: "none",
                    transition: "0.3s",
                  }}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab === "personal" ? "Personal Info" : "Business Info"}
                </button>
              ))}
            </div>
            {activeTab === "personal" ? (
              <>
                <div
                  className="py-2 px-0 ms-0 ps-3 mt-3"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "0px 27.5px 27.5px 0px",
                    fontSize: "19px",
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    color: "rgba(173, 131, 82, 1)",
                    width: "270px",
                    marginLeft: "-48px",
                  }}
                >
                  Basic Details
                </div>
                <div className="container-fluid px-5">
                  <div className="row mt-4">
                    <div className="col-6">
                      <div className="position-relative">
                        <img
                          src={
                            editableFields.photo
                              ? editableFields.photo
                              : noImage2
                          }
                          alt="profile"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Name
                      </p>
                      <input
                        type="text"
                        name="name"
                        className="p-2 w-100 mt-1"
                        value={editableFields.name}
                        onChange={handleInputChange}
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        User ID
                      </p>
                      <input
                        type="text"
                        name="user_id"
                        className="p-2 w-100 mt-1"
                        value={editableFields.user_id}
                        onChange={handleInputChange}
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Designation
                      </p>
                      <input
                        type="text"
                        name="designation"
                        className="p-2 w-100 mt-1"
                        value={editableFields.designation}
                        onChange={handleInputChange}
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Email
                      </p>
                      <input
                        type="email"
                        name="email_id"
                        className="p-2 w-100 mt-1"
                        value={editableFields.email_id}
                        onChange={handleInputChange}
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Mobile Number
                      </p>
                      <input
                        type="number"
                        name="contact_no"
                        className="p-2 w-100 mt-1"
                        value={editableFields.contact_no}
                        onChange={(e) =>
                          handleInputChange("contact_no", e.target.value)
                        }
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Father Name
                      </p>
                      <input
                        type="text"
                        name="father_name"
                        className="p-2 w-100 mt-1"
                        value={editableFields.father_name}
                        onChange={(e) =>
                          handleInputChange("father_name", e.target.value)
                        }
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Mother Name
                      </p>
                      <input
                        type="text"
                        name="mother_name"
                        className="p-2 w-100 mt-1"
                        value={editableFields.mother_name}
                        onChange={(e) =>
                          handleInputChange("mother_name", e.target.value)
                        }
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Spouse Name
                      </p>
                      <input
                        type="text"
                        name="spouse_name"
                        className="p-2 w-100 mt-1"
                        value={editableFields.spouse_name}
                        onChange={(e) =>
                          handleInputChange("spouse_name", e.target.value)
                        }
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Gender
                      </p>
                      <select
                        name="gender"
                        value={editableFields.gender}
                        onChange={(e) =>
                          handleInputChange("gender", e.target.value)
                        }
                        className="p-2 w-100 mt-1"
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          borderRadius: "10px",
                          marginTop: "8px",
                          boxShadow: "none",
                          width: "100%",
                          outline: "none",
                          padding: "12px 8px",
                        }}
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="MALE">MALE</option>
                        <option value="FEMALE">FEMALE</option>
                        <option value="TRANSGENDER">TRANSGENDER</option>
                      </select>
                    </div>
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Highest Qualification
                      </p>
                      <select
                        name="qualification"
                        value={editableFields.qualification}
                        onChange={(e) =>
                          handleInputChange("qualification", e.target.value)
                        }
                        className="p-2 w-100 mt-1"
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          borderRadius: "10px",
                          marginTop: "8px",
                          boxShadow: "none",
                          width: "100%",
                          outline: "none",
                          padding: "12px 8px",
                        }}
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="10th">10th</option>
                        <option value="12th">12th</option>
                        <option value="GRADUATE">GRADUATE</option>
                        <option value="POST-GRADUATE">POST-GRADUATE</option>
                        <option value="PHD">PHD</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Blood Group
                      </p>
                      <input
                        type="text"
                        name="blood_group"
                        className="p-2 w-100 mt-1"
                        value={editableFields.blood_group}
                        onChange={(e) =>
                          handleInputChange("blood_group", e.target.value)
                        }
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="py-2 px-0 ms-0 ps-3 mt-5"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "0px 27.5px 27.5px 0px",
                    fontSize: "19px",
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    color: "rgba(173, 131, 82, 1)",
                    width: "270px",
                    marginLeft: "-48px",
                  }}
                >
                  Address
                </div>
                <div className="container-fluid px-5">
                  <div className="row mt-4">
                    <div className="col-12 position-relative">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Address
                      </p>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "10px",
                          color: "rgba(206, 162, 2, 1)",
                          backgroundColor: "rgba(253, 235, 172, 1)",
                          borderRadius: "3px",
                          top: "23%",
                          left: "87%",
                        }}
                        className="mb-0 position-absolute px-2 py-1"
                      >
                        Aadhar Address
                      </p>
                      <textarea
                        type="text"
                        className="p-2 w-100 mt-1"
                        name="address"
                        value={editableFields.address}
                        onChange={(e) =>
                          handleInputChange("address", e.target.value)
                        }
                        rows={4}
                        style={{
                          border: "1.4px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          outline: "none",
                          resize: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-2">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Pin
                      </p>
                      <input
                        type="number"
                        name="pin"
                        className="p-2 w-100 mt-1"
                        value={editableFields.pin}
                        onChange={(e) =>
                          handleInputChange("pin", e.target.value)
                        }
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        Country
                      </p>
                      <input
                        type="text"
                        name="country"
                        className="p-2 w-100 mt-1"
                        value={editableFields.country}
                        onChange={(e) =>
                          handleInputChange("country", e.target.value)
                        }
                        style={{
                          border: "1.5px solid #696666",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="text-end mx-5 mt-5">
                  <Button
                    style={{
                      opacity: loading ? 0.6 : 1,
                      pointerEvents: loading ? "none" : "auto",
                    }}
                    onClick={handleClickUpdate}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Update"}
                  </Button>
                </div>
              </>
            ) : (
              // business info
              <div>
                {businessInfo?.length > 0 ||
                contactSupport.phone ||
                contactSupport.email ? (
                  <div className="position-relative mt-4">
                    <div
                      className="py-2 px-0 ms-0 ps-3"
                      style={{
                        backgroundColor: "rgba(217, 217, 217, 1)",
                        borderRadius: "0px 27.5px 27.5px 0px",
                        fontSize: "19px",
                        fontWeight: "700",
                        fontFamily: "Montserrat",
                        color: "rgba(173, 131, 82, 1)",
                        width: "270px",
                        marginLeft: "-48px",
                      }}
                    >
                      Hierarchy Details
                    </div>
                    <div className="d-flex flex-column align-items-center mt-3 position-relative">
                      {businessInfo.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ position: "relative" }}
                        >
                          {/* Label & Value */}
                          <div className="text-center mt-2">
                            <span className="fw-bold text-dark">
                              {item.label}:
                            </span>
                            <span className="ms-1 text-secondary">
                              {item.value}
                            </span>
                          </div>

                          {index < businessInfo.length - 1 && (
                            <div
                              style={{
                                width: "2px",
                                height: "60px",
                                backgroundColor: "#ad8352",
                                zIndex: "1",
                              }}
                            ></div>
                          )}

                          {/* Arrow Icon */}
                          {index < businessInfo.length - 1 && (
                            <div
                              className="position-absolute"
                              style={{
                                width: "0",
                                height: "0",
                                borderLeft: "6px solid transparent",
                                borderRight: "6px solid transparent",
                                borderTop: "10px solid #ad8352",
                                top: "100%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: "2",
                              }}
                            ></div>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="mt-4">
                      <div
                        className="py-2 px-0 ms-0 ps-3 mb-3"
                        style={{
                          backgroundColor: "rgba(217, 217, 217, 1)",
                          borderRadius: "0px 27.5px 27.5px 0px",
                          fontSize: "19px",
                          fontWeight: "700",
                          fontFamily: "Montserrat",
                          color: "rgba(173, 131, 82, 1)",
                          width: "270px",
                          marginLeft: "-48px",
                        }}
                      >
                        Contact Details
                      </div>

                      {contactSupport.phone && (
                        <p className="mb-1 ms-4">
                          <span className="fw-bold text-dark">Phone:</span>{" "}
                          <a
                            href={`tel:${contactSupport.phone}`}
                            className="text-secondary"
                          >
                            {contactSupport.phone}
                          </a>
                        </p>
                      )}
                      {contactSupport.email && (
                        <p className="mb-1 ms-4">
                          <span className="fw-bold text-dark">Email:</span>{" "}
                          <a
                            href={`mailto:${contactSupport.email}`}
                            className="text-secondary"
                          >
                            {contactSupport.email}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <p
                    style={{ fontSize: "18px" }}
                    className="text-muted text-center mt-5 pt-5"
                  >
                    No business information available.
                  </p>
                )}
              </div>
            )}
          </>
        )}
        <Alert
          show={showAlertUpdate}
          onHide={hideAlert}
          title="Confirmation"
          actionText="update"
          message={alertTextUpdate}
          walletColor={walletColor}
          onConfirm={handleUpdate}
          forTerm=" "
          term="Profile"
        />
      </div>
    </>
  );
};

export default ProfileSettings;
