import React, { useEffect, useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import Swal from "sweetalert2";
import {
  getAllUserGroups,
  updateUserGroup,
  getAssignEmpHelpdesk,
} from "../urls";
import { useLocation, useNavigate } from "react-router-dom";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Header from "../components/Header";

const UserGroupInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allEmp, setAllEmp] = useState([]);
  const [assignEmp, setAssignEmp] = useState([]);
  const [searchTermAvailable, setSearchTermAvailable] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [groupFetched, setGroupFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const service = location.state || {};

  const isSubmitDisabled = !name || !description || assignEmp.length === 0;

  useEffect(() => {
    API({
      ...getAssignEmpHelpdesk,
      onSuccess: (res) => {
        setAllEmp(res.data.data);
      },
      onError: (error) => {
        console.log("No employees available");
      },
    });
  }, []);

  const fetchGroup = () => {
    API({
      ...getAllUserGroups,
      params: { id: service?.service?.id },
      onSuccess: (res) => {
        const group = res.data.data.helpdesk_user_groups[0];
        const { name, description, employees } = group;

        setName(name);
        setDescription(description);

        const assignedEmployees = allEmp.filter((e) => {
          const empId = Number(e.employee_id);
          return employees.includes(empId);
        });

        setAssignEmp(assignedEmployees);
        setAllEmp((prevEmp) =>
          prevEmp.filter((e) => !employees.includes(Number(e.employee_id)))
        );
        setGroupFetched(true);
      },
      onError: (error) => {
        console.log("Error fetching user group details:", error);
      },
    });
  };

  useEffect(() => {
    if (allEmp.length > 0 && !groupFetched) {
      fetchGroup();
    }
  }, [allEmp, groupFetched]);

  // Handle employee click (assign/unassign)
  const handleEmpClick = (e) => {
    const isAssigned = assignEmp.some((a) => a.employee_id === e.employee_id);

    if (isAssigned) {
      const updatedAssignedE = assignEmp.filter(
        (a) => a.employee_id !== e.employee_id
      );
      setAssignEmp(updatedAssignedE);

      const updatedAvailE = [...allEmp, e];
      setAllEmp(updatedAvailE);
    } else {
      const updatedAvailE = allEmp.filter(
        (a) => a.employee_id !== e.employee_id
      );
      setAllEmp(updatedAvailE);

      const updatedAssignE = [...assignEmp, e];
      setAssignEmp(updatedAssignE);
    }
  };

  const filteredAvailEmp = allEmp.filter((e) => {
    const empNameMatches = e.employee_name
      .toLowerCase()
      .includes(searchTermAvailable.toLowerCase());
    const empIdMatches = e.employee_id
      .toString()
      .toLowerCase()
      .includes(searchTermAvailable.toLowerCase());

    return empNameMatches || empIdMatches;
  });

  const filteredAssignedEmp = assignEmp.filter((e) => {
    const empNameMatches = e.employee_name
      .toLowerCase()
      .includes(searchTermAssigned.toLowerCase());
    const empIdMatches = e.employee_id
      .toString()
      .toLowerCase()
      .includes(searchTermAssigned.toLowerCase());

    return empNameMatches || empIdMatches;
  });

  // Handle Update User Group
  const handleUpdateUserGroup = () => {
    setLoading(true);
    const employees = assignEmp.map((e) => e.employee_id);
    const requestData = {
      name,
      description,
      employees,
      id: service?.service?.id,
    };
    API({
      ...updateUserGroup,
      body: requestData,
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allUserGroups",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white mt-2 me-2 py-4 position-relative overflow-y-scroll container-fluid h-100"
      >
        <div
          style={{ borderBottom: "1px solid rgba(217, 217, 217, 1)" }}
          className="pb-3"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            User Group Info
          </h2>
        </div>

        {/* group detail  */}
        <div
          className="py-2 ps-3 mt-4"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "230px",
            marginLeft: "-12px",
          }}
        >
          User Group Detail
        </div>

        <div className="px-4 mt-3">
          <div className="col-6">
            <InputForm
              type={"text"}
              placeholder={"Group Name"}
              id={"name"}
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <label
            className="mb-0 mt-4"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontFamily: "Montserrat",
            }}
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            className="w-100 p-2 mt-2"
            style={{
              outline: "none",
              backgroundColor: "#FFF6D8",
              resize: "none",
              border: "none",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              fontSize: "14px",
            }}
            spellCheck={false}
            name="description"
            id="description"
            placeholder="Description"
            rows="7"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        {/* Assign activity  */}
        <div
          className="py-2 ps-3 mt-4"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "230px",
            marginLeft: "-12px",
          }}
        >
          Assigned Activity
        </div>
        <div className="row d-flex justify-content-between mx-2 mt-5">
          <div className="col-5 h-100">
            <div
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(25, 66, 128, 1)",
                    fontWeight: "500",
                    fontSize: "13px",
                  }}
                  className="mb-0 mt-1"
                >
                  All Employees
                </p>
              </div>
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(255, 246, 216, 1)",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <input
                  type="text"
                  value={searchTermAvailable}
                  onChange={(e) => setSearchTermAvailable(e.target.value)}
                  placeholder="Search Employee ID"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(255, 246, 216, 1)",
                  }}
                  className="text-center"
                />
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ overflowY: "auto", height: "300px" }}>
                  {filteredAvailEmp
                    .filter(
                      (e) =>
                        !assignEmp.find(
                          (assignedEmp) =>
                            assignedEmp.employee_id === e.employee_id
                        )
                    )
                    .map((e, index) => (
                      <div
                        key={index}
                        onClick={() => handleEmpClick(e)}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          borderBottom:
                            index !== filteredAvailEmp.length - 1
                              ? "1px solid #D9D9D9"
                              : "none",
                        }}
                      >
                        <p
                          className="mb-0 mt-2"
                          style={{
                            padding: "4px 8px",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "rgba(105, 102, 102, 1)",
                          }}
                        >
                          {e.employee_name} ({e.employee_id})
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-5 h-100">
            <div
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(25, 66, 128, 1)",
                    fontWeight: "500",
                    fontSize: "13px",
                  }}
                  className="mb-0 mt-1"
                >
                  Assigned Employees
                </p>
              </div>
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(255, 246, 216, 1)",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <input
                  type="text"
                  value={searchTermAssigned}
                  onChange={(e) => setSearchTermAssigned(e.target.value)}
                  placeholder="Search Employee ID"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(255, 246, 216, 1)",
                  }}
                  className="text-center"
                />
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ overflowY: "auto", height: "300px" }}>
                  {filteredAssignedEmp.map((e, index) => (
                    <div
                      key={index}
                      onClick={() => handleEmpClick(e)}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        borderBottom:
                          index !== filteredAssignedEmp.length - 1
                            ? "1px solid #D9D9D9"
                            : "none",
                      }}
                    >
                      <p
                        className="mb-0 mt-2"
                        style={{
                          padding: "4px 8px",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "13px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        {e.employee_name} ({e.employee_id})
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 pe-4">
          <button
            className="px-3 py-2"
            onClick={changePageFunc}
            style={{
              border: "1px solid ",
              borderRadius: "10px",
              width: "100px",
              color: "rgba(22, 64, 127, 1)",
            }}
          >
            Back
          </button>
          <button
            className="px-3 py-2 border-0"
            onClick={handleUpdateUserGroup}
            disabled={isSubmitDisabled || loading}
            style={{
              backgroundColor: "rgba(22, 64, 127, 1)",
              cursor: isSubmitDisabled || loading ? "not-allowed" : "pointer",
              opacity: isSubmitDisabled || loading ? "0.5" : "1",
              width: "100px",
              borderRadius: "10px",
              color: "white",
            }}
          >
            {loading ? "Loading..." : "Update"}
          </button>
        </div>
      </div>
    </>
  );
};

export default UserGroupInfo;
