import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import API from "../components/api2";
import { backArrow, circles, pdfViewIcon } from "../images";
import { getProfile, manualWalletAction, verifyManualWallet } from "../urls";
import { changePage } from "../redux/slice/dashboardSlice";
import ImageViewer from "../components/ImageViewer";
import Swal from "sweetalert2";

const VerifyWalletInfo = ({ roles }) => {
  const location = useLocation();
  const [serviceData, setServiceData] = useState(null);
  const [empType, setEmpType] = useState("");
  const [loading, setLoading] = useState({ approve: false, reject: false });

  //   fetch profile details
  useEffect(() => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setEmpType(res.data.data.employee_type);
      },
      onError: (error) => {
        console.error("Error fetching profile: ", error);
      },
    });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasRole = (role) => roles.includes(role);

  useEffect(() => {
    const serviceRequestId =
      location.state?.service?.service_request_id || null;

    API({
      ...verifyManualWallet,
      params: { service_request_id: serviceRequestId },
      onSuccess: (response) => {
        setServiceData(response.data.data);
      },
      onError: (error) => {
        console.error("Error fetching wallet info:", error);
      },
    });
  }, [location.state]);

  const handleAction = (actionType) => {
    const confirmationMessage =
      actionType === "approve"
        ? "Are you sure you want to approve this request?"
        : "Are you sure you want to reject this request?";

    Swal.fire({
      title: "Confirm Action",
      text: confirmationMessage,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading((prevState) => ({ ...prevState, [actionType]: true }));

        API({
          ...manualWalletAction,
          body: {
            request_id: serviceData?.service_request_id,
            is_verified: actionType === "approve" ? 1 : 0,
          },
          onSuccess: (res) => {
            Swal.fire({
              title: `${res.data.message}`,
            }).then(() => {
              window.location.reload();
            });
            setLoading((prevState) => ({ ...prevState, [actionType]: false }));
          },
          onError: (error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
            setLoading((prevState) => ({ ...prevState, [actionType]: false }));
          },
        });
      }
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "verifyWallet",
      })
    );
    navigate("/");
  };

  const renderInputField = (label, value) => (
    <div className="col-3 mt-2">
      <p
        className="mb-0"
        style={{
          fontWeight: "600",
          fontFamily: "Montserrat",
          fontSize: "14px",
          color: "rgba(173, 131, 82, 1)",
        }}
      >
        {label}
      </p>
      <input
        type="text"
        className="p-2 w-100"
        value={value || "N/A"}
        style={{
          fontFamily: "Montserrat",
        }}
        readOnly
      />
    </div>
  );

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center pb-4"
        >
          <img
            className="ps-2"
            style={{ cursor: "pointer", pointerEvents: "all" }}
            src={backArrow}
            alt="back"
            onClick={changePageFunc}
          />
          <h2
            className="mb-0 ps-3"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Verify Wallet Information
          </h2>
        </div>

        <div className="mt-3 pb-3">
          <div className="row mt-2 px-5">
            {renderInputField("Merchant ID", serviceData?.merchant_id)}
            {renderInputField("Merchant Name", serviceData?.merchant_name)}
            {renderInputField("Payment Mode", serviceData?.payment_mode)}
            {renderInputField("Bank Name", serviceData?.bank_name)}
            {renderInputField(
              "Deposited Amount",
              `₹ ${serviceData?.deposited_amount}`
            )}
            {renderInputField("Deposited Date", serviceData?.deposit_date)}
            {renderInputField("UTR", serviceData?.utr)}
            {renderInputField(
              "Status",
              serviceData?.verified === true
                ? "Approved"
                : serviceData?.verified === false
                ? "Rejected"
                : "Pending"
            )}
          </div>

          <div className="row d-flex align-items-end mt-3 px-5">
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Attachment
              </p>
              {serviceData?.attachment &&
              serviceData?.payment_mode !== "online" ? (
                <div
                  className="position-relative mt-2 pdf-hvr img_viewer_container"
                  style={{
                    backgroundColor: "rgba(26, 25, 24, 0.5)",
                    borderRadius: "10px",
                    height: "150px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    className="position-absolute zoom"
                    src={pdfViewIcon}
                    alt="pdf-view"
                    style={{
                      left: "80%",
                      top: "50%",
                      transform: "translateY(-50%)",
                      opacity: "0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "white",
                    }}
                    className="mb-0 py-2 px-3"
                  >
                    Attachment
                  </p>
                  <ImageViewer
                    thumbnailHeight="110px"
                    className="px-3 py-2"
                    src={serviceData?.attachment}
                    alt="attachment"
                  />
                </div>
              ) : (
                <p className="mt-2">No attachment available</p>
              )}
            </div>
            <div className="col-3 ms-5">
              <div className="d-flex align-items-center justify-content-between">
                {["PLATFORM", "BRAND"].includes(empType) && (
                  <>
                    {hasRole("Approve Manual Wallet") && (
                      <button
                        className="py-1 px-2"
                        style={{
                          border: "1.5px solid rgba(98, 192, 140, 1)",
                          borderRadius: "5px",
                          fontFamily: "Montserrat",
                          color: "white",
                          fontWeight: "500",
                          width: "118px",
                          backgroundColor: "rgba(98, 192, 140, 1)",
                          opacity: serviceData?.verified !== "" ? 0.5 : 1,
                          cursor:
                            serviceData?.verified !== ""
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => handleAction("approve")}
                        disabled={
                          serviceData?.verified !== "" ||
                          loading.approve ||
                          loading.reject
                        }
                      >
                        {loading.approve ? "Loading..." : "Approve"}
                      </button>
                    )}
                    {hasRole("Reject Manual Wallet") && (
                      <button
                        className="py-1 px-2 ms-3"
                        style={{
                          border: "1.5px solid rgba(249, 124, 75, 1)",
                          borderRadius: "5px",
                          fontFamily: "Montserrat",
                          color: "rgba(249, 124, 75, 1)",
                          fontWeight: "500",
                          width: "118px",
                          opacity: serviceData?.verified !== "" ? 0.5 : 1,
                          cursor:
                            serviceData?.verified !== ""
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => handleAction("reject")}
                        disabled={
                          serviceData?.verified !== "" ||
                          loading.approve ||
                          loading.reject
                        }
                      >
                        {loading.reject ? "Loading..." : "Reject"}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: state.user.roles,
});

export default connect(mapStateToProps)(VerifyWalletInfo);
