export const getDUID = {
  url: "/api/a/login/",
  method: "GET",
};

export const login = {
  url: "/api/a/login/",
  method: "POST",
};

export const checkT = {
  url: "/api/a/checkt/",
  method: "POST",
};

export const changePassword = {
  url: "/api/a/change-password/",
  method: "POST",
};
export const sessionLogout = {
  url: "/api/a/session-logout/",
  method: "POST",
};

// Business brand
export const addBusinessBrand = {
  url: "/api/a/brand/",
  method: "POST",
};
export const getBusinessBrand = {
  url: "/api/a/brand/",
  method: "GET",
};
export const updateBusinessBrand = {
  url: "/api/a/brand/",
  method: "PUT",
};

// Merchant
export const addMerchant = {
  url: "/api/a/merchant/",
  method: "POST",
};

export const getMerchant = {
  url: "/api/a/merchant/",
  method: "GET",
};
export const getMerchantData = {
  url: "api/a/merchant-data/",
  method: "GET",
};
export const getMerchantDropdown = {
  url: "api/a/dropdown-merchant/",
  method: "GET",
};
export const updateMerchantData = {
  url: "api/a/merchant-data/",
  method: "PUT",
};

export const updateMerchant = {
  url: "/api/a/merchant/",
  method: "PUT",
};

export const merchantDedup = {
  url: "api/a/merchant-dedup/",
  method: "POST",
};

// merchant contact api
export const merchantOtp = {
  url: "api/a/merchant-contact-verified/",
  method: "POST",
};
export const merchantVerifyOtp = {
  url: "api/a/merchant-contact-otp/",
  method: "POST",
};

// merchant mail api
export const merchantMailVerify = {
  url: "api/a/merchant-mail-verified/",
  method: "POST",
};
export const merchantVerifyOtpMail = {
  url: "api/a/merchant-mail-otp/",
  method: "POST",
};

// merchant application verify
export const verifyMerch = {
  url: "/api/a/merchant-application-verify/",
  method: "POST",
};

// merchant active / inactive
export const activeMerchant = {
  url: "/api/a/merchant-activation-deactivation/",
  method: "PATCH",
};

// merchant freeze
export const freezeMerchant = {
  url: "/api/a/merchant-wallet-freeze/",
  method: "POST",
};

// merchant dropdown
export const merchantDropdown = {
  url: "/api/a/merchant-dropdown/",
  method: "GET",
};

// merchant dropdown
export const merchantApplicationDropdown = {
  url: "/api/a/merchant-application-dropdown/",
  method: "GET",
};

// merchant allot package
export const getBusinessPackage = {
  url: "/api/a/allot-business-package/",
  method: "GET",
};
export const postBusinessPackage = {
  url: "/api/a/allot-business-package/",
  method: "POST",
};
export const putBusinessPackage = {
  url: "/api/a/allot-business-package/",
  method: "PUT",
};
export const getMerchantPackages = {
  url: "/api/a/merchant-scheme/",
  method: "GET",
};

// recharge merchant wallet
export const rechMerchWallet = {
  url: "/api/a/recharge-merchant-wallet/",
  method: "PUT",
};

// Employee
export const newEmployee = {
  url: "/api/a/user-creation/",
  method: "POST",
};
export const getEmployee = {
  url: "/api/a/user-creation/",
  method: "GET",
};
export const getEmployeeRight = {
  url: "/api/a/user-role-table/",
  method: "GET",
};
export const updateEmployee = {
  url: "/api/a/user-creation/",
  method: "PUT",
};
export const getAssignAccessRightsEmployee = {
  url: "/api/a/assign-access-rights/",
  method: "GET",
};
export const uniqueEmployee = {
  url: "/api/a/unique-system-user/",
  method: "POST",
};
export const designation = {
  url: "api/a/designation-dropdown/",
  method: "GET",
};
export const profileRole = {
  url: "api/a/profile-dropdown/",
  method: "GET",
};

// menus
export const getMenusDropdown = {
  url: "/api/a/app-menus-dropdown/",
  method: "GET",
};
export const getMenus = {
  url: "/api/a/app-menus/",
  method: "GET",
};
export const addMenus = {
  url: "/api/a/app-menus/",
  method: "POST",
};
export const getAssignedMenus = {
  url: "/api/a/roles/",
  method: "GET",
};
export const saveAvailableMenus = {
  url: "/api/a/app-menus/",
  method: "PUT",
};
export const saveAssignedMenus = {
  url: "/api/a/roles/",
  method: "PUT",
};
export const rolesDropdown = {
  url: "/api/a/roles-dropdown/",
  method: "GET",
};

// Worker
export const updateWorker = {
  url: "/api/a/update-worker",
  method: "POST",
};

// service provider
export const addServiceProvider = {
  url: "/api/a/service-provider/",
  method: "POST",
};
export const getServiceProvider = {
  url: "/api/a/service-provider/",
  method: "GET",
};
export const getServiceDropdown = {
  url: "/api/a/service-provider-dropdown/",
  method: "GET",
};
export const updateServiceProvider = {
  url: "/api/a/service-provider/",
  method: "PUT",
};
export const activeServiceProvider = {
  url: "/api/a/service-provider-active/",
  method: "POST",
};

// service provider comment
export const addSpComment = {
  url: "api/a/service-provider-comment/",
  method: "POST",
};
export const getSpComment = {
  url: "api/a/service-provider-comment/",
  method: "GET",
};

// standard service
export const serviceList = {
  url: "api/a/standard-service/",
  method: "GET",
};
export const serviceCode = {
  url: "api/a/service-code/",
  method: "GET",
};
export const serviceDropdown = {
  url: "api/a/standard-service-dropdown/",
  method: "GET",
};
export const addService = {
  url: "api/a/standard-service/",
  method: "POST",
};
export const updateService = {
  url: "api/a/standard-service/",
  method: "PUT",
};
export const activeStService = {
  url: "api/a/standard-service-active/",
  method: "POST",
};

// standard service comment
export const addStComment = {
  url: "api/a/standard-service-comment/",
  method: "POST",
};
export const getStComment = {
  url: "api/a/standard-service-comment/",
  method: "GET",
};

// commission
export const commissionDropdown = {
  url: "api/a/commission-dropdown/",
  method: "GET",
};
export const addCommissions = {
  url: "api/a/commission-structure/",
  method: "POST",
};
export const getCommissions = {
  url: "api/a/commission-structure/",
  method: "GET",
};
export const updateCommissions = {
  url: "api/a/commission-structure/",
  method: "PUT",
};
export const commissionActive = {
  url: "api/a/commission-active/",
  method: "POST",
};
export const consumerDropdown = {
  url: "api/a/consumer-service-dropdown/",
  method: "GET",
};

// commission comments
export const addcommissionComments = {
  url: "api/a/commission-comment/",
  method: "POST",
};
export const getcommissionComments = {
  url: "api/a/commission-comment/",
  method: "GET",
};

// consumer
export const addConsumer = {
  url: "api/a/consumer-service/",
  method: "POST",
};
export const getConsumer = {
  url: "api/a/consumer-service/",
  method: "GET",
};
export const updateConsumer = {
  url: "api/a/consumer-service/",
  method: "PUT",
};
export const activeConsumer = {
  url: "api/a/consumer-service-active/",
  method: "POST",
};

// consumer comments
export const addConsumerComments = {
  url: "api/a/consumer-service-comment/",
  method: "POST",
};
export const getConsumerComments = {
  url: "api/a/consumer-service-comment/",
  method: "GET",
};

// api & portal
export const addApiPortal = {
  url: "api/a/api-portal-utility-service/",
  method: "POST",
};
export const getApiPortal = {
  url: "api/a/api-portal-utility-service/",
  method: "GET",
};

export const getApiPortalDropdown = {
  url: "api/a/api-portal-dropdown/",
  method: "GET",
};
export const updateApiPortal = {
  url: "api/a/api-portal-utility-service/",
  method: "PUT",
};
export const apiPortalActive = {
  url: "api/a/api-portal-active/",
  method: "POST",
};

// api portal comments
export const addApiComments = {
  url: "api/a/api-portal-comment/",
  method: "POST",
};
export const getApiComments = {
  url: "api/a/api-portal-comment/",
  method: "GET",
};

// feature service
export const featureList = {
  url: "api/a/feature/",
  method: "GET",
};
export const addFeature = {
  url: "api/a/feature/",
  method: "POST",
};
export const updateFeature = {
  url: "api/a/feature/",
  method: "PUT",
};

// System user
export const newSystemUser = {
  url: "/api/a/system-user/",
  method: "POST",
};
export const getSystemUser = {
  url: "/api/a/system-user/",
  method: "GET",
};
export const updateSystemUser = {
  url: "/api/a/system-user/",
  method: "PUT",
};

// // platform business scheme
// export const newPlatformBusiness = {
//   url: "/api/a/platform-business-scheme/",
//   method: "POST",
// };
// export const getPlatformBusiness = {
//   url: "/api/a/platform-business-scheme/",
//   method: "GET",
// };
// export const updatePlatformBusiness = {
//   url: "/api/a/platform-business-scheme/",
//   method: "PUT",
// };

export const prayasPlatform = {
  url: "/api/a/prayas-business-platform/",
  method: "GET",
};
export const brandDropdown = {
  url: "/api/a/brand-dropdown/",
  method: "GET",
};
// portal utility service
export const newPortalUtility = {
  url: "/api/a/api-portal-utility-service/",
  method: "POST",
};
export const getPortalUtility = {
  url: "/api/a/api-portal-utility-service/",
  method: "GET",
};
export const updatePortalUtility = {
  url: "/api/a/api-portal-utility-service/",
  method: "PUT",
};

// platform wallet
export const newPlatformWallet = {
  url: "/api/a/platform-wallet/",
  method: "POST",
};
export const platformWalletList = {
  url: "/api/a/platform-wallet/",
  method: "GET",
};
export const updatePlatformWallet = {
  url: "/api/a/platform-wallet/",
  method: "PUT",
};

// platform scheme utility service
export const newPlatformUtility = {
  url: "/api/a/platform-scheme-utility-service/",
  method: "POST",
};
export const platformUtilityList = {
  url: "/api/a/platform-scheme-utility-service/",
  method: "GET",
};
export const updatePlatformUtility = {
  url: "/api/a/platform-scheme-utility-service/",
  method: "PUT",
};

// merchant wallet
export const newMerchWallet = {
  url: "/api/a/merchant-wallet/",
  method: "POST",
};
export const getMerchWallet = {
  url: "/api/a/merchant-wallet/",
  method: "GET",
};

// Reset Password
export const resetPassword = {
  url: "/api/a/reset-password/",
  method: "POST",
};
export const getUser = {
  url: "/api/a/reset-password/",
  method: "GET",
};
export const activeUser = {
  url: "/api/a/user-active-deactive/",
  method: "PATCH",
};

// otp
export const generateOTP = {
  url: "api/a/generate-otp/",
  method: "POST",
};
export const verifyOTP = {
  url: "api/a/verify-otp/",
  method: "POST",
};
export const verifyMailOTP = {
  url: "api/a/verify-mobile-mail-otp/",
  method: "POST",
};

// upin
export const userPin = {
  url: "api/a/user-pin/",
  method: "POST",
};

export const changeUserPin = {
  url: "api/a/user-pin/",
  method: "PUT",
};
export const forgetUserPin = {
  url: "api/a/forget-upin-otp/",
  method: "POST",
};

// first login
export const firstLogin = {
  url: "api/a/first-login-password/",
  method: "POST",
};

// first login ques
export const getQuestions = {
  url: "api/a/login-question/",
  method: "GET",
};
export const submitAns = {
  url: "api/a/login-question/",
  method: "POST",
};
export const resetSecQues = {
  url: "api/a/question/",
  method: "PUT",
};
export const verifyPass = {
  url: "api/a/password-verify/",
  method: "POST",
};

// verify mail
export const mailVerification = {
  url: "api/a/otp-mobile-mail/",
  method: "GET",
};
export const mailCheck = {
  url: "api/a/otp-mobile-mail/",
  method: "POST",
};

// forget password
export const validateUser = {
  url: "/api/a/verify-user/",
  method: "POST",
};
export const validateQues = {
  url: "/api/a/question/",
  method: "GET",
};
export const validateAns = {
  url: "/api/a/question/",
  method: "POST",
};
export const getForgetMail = {
  url: "/api/a/forget-password-otp/",
  method: "GET",
};
export const forgetOtp = {
  url: "/api/a/forget-password-otp/",
  method: "POST",
};
export const forgetPassword = {
  url: "/api/a/forget-password/",
  method: "POST",
};

// assign rights
export const assignRights = {
  url: "/api/a/assign-rights",
  method: "POST",
};

// logout
export const logout = {
  url: "/api/a/logout/",
  method: "POST",
};

// roles

// add new role
export const addRole = {
  url: "/api/a/roles/",
  method: "POST",
};

export const available = {
  url: "/api/a/roles/",
  method: "GET",
};
export const assigned = {
  url: "/api/a/user-roles/",
  method: "GET",
};
export const saveAvailableRoles = {
  url: "/api/a/user-roles/",
  method: "PUT",
};
export const saveAssignedRoles = {
  url: "/api/a/user-roles/",
  method: "POST",
};

// wallet
export const getWallet = {
  url: "/api/a/logged-user-wallet/",
  method: "GET",
};

// merchant transaction
export const merchTransaction = {
  url: "/api/a/merchant-transaction/",
  method: "GET",
};

// brand transaction
export const brandTransaction = {
  url: "/api/a/brand-transaction/",
  method: "GET",
};

// platform transaction
export const platformTransaction = {
  url: "/api/a/platform-transaction/",
  method: "GET",
};

// transaction history
export const transactionHistory = {
  url: "api/a/logged-user-transaction/",
  method: "GET",
};

// allot package
export const getAvailableScheme = {
  url: "api/a/platform-business-scheme/",
  method: "GET",
};
export const getAvailableSchemeDropdown = {
  url: "api/a/platform-business-scheme-dropdown/",
  method: "GET",
};
export const getAllotBrandDetails = {
  url: "api/a/allot-business-to-brand/",
  method: "GET",
};
export const postSchemesInAssigned = {
  url: "api/a/allot-business-to-brand/",
  method: "POST",
};
export const postSchemesInAvailable = {
  url: "api/a/allot-business-to-brand/",
  method: "PUT",
};

// application fees api
export const payFee = {
  url: "/api/a/application-fees/",
  method: "POST",
};
export const getFees = {
  url: "/api/a/application-fees/",
  method: "GET",
};

// business scheme
export const getBusinessSchemeDropdown = {
  url: "/api/a/business-scheme-dropdown/",
  method: "GET",
};
export const addBusinessScheme = {
  url: "/api/a/manage-business-scheme/",
  method: "POST",
};
export const getBusinessScheme = {
  url: "/api/a/manage-business-scheme/",
  method: "GET",
};
export const allBusinessScheme = {
  url: "/api/a/all-business-scheme/",
  method: "GET",
};
export const updateBusinessScheme = {
  url: "/api/a/manage-business-scheme/",
  method: "PUT",
};
export const allotedServices = {
  url: "/api/a/allot-services/",
  method: "GET",
};
export const schemeActive = {
  url: "/api/a/all-business-scheme-active/",
  method: "PATCH",
};

// comments
export const addComments = {
  url: "/api/a/application-comments/",
  method: "POST",
};
export const getComments = {
  url: "/api/a/application-comments/",
  method: "GET",
};

// brand wallet
export const addWallet = {
  url: "/api/a/brand-wallet/",
  method: "POST",
};
export const getBrandWallet = {
  url: "/api/a/brand-wallet/",
  method: "GET",
};

//  freeze/unfreeze wallet
export const freezeWallet = {
  url: "/api/a/brand-freeze/",
  method: "POST",
};

// verify/reject/approve brand
export const actionBrand = {
  url: "/api/a/brand-verify/",
  method: "POST",
};

// active/inactive brand
export const activeWallet = {
  url: "/api/a/brand-active/",
  method: "POST",
};

// menus shortcut
export const menuShortcut = {
  url: "/api/a/menu-shortcuts/",
  method: "POST",
};
export const getShortcutServices = {
  url: "/api/a/menu-shortcuts/",
  method: "GET",
};
export const deleteShortcutServices = {
  url: "/api/a/menu-shortcuts/",
  method: "DELETE",
};
export const getMenuShortcut = {
  url: "/api/a/logged-services/",
  method: "GET",
};
export const getMenuShortcutNew = {
  url: "/api/a/commission-logged-services/",
  method: "GET",
};

// transaction graph
export const transGraph = {
  url: "/api/a/transaction-graph/",
  method: "GET",
};

// helpdesk

// new helpdesk/workflow urls
export const workflowFilters = {
  url: "/api/h/helpdesk-category/",
  method: "GET",
};
export const checkWorkflowRules = {
  url: "/api/h/check-workflow/",
  method: "POST",
};
export const getAllUserGroupsDropdown = {
  url: "/api/h/helpdesk-usergroup-dropdown/",
  method: "GET",
};
export const getAdditionalFields = {
  url: "/api/h/helpdesk-custom-fields/",
  method: "GET",
};
export const worfklowDropdown = {
  url: "/api/h/workflow-dropdown/",
  method: "GET",
};
export const ticketAdditionalFields = {
  url: "/api/h/helpdesk-workflow-additional-fields/",
  method: "GET",
};
// export const getDropdown = {
//   url: "/api/m/dropdown/",
//   method: "GET",
// };
export const customDropdown = {
  url: "/api/h/additional-fields-custom-dropdown/",
  method: "GET",
};
export const ticketAssignedDropdown = {
  url: "/api/h/ticket-assigned/",
  method: "GET",
};
export const ticketReportedDropdown = {
  url: "/api/h/ticket-reported/",
  method: "GET",
};
export const allCustomFields = {
  url: "/api/h/helpdesk-custom-fields/",
  method: "GET",
};
export const newCustomFields = {
  url: "/api/h/helpdesk-custom-fields/",
  method: "POST",
};
export const deleteCustomFields = {
  url: "/api/h/helpdesk-custom-fields/",
  method: "DEL",
};
export const getAllUserGroups = {
  url: "/api/h/helpdesk-usergroup/",
  method: "GET",
};
export const createdByDropdown = {
  url: "/api/h/createdby-dropdown/",
  method: "GET",
};
export const addUserGroups = {
  url: "/api/h/helpdesk-usergroup/",
  method: "POST",
};
export const updateUserGroup = {
  url: "/api/h/helpdesk-usergroup/",
  method: "PUT",
};
export const getHelpdeskManagement = {
  url: "/api/h/helpdesk-category-table/",
  method: "GET",
};
export const createCategory = {
  url: "/api/h/helpdesk-category/",
  method: "POST",
};

// category helpdesk
export const helpSelectedDropdownRefined = {
  url: "/api/h/ticket-selected-dropdown/",
  method: "GET",
};
export const helpDeskCategory = {
  url: "/api/h/selected-dropdown/",
  method: "GET",
};
export const helpDeskPost = {
  url: "/api/h/helpdesk-workflow/",
  method: "POST",
};
export const getWorkflow = {
  url: "/api/h/helpdesk-workflow/",
  method: "GET",
};
export const updateWorkflow = {
  url: "/api/h/helpdesk-workflow/",
  method: "PUT",
};
export const workflowActive = {
  url: "/api/h/workflow-active-deactive/",
  method: "PATCH",
};
export const getAssignEmp = {
  url: "/api/a/employee-dropdown/",
  method: "GET",
};
export const getAssignEmpHelpdesk = {
  url: "/api/h/employee-selected/",
  method: "GET",
};

export const ticketCategory = {
  url: "/api/h/ticket-category/",
  method: "GET",
};

// helpdesk ticket
export const addTicket = {
  url: "/api/h/helpdesk-ticket/",
  method: "POST",
};
export const getTicket = {
  url: "/api/h/helpdesk-ticket/",
  method: "GET",
};
export const updateTicket = {
  url: "/api/h/helpdesk-ticket/",
  method: "PUT",
};

// helpdesk ticket attachments
export const getTicketAttachments = {
  url: "/api/h/helpdesk-attachments/",
  method: "GET",
};

// ticket comments
export const getTicketComments = {
  url: "/api/h/helpdesk-comment/",
  method: "GET",
};
export const addTicketComments = {
  url: "/api/h/helpdesk-comment/",
  method: "POST",
};

export const assginedToTicket = {
  url: "/api/h/assigned-to/",
  method: "GET",
};
export const revertToTicket = {
  url: "/api/h/revert/",
  method: "GET",
};

// helpdesk progress tracker
export const progressTracker = {
  url: "/api/h/progress-tracker/",
  method: "GET",
};
// helpdesk progress tracker
export const helpdeskHistory = {
  url: "/api/h/helpdesk-status/",
  method: "GET",
};

// end customer
export const addEndCustomer = {
  url: "api/a/end-customer/",
  method: "POST",
};
export const endCustomerDropdown = {
  url: "api/a/end-customer-dropdown/",
  method: "GET",
};
export const getEndCustomer = {
  url: "api/a/end-customer/",
  method: "GET",
};
export const updateEndCustomer = {
  url: "api/a/end-customer/",
  method: "PUT",
};

// end cust active/inactive
export const endCustomerActive = {
  url: "api/a/end-customer-active/",
  method: "PATCH",
};

// end cust comment
export const endCustomerComment = {
  url: "api/a/end-customer-comments/",
  method: "POST",
};
export const getEndCustomerComment = {
  url: "api/a/end-customer-comments/",
  method: "GET",
};

// profile
export const getProfile = {
  url: "api/a/profile/",
  method: "GET",
};
export const updateProfile = {
  url: "api/a/profile/",
  method: "PUT",
};

// logged apis
export const getLoggedMenus = {
  url: "api/a/logged-user-app-menus/",
  method: "GET",
};

// commission
export const transReport = {
  url: "api/a/get-transaction-report/",
  method: "GET",
};
export const getCommissionStruct = {
  url: "api/a/commission/",
  method: "GET",
};
export const getNewCommissionStruct = {
  url: "api/a/get-commission/",
  method: "GET",
};
export const getTransCommission = {
  url: "api/a/transaction-commission/",
  method: "GET",
};

export const getCommissionReport = {
  url: "api/a/commission-report/",
  method: "GET",
};
export const getLedgerReport = {
  url: "api/a/get-ledger-report/",
  method: "GET",
};
export const merchantDropdownReport = {
  url: "api/a/logged-dropdown/",
  method: "GET",
};
export const selectedDropdown = {
  url: "api/a/selected-logged-dropdown/",
  method: "GET",
};
export const ntwkWalletReport = {
  url: "api/s/network-wallet-report/",
  method: "GET",
};
export const walletRechgReport = {
  url: "api/s/wallet-recharge-report/",
  method: "GET",
};
export const rechgStatusReport = {
  url: "api/s/recharge-status/",
  method: "GET",
};
export const checkRechgStatusReport = {
  url: "api/s/recharge-status/",
  method: "PUT",
};

// designation
export const getDesignation = {
  url: "api/a/designation/",
  method: "GET",
};
export const postDesignation = {
  url: "api/a/designation/",
  method: "POST",
};
export const editDesignation = {
  url: "api/a/designation/",
  method: "PUT",
};

// profile role
export const getProfileRole = {
  url: "api/a/profile-role/",
  method: "GET",
};
export const postProfileRole = {
  url: "api/a/profile-role/",
  method: "POST",
};
export const editProfileRole = {
  url: "api/a/profile-role/",
  method: "PUT",
};

// bank accounts
export const merchBankDetails = {
  url: "api/s/merchant-bank-details/",
  method: "GET",
};
export const addMerchBank = {
  url: "api/s/merchant-bank-details/",
  method: "POST",
};
export const verifyMerchBank = {
  url: "api/s/vrms/",
  method: "POST",
};
export const verifyMerchBankList = {
  url: "api/s/verify-merchant-bank-details/",
  method: "GET",
};

// my network
export const getMyNetwork = {
  url: "api/s/network-wallet/",
  method: "GET",
};

export const myNetworkWallet = {
  url: "api/s/network-wallet/",
  method: "POST",
};
export const myNetworkWalletPb = {
  url: "api/s/network-wallet-pb/",
  method: "POST",
};
export const merchNetworkDropdown = {
  url: "api/s/merchant-network-dropdown/",
  method: "GET",
};

// manual wallet recharge
export const manualWalletRechg = {
  url: "api/s/manual-wallet/",
  method: "POST",
};
export const verifyManualWallet = {
  url: "api/s/manual-wallet/",
  method: "GET",
};
export const manualWalletAction = {
  url: "api/s/manual-wallet-verified/",
  method: "POST",
};

// alerts
export const newAlert = {
  url: "/api/s/alerts/",
  method: "POST",
};
export const getAlerts = {
  url: "/api/s/alerts/",
  method: "GET",
};
export const updateAlertStatus = {
  url: "/api/s/alerts/",
  method: "PUT",
};

// *********** services *************

// mobile recharge
export const checkRechgType = {
  url: "api/s/hlrcheck/",
  method: "POST",
};

export const getMobOperator = {
  url: "api/s/getoperator/",
  method: "GET",
};

export const browseMobPlans = {
  url: "api/s/browseplan/",
  method: "POST",
};
export const mobRecharge = {
  url: "api/s/mobile-recharge/",
  method: "POST",
};
export const endCustDropdown = {
  url: "api/a/logged-customer-dropdown/",
  method: "GET",
};
export const globalCustSearch = {
  url: "api/a/end-customer-filter/",
  method: "GET",
};

// dth recharge
export const dthPlans = {
  url: "api/s/dthinfo/",
  method: "POST",
};
export const dthRecharge = {
  url: "api/s/dth-recharge/",
  method: "POST",
};

// Payment Gateway

// airpay
export const airpayRechargeRequest = {
  url: "/api/s/airpay-recharge-request/",
  method: "POST",
};
export const payIn = {
  url: "api/s/payin-orders/",
  method: "POST",
};
export const payInCallback = {
  url: "api/s/payin-callback/",
  method: "POST",
};

// easebuzz
export const payInEaseBuzz = {
  url: "api/s/eazz_pay_in/",
  method: "POST",
};

// Service Request
export const serviceRequest = {
  url: "/api/s/service-request/",
  method: "POST",
};

// Service Request Status
export const serviceRequestStatus = {
  url: "/api/s/service-request-status/",
  method: "GET",
};

// AEPS Merchant OnBoarding
export const aepsMerchantOnBoard = {
  url: "/api/s/aeps-onboarding/",
  method: "POST",
};
// AEPS Merchant OnBoarding Status
export const aepsOnBoardStatus = {
  url: "/api/s/aeps-onboarding-status/",
  method: "GET",
};

// AEPS Registration
export const aepsRegistration = {
  url: "/api/s/aeps-registration/",
  method: "POST",
};

// AEPS Registration
export const aepsRegLock = {
  url: "/api/s/aeps-registration-verify/",
  method: "GET",
};

// AEPS Merchant Authenticity
export const aepsMerchantAuthenticity = {
  url: "/api/s/aeps-2fa-merchant-authenticity/",
  method: "POST",
};

export const aepsBankList = {
  url: "/api/s/aeps-banklist/",
  method: "GET",
};

// AEPS Withdraw
export const aepsWithdraw = {
  url: "/api/s/aeps-withdraw/",
  method: "POST",
};
export const aepsWithdrawStatus = {
  url: "/api/s/aeps-withdraw-status/",
  method: "GET",
};

// AEPS Pay
export const aepsPay = {
  url: "/api/s/aeps-pay/",
  method: "POST",
};

// AEPS Ministatement
export const aepsStatement = {
  url: "/api/s/aeps-ministatement/",
  method: "POST",
};
// AEPS balance enquiry
export const aepsBalance = {
  url: "/api/s/aeps-balance-enquiry/",
  method: "POST",
};

// AEPS Merchant Auth Check
export const aepsMerchAuthCheck = {
  url: "/api/s/aeps-merchant-auth-check/",
  method: "GET",
};

// Aadhar Validation
export const aadharValidation = {
  url: "/api/a/aadhar-validator/",
  method: "POST",
};
// AEPS wallet to main
export const aepsToMain = {
  url: "/api/s/aeps-to-main/",
  method: "POST",
};
// payout
export const aepsToBank = {
  url: "/api/s/payout-response/",
  method: "POST",
};
export const fetchPayoutsTrans = {
  url: "/api/s/fetch-payout-transacrions/",
  method: "GET",
};
export const updatePayoutsTrans = {
  url: "/api/s/fetch-payout-transacrions/",
  method: "POST",
};

// payin
export const fetchPayinTrans = {
  url: "/api/s/fetch-payin-transacrions/",
  method: "GET",
};

// dmt
export const postQueryRemitter = {
  url: "/api/s/dmt-query-remitter/",
  method: "POST",
};
export const registerRemitter = {
  url: "/api/s/dmt-register-remitter/",
  method: "POST",
};
export const regNewBeneficiary = {
  url: "/api/s/dmt-register-beneficiary/",
  method: "POST",
};
export const fetchBeneficiary = {
  url: "/api/s/dmt-fetch-beneficiary/",
  method: "POST",
};
export const deleteBeneficiary = {
  url: "/api/s/dmt-delete-beneficiary/",
  method: "POST",
};
export const dmtBankList = {
  url: "/api/s/dmt-banklist/",
  method: "GET",
};
export const dmtIfscList = {
  url: "/api/a/ifsc/",
  method: "GET",
};
export const dmtPennyDrop = {
  url: "/api/s/dmt-penny-drop/",
  method: "POST",
};
export const dmtTransact = {
  url: "/api/s/dmt-transact/",
  method: "POST",
};
export const transSummmary = {
  url: "/api/s/dmt-transact/",
  method: "GET",
};
export const dmtRefundOtp = {
  url: "/api/s/dmt-refund-otp/",
  method: "POST",
};
export const dmtRefund = {
  url: "/api/s/dmt-refund/",
  method: "POST",
};
export const dmtStatus = {
  url: "/api/s/dmt-transact-status/",
  method: "POST",
};
export const dmtEkyc = {
  url: "/api/s/dmt-remitter-ekyc/",
  method: "POST",
};
export const dmtTransactOtp = {
  url: "/api/s/dmt-transact-send-otp/",
  method: "POST",
};

// travel

// a. bus
export const busSourceCity = {
  url: "api/s/source-city/",
  method: "GET",
};
export const busTopDest = {
  url: "api/s/top-destination/",
  method: "GET",
};

export const availableTrips = {
  url: "api/s/available-trips/",
  method: "POST",
};
export const currentTripDetails = {
  url: "api/s/current-trip-details/",
  method: "POST",
};
export const boardingPointDetail = {
  url: "api/s/boarding-point-detail/",
  method: "POST",
};
export const passengerDetails = {
  url: "api/s/block-ticket/",
  method: "POST",
};
export const bookTicket = {
  url: "api/s/book-ticket/",
  method: "POST",
};
export const checkBookedTicket = {
  url: "api/s/check-booked-ticket/",
  method: "POST",
};
export const cancelTicketData = {
  url: "api/s/cancelation-data/",
  method: "POST",
};
export const cancelTicket = {
  url: "api/s/cancel-ticket/",
  method: "POST",
};
export const busHistory = {
  url: "api/s/bus-history/",
  method: "GET",
};
export const busCancellationPolicy = {
  url: "api/s/bus-cancellation-policy/",
  method: "POST",
};

// paysprint wallets
export const paySprintCredit = {
  url: "api/s/credit-balance/",
  method: "POST",
};
export const paySprintDebit = {
  url: "api/s/debit-balance/",
  method: "POST",
};

// merchant location update
export const merchLocationUpdate = {
  url: "api/s/merchant-location-update/",
  method: "POST",
};

// bbps

// paysprint bbps
export const bbpsOperatorList = {
  url: "api/s/paysprint-billpayment-operator-list/",
  method: "GET",
};
export const fetchGasBill = {
  url: "api/s/paysprint-billpayment-fetch-bill-detail/",
  method: "POST",
};
export const payLpgBill = {
  url: "api/s/paysprint-billpayment-pay-bill/",
  method: "POST",
};

// airtel bbps
export const airtelBbpsOperatorList = {
  url: "api/s/bbps-fetch-subcategory-biller/",
  method: "GET",
};
export const fetchBill = {
  url: "api/s/bbps-fetch-bill/",
  method: "POST",
};
export const validateBill = {
  url: "api/s/bbps-validate-bill/",
  method: "POST",
};
export const payBill = {
  url: "api/s/bbps-pay-bill/",
  method: "POST",
};
export const airtelBbpsHistory = {
  url: "api/s/bbps-history/",
  method: "GET",
};
export const airtelBbpsStatus = {
  url: "api/s/bbps-transaction-enquiry-bill/",
  method: "GET",
};
export const airtelBbpsInvoice = {
  url: "api/s/bbps-invoice-bill/ ",
  method: "GET",
};
