import React, { useEffect, useState } from "react";
import {
  getLedgerReport,
  selectedDropdown,
  merchantDropdownReport,
} from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import Filter2 from "../components/Filter2";
import Swal from "sweetalert2";

const LedgerReport = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [filters, setFilters] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedRd, setSelectedRd] = useState(null);
  const [selectedAd, setSelectedAd] = useState(null);
  const [selectedLd, setSelectedLd] = useState(null);
  const [selectedAg, setSelectedAg] = useState(null);
  const [rdOptions, setRdOptions] = useState([]);
  const [adOptions, setAdOptions] = useState([]);
  const [ldOptions, setLdOptions] = useState([]);
  const [agOptions, setAgOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  async function fetchApplicationExcel({
    filterValues = {},
    excludedKeys = [
      "brand_id",
      "rd_merchant_id",
      "ad_merchant_id",
      "ld_merchant_id",
    ],
  } = {}) {
    let selectedMerchantId = null;
    if (selectedAg) {
      selectedMerchantId = selectedAg;
    } else if (selectedLd) {
      selectedMerchantId = selectedLd;
    } else if (selectedAd) {
      selectedMerchantId = selectedAd;
    } else if (selectedRd) {
      selectedMerchantId = selectedRd;
    }

    if (!selectedMerchantId) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Selection",
        text: "Please select a valid merchant before downloading the Excel report.",
      });
      return;
    }

    const filteredValues = {
      ...filterValues,
      merchant_id: selectedMerchantId,
    };

    excludedKeys.forEach((key) => {
      delete filteredValues[key];
    });

    if (!filteredValues.start_date || !filteredValues.end_date) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Filters",
        text: "Please enter start and end date before downloading the Excel report.",
      });
      return;
    }
    if (!filteredValues.wallet_type) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Filters",
        text: "Please select the wallet type before downloading the Excel report.",
      });
      return;
    }

    try {
      return await new Promise((resolve, reject) => {
        fetchLedgerReport({
          filterValues: filteredValues,
          resolve,
          reject,
        });
      });
    } catch (error) {
      console.error("Error while fetching Excel report:", error);
      throw error;
    }
  }

  const fetchLedgerReport = ({
    filterValues = {},
    append = false,
    initial = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }

    const filteredObject = {};

    if (filterValues.start_date) {
      filteredObject["start_date"] = filterValues.start_date;
    }
    if (filterValues.end_date) {
      filteredObject["end_date"] = filterValues.end_date;
    }

    for (const key in filterValues) {
      if (key !== "start_date" && key !== "end_date") {
        filteredObject[key] = filterValues[key];
      }
    }

    setFilters(filteredObject);

    let selectedMerchantId = null;
    if (selectedAg) {
      selectedMerchantId = selectedAg;
    } else if (selectedLd) {
      selectedMerchantId = selectedLd;
    } else if (selectedAd) {
      selectedMerchantId = selectedAd;
    } else if (selectedRd) {
      selectedMerchantId = selectedRd;
    }

    const params = {
      ...filteredObject,
      ...pagination,
      ...(selectedMerchantId ? { merchant_id: selectedMerchantId } : {}),
      ...(resolve
        ? {
            include_excel: true,
            offset: 0,
            limit: pagination?.offset + pagination?.limit,
          }
        : {}),
    };

    API({
      ...getLedgerReport,
      params: initial ? { limit: 1 } : params,
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          console.log("test");
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
              setTotalRows(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        if (reject) {
          reject(error);
        }
      },
    });
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchLedgerReport({ append: true, filterValues: filters });
    }
  }, [pagination]);

  // merchant dropdown
  useEffect(() => {
    API({
      ...merchantDropdownReport,
      onSuccess: (res) => {
        setDropdown(res?.data?.data);
      },
      onError: (error) => {
        console.error("Error fetching merchant data: ", error);
      },
    });
  }, []);

  useEffect(() => {
    const idToUse = selectedLd
      ? selectedLd
      : selectedAd
      ? selectedAd
      : selectedRd
      ? selectedRd
      : selectedBrandId;

    if (idToUse) {
      const params = selectedLd
        ? { ld: selectedLd }
        : selectedAd
        ? { ad: selectedAd }
        : selectedRd
        ? { rd: selectedRd }
        : selectedBrandId
        ? { brand_id: selectedBrandId }
        : selectedAg
        ? { ag: selectedAg }
        : {};

      API({
        ...selectedDropdown,
        params: params,
        onSuccess: (res) => {
          const rdMerchants =
            res?.data?.data?.map((merchant) => ({
              value: merchant.merchant_id,
              label: merchant.name,
            })) || [];
          setRdOptions(rdMerchants);
          if (selectedRd) {
            const adMerchants =
              res?.data?.data?.map((merchant) => ({
                value: merchant.merchant_id,
                label: merchant.name,
              })) || [];
            setAdOptions(adMerchants);
          }
          if (selectedAd) {
            const ldMerchants =
              res?.data?.data?.map((merchant) => ({
                value: merchant.merchant_id,
                label: merchant.name,
              })) || [];
            setLdOptions(ldMerchants);
          }
          if (selectedLd) {
            const agMerchants =
              res?.data?.data?.map((merchant) => ({
                value: merchant.merchant_id,
                label: merchant.name,
              })) || [];
            setAgOptions(agMerchants);
          }
        },
        onError: (error) => {
          console.error("Error fetching selected dropdown data: ", error);
        },
      });
    } else {
      setRdOptions([]);
      setAdOptions([]);
      setLdOptions([]);
      setAgOptions([]);
    }
  }, [selectedBrandId, selectedRd, selectedAd, selectedLd]);

  const brandOptions =
    dropdown?.brand?.map((item) => ({
      value: item.brand_id,
      label: item.business_name,
    })) || [];

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Ledger Report
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchLedgerReport}
          forPage={"Ledger Report"}
          excelFunc={fetchApplicationExcel}
          loading={loading}
          setSelectedAd={setSelectedAd}
          setSelectedRd={setSelectedRd}
          setSelectedLd={setSelectedLd}
          setSelectedAg={setSelectedAg}
          setSelectedBrandId={setSelectedBrandId}
          excelFileName={"Ledger Report"}
          filters={[
            {
              placeholder: "Brand",
              id: "brand_id",
              filterType: "select",
              inputType: null,
              options: brandOptions.length
                ? brandOptions
                : [{ value: "", label: "No brands available" }],
              onChange: (selectedOption) => {
                setSelectedBrandId(selectedOption);
                setSelectedRd(null);
              },
            },

            {
              placeholder: "Start Date",
              id: "start_date",
              filterType: "date",
              singleDate: true,
            },
            {
              placeholder: "End Date",
              id: "end_date",
              filterType: "date",
              singleDate: true,
            },
          ]}
          extraFilters={[
            {
              placeholder: "Super Distributor",
              id: "rd_merchant_id",
              filterType: "select",
              inputType: null,
              options: rdOptions.length
                ? rdOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedRd(selectedOption);
              },
            },
            {
              placeholder: "Master Distributor",
              id: "ad_merchant_id",
              filterType: "select",
              inputType: null,
              options: adOptions.length
                ? adOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedAd(selectedOption);
              },
            },
            {
              placeholder: "Distributor",
              id: "ld_merchant_id",
              filterType: "select",
              inputType: null,
              options: ldOptions.length
                ? ldOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedLd(selectedOption);
              },
            },
            {
              placeholder: "Retailer",
              id: "merchant_id",
              filterType: "select",
              inputType: null,
              options: agOptions.length
                ? agOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedAg(selectedOption);
              },
            },
            {
              placeholder: "Wallet Type",
              id: "wallet_type",
              filterType: "select",
              inputType: null,
              options: [
                { label: "AEPS PAY", value: "AEPS_PAY" },
                { label: "MAIN WALLET", value: "MAIN_WALLET" },
              ],
            },
          ]}
          excludedKeys={[
            "brand_id",
            "rd_merchant_id",
            "ad_merchant_id",
            "ld_merchant_id",
          ]}
        ></Filter2>
        <PaginationTable
          columns={[
            { Header: "Merchant ID", accessor: "merchant_id" },
            { Header: "Transaction ID", accessor: "transaction_id" },
            { Header: "Transaction Date", accessor: "transaction_date" },
            { Header: "Transaction Time", accessor: "transaction_time" },
            { Header: "Request Type", accessor: "request_type" },
            { Header: "Opening Balance", accessor: "opening_balance" },
            { Header: "Closing Balance", accessor: "closing_balance" },
            { Header: "Amount", accessor: "amount" },
            { Header: "CR DR", accessor: "cr_dr" },
            { Header: "Status", accessor: "status" },
            { Header: "Bank Operator", accessor: "bank_operator" },
            { Header: "Account No", accessor: "account_no" },
            { Header: "RRN UTR", accessor: "rrn_utr" },
            {
              Header: "Reverse Refund Status",
              accessor: "reverse_refund_status",
            },
            { Header: "Platform", accessor: "platform" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={totalRows}
        />
      </div>
    </>
  );
};

export default LedgerReport;
