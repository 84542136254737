import React, { useEffect, useState } from "react";
import { currentTripDetails } from "../../../../urls";
import API from "../../../../components/api2";
import {
  availableSeats,
  femaleSeats,
  selectedFemaleSeats,
  selectedSeats,
  selectedSleeper,
  sleeperSeats,
  sleeperSeatsFilled,
  steering,
  unavailableSeats,
} from "../../../../images";
import SelectLocation from "./SelectLocation";
import PassengerDetails from "./PassengerDetails";
import { IoMdArrowRoundBack } from "react-icons/io";
import loaderGif from "../../../../gif/buffer.gif";

const SelectSeats = ({
  tripId,
  trips,
  proceedToPay,
  setProceedToPay,
  setShowSelectSeats,
  sourceId,
  destinationId,
}) => {
  const [seats, setSeats] = useState([]);
  const [selectedSeatNames, setSelectedSeatNames] = useState([]);
  const [selectedSeatFares, setSelectedSeatFares] = useState([]);
  const [activeTab, setActiveTab] = useState("upper");
  const [boardingBpId, setBoardingBpId] = useState(null);
  const [droppingBpId, setDroppingBpId] = useState(null);
  const [selectedSeatsDetails, setSelectedSeatsDetails] = useState([]);
  const [selectedBoardingPoint, setSelectedBoardingPoint] = useState(null);
  const [selectedDroppingPoint, setSelectedDroppingPoint] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSelectedLocation = (boarding, dropping) => {
    setSelectedBoardingPoint(boarding);
    setSelectedDroppingPoint(dropping);
  };

  useEffect(() => {
    const fetchCurrentTrip = () => {
      setLoading(true);
      API({
        ...currentTripDetails,
        body: { trip_id: tripId.tripId },
        onSuccess: (res) => {
          const { boardingTimes, droppingTimes } = res.data.data;

          if (Array.isArray(boardingTimes)) {
            setBoardingBpId(boardingTimes.map((point) => point.bpId));
          } else {
            setBoardingBpId(boardingTimes.bpId);
          }

          if (Array.isArray(droppingTimes)) {
            setDroppingBpId(droppingTimes.map((point) => point.bpId));
          } else {
            setDroppingBpId(droppingTimes.bpId);
          }

          setSeats(res.data.data.seats);
          setLoading(false);
        },
        onError: (error) => {
          setLoading(false);
          console.error("Error fetching current trip:", error);
        },
      });
    };
    fetchCurrentTrip();
  }, [tripId.tripId]);

  const lowerDeckSeats = seats.filter((seat) => seat.zIndex === "0");
  const upperDeckSeats = seats.filter((seat) => seat.zIndex === "1");

  const renderSeats = (deckSeats) => {
    return deckSeats.map((seat, index) => {
      let seatImage;
      if (selectedSeatNames.includes(seat.name)) {
        if (seat.ladiesSeat === "true") {
          seatImage = selectedFemaleSeats;
        } else if (seat.length === "2") {
          seatImage =
            seat.available === "true" ? selectedSleeper : sleeperSeatsFilled;
        } else {
          seatImage = selectedSeats;
        }
      } else if (seat.available === "false") {
        seatImage = seat.length === "2" ? sleeperSeatsFilled : unavailableSeats;
      } else if (seat.length === "2") {
        seatImage = sleeperSeats;
      } else if (seat.ladiesSeat === "true") {
        seatImage = femaleSeats;
      } else if (seat.malesSeat === "true") {
        seatImage = availableSeats;
      } else {
        seatImage = availableSeats;
      }

      const seatStyle = {
        gridColumn: `span ${seat.length === "2" ? 2 : 1}`,
        gridRow: parseInt(seat.row) + 1,
        gridColumnStart: parseInt(seat.column) + 1,
        cursor: seat.available === "false" ? "not-allowed" : "pointer",
        position: "relative",
        height: "60px",
        overflow: "hidden",
      };
      return (
        <div
          key={index}
          className="seat"
          style={seatStyle}
          onClick={() => handleSeatClick(seat)}
        >
          <img
            src={seatImage}
            alt={seat.name}
            style={{
              width:
                seat.length === "2" &&
                (seat.available === "true" || seat.available === "false")
                  ? "60px"
                  : "25px",
            }}
          />
        </div>
      );
    });
  };

  const handleSeatClick = (seat) => {
    if (seat.available === "false") return;

    setSelectedSeatNames((prev) =>
      prev.includes(seat.name)
        ? prev.filter((name) => name !== seat.name)
        : [...prev, seat.name]
    );

    setSelectedSeatsDetails((prev) =>
      prev.some((s) => s.name === seat.name)
        ? prev.filter((s) => s.name !== seat.name)
        : [
            ...prev,
            {
              name: seat.name,
              fare: seat.fare,
              baseFare: seat.baseFare,
              serviceTax: seat.serviceTaxAbsolute,
              operatorServiceChargeAbsolute: seat.operatorServiceChargeAbsolute,
              ladiesSeat: seat.ladiesSeat,
            },
          ]
    );
    setSelectedSeatFares((prev) =>
      prev.some((fare) => fare.name === seat.name)
        ? prev.filter((fare) => fare.name !== seat.name)
        : [...prev, { name: seat.name, fare: seat.fare }]
    );
  };

  useEffect(() => {
    if (upperDeckSeats.length > 0 && lowerDeckSeats.length === 0) {
      setActiveTab("upper");
    } else if (lowerDeckSeats.length > 0 && upperDeckSeats.length === 0) {
      setActiveTab("lower");
    }
  }, [upperDeckSeats, lowerDeckSeats]);

  // tab styles
  const styles = {
    tabs: {
      display: "flex",
      justifyContent: "space-around",
    },
    tab: (isActive) => ({
      border: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
      color: isActive ? "#023685" : "#333",
      transition: "border-bottom 0.3s ease, color 0.3s ease",
      fontWeight: "bold",
      borderBottom: isActive ? "3px solid #023685" : "3px solid transparent",
      borderRadius: "0px",
    }),
  };

  return proceedToPay ? (
    <PassengerDetails
      tripId={tripId}
      selectedBoardingPoint={selectedBoardingPoint}
      selectedDroppingPoint={selectedDroppingPoint}
      sourceId={sourceId}
      destinationId={destinationId}
      selectedSeatsDetails={selectedSeatsDetails}
      setProceedToPay={setProceedToPay}
    />
  ) : (
    <div
      className="h-100 position-relative"
      style={{ backgroundColor: "#EDEDED", borderRadius: "5px" }}
    >
      {/* Legend Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          transform: "rotate(-90deg)",
          position: "absolute",
          zIndex: "1000",
          left: "-15%",
          top: "50%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "#F1A9A0",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          ></div>
          <span>Female Seat</span>
        </div>
        <div className="mx-2" style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "darkgrey",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          ></div>
          <span>Filled Seat</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "white",
              border: "2px solid #ddd",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          ></div>
          <span>Empty Seat</span>
        </div>
      </div>
      {loading && (
        <div
          className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.7)", zIndex: 999 }}
        >
          <img
            src={loaderGif}
            alt="Loading..."
            style={{ width: "60px", height: "60px" }}
          />
        </div>
      )}

      {!loading && (
        <div className="container px-4">
          <div className="row justify-content-between">
            <div className="col-8 mt-2 position-relative">
              {upperDeckSeats.length > 0 || lowerDeckSeats.length > 0 ? (
                <div>
                  {upperDeckSeats.length > 0 && lowerDeckSeats.length > 0 && (
                    <div style={styles.tabs}>
                      <button
                        style={styles.tab(activeTab === "upper")}
                        onClick={() => setActiveTab("upper")}
                      >
                        Upper Deck
                      </button>
                      <button
                        style={styles.tab(activeTab === "lower")}
                        onClick={() => setActiveTab("lower")}
                      >
                        Lower Deck
                      </button>
                    </div>
                  )}

                  <div
                    className="bus-layout mt-2"
                    style={{
                      display: "grid",
                      position: "relative",
                      padding: "0 10px",
                    }}
                  >
                    {activeTab === "upper" && upperDeckSeats.length > 0 ? (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(10, 1fr)",
                          gridTemplateRows: "repeat(5, 60px)",
                          gap: "10px",
                        }}
                      >
                        {renderSeats(upperDeckSeats)}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(10, 1fr)",
                          gridTemplateRows: "repeat(5, 60px)",
                          gap: "10px",
                        }}
                      >
                        {renderSeats(lowerDeckSeats)}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-4">
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center justify-content-end mt-3"
                onClick={() => setShowSelectSeats(false)}
              >
                <IoMdArrowRoundBack />
                <p className="mb-0 ms-1 fw-bold">View other buses</p>
              </div>

              {Array.isArray(selectedSeatNames) &&
                selectedSeatNames.length > 0 && (
                  <SelectLocation
                    tripId={tripId}
                    trips={trips}
                    setProceedToPay={setProceedToPay}
                    selectedSeatNames={selectedSeatNames}
                    selectedSeatFares={selectedSeatFares}
                    handleSelectedLocation={handleSelectedLocation}
                  />
                )}
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .bus-layout {
          background-color: #ffffff;
          border: 2px solid #ddd;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .seat {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          border-radius: 5px;
          transition: background-color 0.3s ease;
        }
        .aisle {
          width: 60px;
          height: 60px;
          background-color: #f2f2f2;
        }
      `}</style>
    </div>
  );
};

export default SelectSeats;
