import React, { useState, useEffect } from "react";
import API from "../components/api2";
import { transactionHistory } from "../urls";
import { noImage2 } from "../images";
import { useTranslation } from "react-i18next";

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const limit = 10;
  const { t } = useTranslation();

  useEffect(() => {
    API({
      ...transactionHistory,
      params: { offset: 0, limit },
      onSuccess: (res) => {
        setTransactions(res.data.data.table_data);
      },
      onError: (error) => {
        console.error("Error fetching transactions:", error);
      },
    });
  }, []);

  const formatTransactionDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  return (
    <section className="h-100">
      <div className="container-fluid h-100">
        <p
          className="mb-0 ms-4 login_text"
          style={{
            fontWeight: "500",
            fontSize: "20px",
            color: "rgba(79, 87, 91, 1)",
          }}
        >
          {t("Transaction")}
        </p>
        <div style={{ overflowY: "auto", height: "90%" }}>
          {transactions.length > 0 ? (
            transactions.map((t) => (
              <div className="container-fluid" key={t.transaction_id}>
                <div className="row d-flex justify-content-between align-items-center mt-4">
                  <div className="col-9">
                    <div className="d-flex align-items-center">
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          border: "1.5px solid black",
                        }}
                        src={t.photo ? t.photo : noImage2}
                        alt={`trans${t.transaction_id}`}
                      />
                      <div className="ms-2">
                        <p
                          style={{
                            fontSize: "15px",
                            color: "rgba(79, 87, 91, 1)",
                            fontWeight: "400",
                          }}
                          className="mb-0 trans_text"
                        >
                          {t.full_name}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "rgba(79, 87, 91, 1)",
                            fontWeight: "400",
                          }}
                          className="mb-0 ms-1 trans_text"
                        >
                          {formatTransactionDate(t.transaction_date)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "#3D9189",
                          fontWeight: "400",
                        }}
                        className="mb-0 me-5 trans_text"
                      >
                        {t.credit_amount}
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "#F97C4B",
                          fontWeight: "400",
                        }}
                        className="mb-0 me-5 trans_text"
                      >
                        {t.debit_amount}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              className="mb-0 mt-5 pt-5 text-center"
              style={{ fontSize: "22px", color: "rgba(79, 87, 91, 1)" }}
            >
              {t("No transactions found")}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default TransactionHistory;
