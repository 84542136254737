import React, { useEffect, useState } from "react";
import API from "../../../components/api2";
import {
  bankTransferIcon,
  newLogo,
  serviceBackArrow,
  serviceCircle,
} from "../../../images";
import { discoverAvdm, CaptureAvdm } from "../../../utils/rd";
import axios from "axios";
import { aadharValidation, dmtEkyc } from "../../../urls";
import Swal from "sweetalert2";
import { FiRefreshCcw } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { changePage } from "../../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Header from "../../../components/Header";

const EkycPage = ({ contactNo, setCurrentPage, forPage }) => {
  const location = useLocation();
  const mobileNumber = contactNo || location.state?.contactNo || "";

  const [formData, setFormData] = useState({
    aadhaar_number: "",
    mobile_number: mobileNumber,
  });
  const [rd, setRD] = useState();
  const [fingerData, setFingerData] = useState();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAadharMessage, setShowAadharMessage] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isRotating, setIsRotating] = useState(false);
  const [ekycId, setEkycId] = useState("");
  const [stateRes, setStateRes] = useState("");

  // handle aadhar validation
  const handleAadharValidation = (aadhar) => {
    if (aadhar.length !== 12) return false;

    API({
      ...aadharValidation,
      body: {
        aadhar: aadhar,
      },
      onSuccess: (res) => {
        if (res.data.data.aadhar_validation) {
          setShowAadharMessage(false);
        } else {
          setShowAadharMessage(true);
        }
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "aadhaar_number") {
      const formattedValue = value.replace(/\D/g, "").slice(0, 12);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));

      handleAadharValidation(formattedValue);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // finger scan
  navigator.geolocation.getCurrentPosition(function (position) {
    setData((prev) => ({
      ...prev,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    }));
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await discoverAvdm();
        const trimmedDeviceName = data?.deviceInfo?.includes("Mantra MFS110")
          ? "Mantra MFS110"
          : data?.deviceInfo || "No device found";

        const deviceNameColor =
          trimmedDeviceName === "No device found" ? "red" : "white";

        setRD(data);
        setFormData((prevState) => ({
          ...prevState,
          device_name: trimmedDeviceName,
          device_name_color: deviceNameColor,
        }));
      } catch (error) {
        console.error("Error discovering device:", error);
        setFormData((prevState) => ({
          ...prevState,
          device_name: "No device found",
          device_name_color: "red",
        }));
      }
    };

    setFormData((prevState) => ({
      ...prevState,
      device_name: "No device found",
      device_name_color: "red",
    }));

    fetchData();
  }, [refreshKey]);

  const captureFingerData = (e) => {
    e.preventDefault();
    CaptureAvdm(rd, { wadh: true }).then((xml) => {
      setFingerData(xml);
    });
  };
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        const ipAddress = response.data.ip;
        setData((prev) => ({
          ...prev,
          ip: ipAddress,
        }));
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  // handle refresh
  const handleRefresh = () => {
    setIsRotating(true);
    setRefreshKey((prevKey) => prevKey + 1);
    setTimeout(() => setIsRotating(false), 1000);
  };

  // handle continue
  const handleContinue = (e) => {
    e.preventDefault();
    setLoading(true);

    const { device_name, device_name_color, ...filteredFormData } = formData;

    API({
      ...dmtEkyc,
      body: {
        ...filteredFormData,
        data: fingerData,
        latitude: data.latitude,
        longitude: data.longitude,
      },
      onSuccess: (res) => {
        const ekycIdRes = res?.data?.data?.data?.ekyc_id;
        const stateResData = res?.data?.data?.data?.stateresp;

        setEkycId(ekycIdRes);
        setStateRes(stateResData);
        setLoading(false);

        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          changePageFunc(ekycIdRes, stateResData);
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changePageFunc = (ekycIdRes, stateResData) => {
    dispatch(
      changePage({
        page: "remitterReg",
      })
    );

    navigate("/", {
      state: {
        mobile: formData.mobile_number,
        stateResp: stateResData,
        ekyc_id: ekycIdRes,
      },
    });
  };
  const handleBack = (targetPage) => {
    dispatch(
      changePage({
        page: targetPage,
      })
    );
    navigate("/", {});
  };

  return (
    <>
      {forPage === "NewDmtCust" && (
        <>
          <div
            style={{ backgroundColor: "#D9D9D9" }}
            className="px-2 py-2 d-flex justify-content-between"
          >
            <img
              onClick={() => handleBack("home")}
              style={{
                width: "220px",
                cursor: "pointer",
                pointerEvents: "all",
                marginLeft: "20px",
                padding: "6px 0",
              }}
              src={newLogo}
              alt="logo"
            />
            <Header
              style={{
                width: "75%",
                backgroundColor: "#194280",
                borderRadius: "10px",
                color: "#194280",
                position: "sticky",
                padding: "10px 0px",
                top: "0",
              }}
            />
          </div>
          <div className="mb-2 mt-1 mx-5 px-2 d-flex align-items-center">
            <div
              className="mb-0 px-2 d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                borderRadius: "5px",
                color: "white",
                height: "50px",
                cursor: "pointer",
                pointerEvents: "all",
              }}
              onClick={() => handleBack("services")}
            >
              <img src={serviceBackArrow} alt="back" />
            </div>
            <div className="d-flex ms-2 flex-wrap text-center justify-content-center">
              <div
                style={{ cursor: "pointer", margin: "0 20px" }}
                className="d-flex flex-column text-center position-relative "
              >
                <img
                  style={{ width: "40px", margin: "6px auto 0 auto" }}
                  src={bankTransferIcon}
                  alt="icon"
                />
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  className="mb-0 login_text selected_service"
                >
                  Bank Transfer
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="col-11 position-relative mx-auto mt-2"
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
          border: "1px solid #D9D9D9",
          boxShadow: "0px 4px 40px 10px #00000026",
        }}
      >
        <img
          style={{
            position: "absolute",
            right: "0",
            top: "0",
          }}
          src={serviceCircle}
          alt="circle"
        />
        <div className="mt-3 px-2">
          <div className="row mb-3">
            <div className="col-7">
              <div
                className="px-4 position-relative"
                style={{
                  backgroundColor: "rgba(25, 66, 128, 1)",
                  borderRadius: "20px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                    width: "120px",
                  }}
                  src={serviceCircle}
                  alt="circle"
                />

                <div className="mx-2 pt-2 pb-4 col-6">
                  <label
                    htmlFor="aadhaar_number"
                    style={{
                      color: "#ADACAC",
                      transition: "all 0.4s ease-in-out",
                      fontSize: "14px",
                      opacity: formData.aadhaar_number ? 1 : 0,
                      marginTop: "8px",
                    }}
                    className="ms-2 login_text"
                  >
                    Aadhar Number
                  </label>
                  <input
                    type="number"
                    id="aadhaar_number"
                    name="aadhaar_number"
                    value={formData.aadhaar_number}
                    onChange={handleInputChange}
                    placeholder="Aadhar Number"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: formData.aadhaar_number
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: "white",
                      borderRadius: "0px",
                      padding: "0px 8px",
                    }}
                    autoComplete="off"
                    className="login_text"
                  />
                  {showAadharMessage && (
                    <div
                      className="fs_xl"
                      style={{
                        color: "yellow",
                        marginTop: "8px",
                        fontSize: "12px",
                      }}
                    >
                      The aadhar number is invalid.
                    </div>
                  )}
                  <label
                    htmlFor="mobile_number"
                    style={{
                      color: "#ADACAC",
                      transition: "all 0.4s ease-in-out",
                      fontSize: "14px",
                      opacity: mobileNumber ? 1 : 0,
                      marginTop: "8px",
                    }}
                    className="ms-2 login_text"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    id="mobile_number"
                    name="mobile_number"
                    value={formData.mobile_number}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: mobileNumber
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: "white",
                      borderRadius: "0px",
                      padding: "0px 8px",
                    }}
                    autoComplete="off"
                    className="login_text"
                    readOnly={forPage !== "NewDmtCust"}
                  />
                  <div className="d-flex align-items-end justify-content-between">
                    <div>
                      <label
                        htmlFor="device_name"
                        style={{
                          color: "#ADACAC",
                          transition: "all 0.4s ease-in-out",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                        className="ms-2 login_text mt-2"
                      >
                        Device Name
                      </label>
                      <input
                        type="text"
                        id="device_name"
                        name="device_name"
                        value={formData.device_name}
                        onChange={handleInputChange}
                        placeholder="Device Name"
                        style={{
                          backgroundColor: "transparent",
                          color: formData.device_name_color,
                          width: "100%",
                          borderRadius: "0px",
                          padding: "0px 8px",
                        }}
                        autoComplete="off"
                        className="login_text"
                        readOnly
                      />
                    </div>
                    <div>
                      <FiRefreshCcw
                        className={isRotating ? "rotating" : ""}
                        style={{
                          color: "white",
                          fontSize: "15px",
                          marginTop: "-4px",
                          cursor: "pointer",
                        }}
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      className="px-4 py-2 login_text"
                      style={{
                        backgroundColor: fingerData ? "green" : "white",
                        color: fingerData ? "white" : "black",
                        borderRadius: "10px",
                        border: "none",
                        fontSize: "15px",
                        fontWeight: "500",
                        cursor: !loading ? "pointer" : "not-allowed",
                        opacity: !loading ? 1 : 0.5,
                      }}
                      disabled={loading}
                      onClick={
                        rd
                          ? fingerData
                            ? handleContinue
                            : captureFingerData
                          : null
                      }
                    >
                      {loading
                        ? "Loading..."
                        : fingerData
                        ? "Continue"
                        : "Capture"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EkycPage;
