import React, { useEffect, useState } from "react";
import { getNewCommissionStruct, getMenuShortcutNew } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles } from "../images";
import Swal from "sweetalert2";
import Select from "react-select";

const CommissionStructure = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  useEffect(() => {
    API({
      ...getMenuShortcutNew,
      onSuccess: (res) => {
        setDropdown(res?.data?.data);
      },
      onError: (error) => {
        console.error("Error fetching service dropdown: ", error);
      },
    });
  }, []);

  const fetchCommission = (filterValues = {}) => {
    setLoading(true);

    API({
      ...getNewCommissionStruct,
      params: filterValues,
      onSuccess: (res) => {
        setLoading(false);
        const data = res?.data?.data;
        setFilteredTable(data);

        if (columns.length === 0 && data.length > 0) {
          const dynamicColumns = Object.keys(data[0])
            .filter((key) => key !== "service_name")
            .map((key) => ({
              Header: key.replace(/_/g, " ").toUpperCase(),
              accessor: key,
            }));
          setColumns(dynamicColumns);
        }
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleFilterChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setFilterValue(value);
    if (value) {
      fetchCommission({ service_name: value });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredTable.length / entriesPerPage);
  const paginatedData = filteredTable.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const options = dropdown.map((item) => ({
    value: item.utility_service_name,
    label: item.utility_service_name,
  }));

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-3"
          >
            Commission Structure
          </h2>
          <div
            style={{ marginTop: "-8px" }}
            className="d-flex align-items-center pe-4 me-5"
          >
            <Select
              options={options}
              value={
                filterValue ? { value: filterValue, label: filterValue } : null
              }
              onChange={(selectedOption) => handleFilterChange(selectedOption)}
              placeholder="Select Service Name"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: "#fff5d4",
                  borderRadius: "10px",
                  width: "250px",
                  border: "none",
                  boxShadow: "none",
                  fontFamily: "Montserrat",
                  padding: "4px 0",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "black",
                }),
                singleValue: (base) => ({
                  ...base,
                  color: "black",
                }),
              }}
            />
          </div>
        </div>

        <div className="table-responsive px-3 mt-3">
          <table
            className="table table-hovered table-striped"
            style={{ border: "1px solid #E6E6E6" }}
          >
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th key={index}>{col.Header}</th>
                ))}
              </tr>
            </thead>
            <tbody style={{ border: "1px solid #E6E6E6" }}>
              {loading ? (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : filteredTable.length > 0 ? (
                paginatedData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((col, colIndex) => (
                      <td key={colIndex}>{row[col.accessor]}</td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {filterValue && filteredTable.length > 0 && (
          <div
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "#fff",
              padding: "10px",
            }}
            className="d-flex justify-content-end align-items-center me-4"
          >
            <button
              style={{
                borderRadius: "5px",
                fontSize: "14px",
                backgroundColor: "#194280",
                opacity: currentPage === 1 ? 0.5 : 1,
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
              }}
              className="me-2 px-3 py-1 text-white border-0"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              First
            </button>
            <button
              style={{
                borderRadius: "5px",
                fontSize: "14px",
                backgroundColor: "#194280",
                opacity: currentPage === 1 ? 0.5 : 1,
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
              }}
              className="me-2 px-3 py-1 text-white border-0"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-3">
              Page <strong>{currentPage}</strong> of {totalPages}
            </span>
            <button
              style={{
                borderRadius: "5px",
                fontSize: "14px",
                backgroundColor: "#194280",
                opacity: currentPage === totalPages ? 0.5 : 1,
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
              className="ms-2 px-3 py-1 text-white border-0"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              style={{
                borderRadius: "5px",
                fontSize: "14px",
                backgroundColor: "#194280",
                opacity: currentPage === totalPages ? 0.5 : 1,
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
              className="ms-2 px-3 py-1 text-white border-0"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CommissionStructure;
