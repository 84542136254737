import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateWorkflow,
  getWorkflow,
  workflowActive,
  helpDeskCategory,
  getAssignEmpHelpdesk,
  getAllUserGroupsDropdown,
  getAdditionalFields,
  workflowFilters,
} from "../urls";
import InputForm from "../components/InputForm";
import { splitFormatTime } from "../utils/CommonFunctions";
import API from "../components/api2";
import {
  active,
  add,
  circles,
  dashed,
  dashed2,
  inactive,
  stepAdd,
} from "../images";
import Swal from "sweetalert2";
import Alert from "../components/Alert";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import TextEditor from "../components/TextEditor";
import { fetchHtmlContent } from "../utils/CommonFunctions";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Header from "../components/Header";

const WorkflowInfo = () => {
  const location = useLocation();
  const service = location.state || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [businessScheme, setBusinessScheme] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [showAlertActive, setShowAlertActive] = useState(false);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextActive, setAlertTextActive] = useState("");
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [removedEmployees, setRemovedEmployees] = useState([]);
  const [addFields, setAddFields] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [walletColor, setWalletColor] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [removedSteps, setRemovedSteps] = useState([]);
  const [editableFields, setEditableFields] = useState({
    workflow_name: "",
    description: "",
    type: "",
    category: "",
    subcategory: "",
    assigned_to: [],
    tat: "",
    escalation1: "",
    escalation2: "",
    escalation3: "",
    escalation1_tat: "",
    escalation2_tat: "",
    escalation3_tat: "",
    subject: "",
  });
  const [template, setTemplate] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [tabData, setTabData] = useState({
    0: {
      stepData: {
        escalation1: null,
        escalation1_tat: "",
        escalation2: null,
        escalation2_tat: "",
        escalation3: null,
      },
      selectedEmployees: [],
    },
  });

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allWorkflows",
      })
    );

    navigate("/");
  };

  useEffect(() => {
    API({
      ...workflowFilters,
      onSuccess: (res) => {
        setTypeOptions(
          res.data.data.types.map((t) => ({
            value: t,
            label: t,
          }))
        );
      },
      onError: (error) => {
        console.error("Error getting type:", error);
      },
    });
  }, []);

  // alert ***************************
  const hideAlert = () => {
    setShowAlertUpdate(false);
  };
  const hideAlertActive = () => {
    setIsActive(initialIsActive);
    setShowAlertActive(false);
  };
  const showAlertWithTextActive = (text, walletColor) => {
    setAlertTextActive(text);
    setShowAlertActive(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickActive = () => {
    showAlertWithTextActive("Are you sure you want to", "green");
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  // category,subcategory and type dropdown ********
  const fetchCategories = () => {
    API({
      ...helpDeskCategory,
      params: { type: editableFields.type },
      onSuccess: (res) => {
        const categories = res.data.data.category_data?.map((category) => ({
          value: category.category,
          label: category.category,
        }));
        setCategoryOptions([
          { value: "", label: "Select Category" },
          ...categories,
        ]);
      },
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    });
  };

  const fetchSubcategories = (category) => {
    API({
      ...helpDeskCategory,
      params: { type: editableFields.type, category },
      onSuccess: (res) => {
        const subcategories = res.data.data.subcategory_data.map(
          (subcategory) => ({
            value: subcategory.subcategory,
            label: subcategory.subcategory,
          })
        );
        setSubcategoryOptions([
          { value: "", label: "Select Sub Category" },
          ...subcategories,
        ]);
      },
      onError: (error) => {
        console.error("Error fetching subcategories:", error);
      },
    });
  };

  useEffect(() => {
    if (editableFields?.type) {
      fetchCategories();
    }
  }, [editableFields?.type]);

  useEffect(() => {
    if (editableFields?.type && editableFields?.category) {
      fetchSubcategories(editableFields.category);
    }
  }, [editableFields?.type, editableFields?.category]);

  // get category and sub category
  useEffect(() => {
    API({
      ...helpDeskCategory,
      onSuccess: (res) => {
        const category = res.data.data.map((c) => ({
          value: c.category,
          label: c.category,
        }));
        setCategoryOptions(category);
        const subCategory = res.data.data?.map((c) => ({
          value: c.subcategory,
          label: c.subcategory,
        }));
        setSubcategoryOptions(subCategory);
      },
      onError: (error) => {
        console.error("Error fetching categories: ", error);
      },
    });
  }, []);

  const handleInputChange = (fieldName, value, activeTab = false) => {
    if (activeTab !== false) {
      let newSteps = [...editableFields.steps];
      newSteps[activeTab] = { ...newSteps[activeTab], [fieldName]: value };

      setEditableFields((prevFields) => ({
        ...prevFields,
        steps: newSteps,
      }));
    } else {
      setEditableFields((prevFields) => ({
        ...prevFields,
        [fieldName]: value,
      }));
    }
  };

  // additional field
  useEffect(() => {
    API({
      ...getAdditionalFields,
      params: { dropdown: "1" },
      onSuccess: (res) => {
        const fields = res.data.data.custom_fields.map((a) => ({
          value: a.id,
          label: a.field_label,
        }));
        setAddFields(fields);
      },
      onError: (error) => {
        console.error("Error: ", error);
      },
    });
  }, []);

  // fetch workflow by workflow id *****************
  useEffect(() => {
    const fetchWorkflow = () => {
      API({
        ...getWorkflow,
        params: { workflow_sid: service?.service?.workflow_sid },
        onSuccess: (res) => {
          const workflowData = res.data.data;
          setBusinessScheme(workflowData);
          setEditableFields(workflowData);
          setAdditionalFields(
            workflowData.additional_fields.map((field) => ({
              id: field.id,
              value: field.id,
              label: field.field_label,
            }))
          );
          getHtml(workflowData?.template);
          renderContent();
        },
        onError: (error) => {
          console.error("Error fetching workflow: ", error);
        },
      });
    };
    fetchWorkflow();
  }, [service?.service?.workflow_sid]);

  const getHtml = async (url) => {
    const htmlData = await fetchHtmlContent(url);
    setTemplate(htmlData);
  };
  // additional fields
  const handleFieldChange = (selectedOption, index) => {
    const updatedFields = [...additionalFields];
    updatedFields[index] = {
      ...updatedFields[index],
      value: selectedOption ? selectedOption.value : null,
      label: selectedOption ? selectedOption.label : "",
    };
    setAdditionalFields(updatedFields);

    validateButtonState(updatedFields);
  };
  const validateButtonState = (fields) => {
    let shouldDisableButton = false;
    let message = "";

    const hasValue10 = fields.some((field) => field.value === 10);
    const hasValue9 = fields.some((field) => field.value === 9);
    const hasValue11Or12 = fields.some(
      (field) => field.value === 11 || field.value === 12
    );

    if (hasValue10) {
      if (!hasValue9) {
        message = "Please add field and select Branch from options";
        shouldDisableButton = true;
      }
    } else if (hasValue11Or12) {
      if (!hasValue10 || !hasValue9) {
        message = "Please add field and select Center ID from options";
        shouldDisableButton = true;
      }
    }

    setErrorMessage(message);
    setIsButtonDisabled(shouldDisableButton);
  };
  const handleAddField = () => {
    setAdditionalFields([
      ...additionalFields,
      { id: additionalFields.length, value: null, label: "" },
    ]);

    validateButtonState(additionalFields);
  };

  const getAvailableOptions = (index) => {
    const selectedValues = additionalFields
      ?.map((field) => field.value)
      ?.filter((value) => value !== null);
    return addFields.filter(
      (option) =>
        !selectedValues.includes(option.value) ||
        option.value === additionalFields[index].value
    );
  };

  const handleDeleteField = (index) => {
    const updatedFields = additionalFields
      .filter((_, idx) => idx !== index)
      .map((field, idx) => ({
        ...field,
        id: idx,
      }));
    setAdditionalFields(updatedFields);
  };

  // steps *****************************
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    if (
      editableFields &&
      editableFields.steps &&
      editableFields.steps.length > 0
    ) {
      const { steps } = editableFields;
      const newTabs = steps.map((_, index) => ({
        id: index + 1,
        title: `Step ${index + 1}`,
      }));
      setTabs(newTabs);
    }
  }, [editableFields]);

  // handle tab click
  const handleTabClick = (index) => {
    if (index !== activeTab) {
      setTabData((prevData) => ({
        ...prevData,
        [activeTab]: {
          pageData: { ...editableFields },
          selectedEmployees: [...selectedEmployees],
        },
      }));

      setActiveTab(index);
      setSelectedEmployees(editableFields.steps[index]?.assigned_to || []);
    }
  };
  useEffect(() => {
    if (
      editableFields &&
      editableFields.steps &&
      editableFields.steps.length > 0
    ) {
      setSelectedEmployees(editableFields.steps[activeTab]?.assigned_to || []);
    }
  }, [editableFields, activeTab]);

  //get employee options
  useEffect(() => {
    API({
      ...getAssignEmpHelpdesk,
      onSuccess: (res) => {
        const emp = res.data.data.map((e) => ({
          value: e.employee_id,
          label: `${e.employee_id}-${e.employee_name}`,
        }));
        setEmpOptions(emp);
      },
      onError: (error) => {
        console.error("Error employee: ", error);
      },
    });
  }, []);

  useEffect(() => {
    API({
      ...getAllUserGroupsDropdown,
      onSuccess: (res) => {
        const group = res.data.data.usergroups.map((g) => ({
          value: g.id,
          label: `${g.id}-${g.name}`,
        }));
        setGroupOptions(group);
      },
      onError: (error) => {
        console.error("Error: ", error);
      },
    });
  }, []);

  const handleAddEmployee = () => {
    setSelectedEmployees([...selectedEmployees, ""]);
  };

  // active inactive **************************
  const { active_status } = businessScheme;
  const initialIsActive = active_status !== "A";

  const [isActive, setIsActive] = useState(initialIsActive);

  useEffect(() => {
    const { active_status } = businessScheme;
    setIsActive(active_status !== "A");
  }, [businessScheme]);

  const handleConfirmActive = (actionText) => {
    const isActive = actionText === "Active" ? 1 : 0;
    API({
      ...workflowActive,
      body: {
        workflow_sid: editableFields.workflow_sid,
        active_status: isActive,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: ` The workflow has been successfully ${
            isActive === 0 ? "Deactivate" : "Activate"
          } `,
        }).then(() => window.location.reload());
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertActive(false);
  };
  const sliderContent = isActive ? "Inactive" : "Active";
  const imageSource = isActive ? inactive : active;

  const handleToggleSwitch = () => {
    setIsActive((prev) => {
      return !prev;
    });
  };
  // ************************************************

  // handle update
  const handleUpdate = () => {
    setIsLoading(true);

    const filteredSteps = editableFields.steps.filter(
      (_, index) => !removedSteps.includes(index)
    );

    const updatedSteps = filteredSteps.map((step) => {
      return {
        ...step,
        assigned_to: step.assigned_to.map((employee) => employee.id),
      };
    });

    const additionalFieldIds = additionalFields
      .filter((field) => field.value !== null)
      .map((field) => field.value);

    const updatedData = {
      ...editableFields,
      template,
      steps: updatedSteps,
      additional_fields: additionalFieldIds,
    };

    API({
      ...updateWorkflow,
      body: updatedData,
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
        setIsLoading(false);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setIsLoading(false);
      },
    });
    setShowAlertUpdate(false);
  };
  useEffect(() => {
    if (
      editableFields &&
      editableFields.steps &&
      editableFields.steps[activeTab]
    ) {
      const step = editableFields.steps[activeTab];
      if (step && step.assigned_to) {
        setSelectedEmployees(step.assigned_to);
      }
    }
  }, [editableFields, activeTab]);

  // new tat handle

  const handleTimeChange = (type, value, fieldName, activeTab) => {
    let newSteps = [...editableFields?.steps];
    const currentTat = newSteps?.[activeTab][fieldName] || "00 days, 00:00:00";

    // Split the current time into days, hours, minutes
    const [daysPart, timePart] = currentTat.split(" days, ");
    const [hours, minutes, seconds] = timePart.split(":");
    const days = daysPart.padStart(2, "0");

    let updatedTat;

    // Update days, hours, or minutes based on the input type
    if (type === "days") {
      updatedTat = `${value.padStart(
        2,
        "0"
      )} days, ${hours}:${minutes}:${seconds}`;
    } else if (type === "hours") {
      updatedTat = `${days} days, ${value.padStart(
        2,
        "0"
      )}:${minutes}:${seconds}`;
    } else if (type === "minutes") {
      updatedTat = `${days} days, ${hours}:${value.padStart(
        2,
        "0"
      )}:${seconds}`;
    }

    // Update the steps with the new time
    newSteps[activeTab] = { ...newSteps[activeTab], [fieldName]: updatedTat };

    // Update the editable fields
    setEditableFields((prevFields) => ({
      ...prevFields,
      steps: newSteps,
    }));
  };

  // render content
  const renderContent = () => {
    const { steps } = editableFields;

    if (!editableFields || !steps || steps.length === 0) {
      return (
        <div
          style={{ fontFamily: "Montserrat", color: "rgba(105, 102, 102, 1)" }}
          className="pt-5 ms-5"
        >
          No steps available
        </div>
      );
    }

    const step = steps[activeTab];
    if (!step) {
      return (
        <div
          style={{ fontFamily: "Montserrat", color: "rgba(105, 102, 102, 1)" }}
          className="pt-5 ms-5"
        >
          Step not found
        </div>
      );
    }

    return (
      <>
        <div key={step.step_id}>
          {/* step name  */}
          <div className="mx-4 px-4 mt-4 col-4">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Step Name
            </p>
            <input
              type="text"
              name="step_name"
              className="p-2 w-100 mt-1"
              value={step.step_name}
              onChange={(e) =>
                handleInputChange("step_name", e.target.value, activeTab)
              }
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
            />
          </div>
          {/* / assign to  */}
          <div
            className="mt-5 px-5 py-4 mx-5 position-relative dash_line"
            style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assign To
              </h3>
              <div className="col-6">
                <div
                  className="col-7 mx-auto"
                  style={{
                    border: "1.5px solid rgba(105, 102, 102, 1)",
                    borderRadius: "10px",
                  }}
                  onClick={handleAddEmployee}
                >
                  <div
                    className="d-flex align-items-center justify-content-center py-1 px-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={add} alt="add-employee" />
                    <p
                      className="mb-0 ms-2"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "rgba(105, 102, 102, 1)",
                      }}
                    >
                      {" "}
                      Add New User Group
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                  marginBottom: "-5px",
                }}
                htmlFor="assigned_to"
                className="form-label"
              >
                User Groups
              </label>
              <div className="d-flex row justify-content-between">
                {selectedEmployees.map((employee, index) => (
                  <div
                    className="col-6 d-flex align-items-center"
                    key={`employee-${index}`}
                  >
                    <Select
                      id={`assigned_to_${index}`}
                      name={`assigned_to_${index}`}
                      value={
                        groupOptions.find(
                          (option) => option.value === employee.id
                        ) || null
                      }
                      onChange={(selectedOption) => {
                        const updatedEmployees = [...selectedEmployees];
                        updatedEmployees[index] = selectedOption
                          ? {
                              id: selectedOption.value,
                              name: selectedOption.label.split("-")[1],
                            }
                          : null;
                        setSelectedEmployees(updatedEmployees);
                        handleInputChange(
                          "assigned_to",
                          updatedEmployees,
                          activeTab
                        );
                      }}
                      options={groupOptions.filter(
                        (option) =>
                          !selectedEmployees
                            .map((emp) => emp?.id)
                            .includes(option.value)
                      )}
                      placeholder="Select User Group"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "310px",
                          backgroundColor: "rgba(255, 231, 159, 0.4)",
                          borderRadius: "10px",
                          marginTop: "8px",
                          color: "black",
                          border: "none",
                          boxShadow: "none",
                          fontFamily: "Montserrat",
                          outline: "none",
                        }),
                      }}
                    />

                    <MdDelete
                      onClick={() => {
                        const updatedEmployees = selectedEmployees.filter(
                          (_, i) => i !== index
                        );
                        setSelectedEmployees(updatedEmployees);
                        handleInputChange(
                          "assigned_to",
                          updatedEmployees,
                          activeTab
                        );
                      }}
                      style={{
                        color: "grey",
                        fontSize: "20px",
                        marginTop: "8px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="row mt-4"></div>
          </div>

          {/* tat  */}
          <div
            className="mt-5 px-5 py-4 mx-5 position-relative dash_line2"
            style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              If ticket status not closed in
            </h3>

            <div className="row mt-4">
              <div className="col-12">
                <select
                  name="days"
                  className="p-2 w-33 mt-1 border-0"
                  value={splitFormatTime(step.tat).days}
                  onChange={(e) =>
                    handleTimeChange("days", e.target.value, "tat", activeTab)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {Array.from({ length: 31 }).map((_, i) => (
                    <option key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")} days
                    </option>
                  ))}
                </select>

                <select
                  name="hours"
                  className="p-2 w-33 mt-1 mx-3 border-0"
                  value={splitFormatTime(step.tat).hours}
                  onChange={(e) =>
                    handleTimeChange("hours", e.target.value, "tat", activeTab)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {Array.from({ length: 24 }).map((_, i) => (
                    <option key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")} hrs
                    </option>
                  ))}
                </select>

                <select
                  name="minutes"
                  className="p-2 w-33 mt-1 border-0"
                  value={splitFormatTime(step.tat).minutes}
                  onChange={(e) =>
                    handleTimeChange(
                      "minutes",
                      e.target.value,
                      "tat",
                      activeTab
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {Array.from({ length: 60 }).map((_, i) => (
                    <option key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")} mins
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* escalation 1 */}
          <div className="position-relative ">
            <img
              style={{
                position: "absolute",
                right: "25%",
                top: "-10%",
                width: "420px",
              }}
              src={dashed}
              alt="dashed"
            />
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                right: "10%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              className="mb-0 position-absolute"
            >
              Escalation 1
            </p>
            <div
              className="col-8 px-5 py-4 mx-5 position-relative dash_line"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
                marginTop: "80px",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assign To
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <label
                    style={{
                      marginTop: "8px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                      fontFamily: "Montserrat",
                    }}
                    htmlFor={`escalation1`}
                    className="form-label"
                  >
                    Select Employee
                  </label>
                  <Select
                    id={`escalation1`}
                    name={`escalation1`}
                    value={empOptions.find(
                      (option) => Number(option.value) === step.escalation1
                    )}
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "escalation1",
                        selectedOption.value,
                        activeTab
                      )
                    }
                    options={empOptions}
                    placeholder="Select employee"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159,0.4)",
                        borderRadius: "10px",
                        marginTop: "8px",
                        color: "black",
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Montserrat",
                        outline: "none",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-8 mt-5 px-5 py-4 mx-5 position-relative dash_line2"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                If ticket status not closed in
              </h3>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-flex">
                    <select
                      className="p-2 border-0"
                      name="days"
                      value={splitFormatTime(step.escalation1_tat).days}
                      onChange={(e) =>
                        handleTimeChange(
                          "days",
                          e.target.value,
                          "escalation1_tat",
                          activeTab
                        )
                      }
                      style={{
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                        marginRight: "4px",
                      }}
                    >
                      {[...Array(30).keys()].map((i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")} days
                        </option>
                      ))}
                    </select>
                    <select
                      className="border-0 mx-3"
                      name="hours"
                      value={splitFormatTime(step.escalation1_tat).hours}
                      onChange={(e) =>
                        handleTimeChange(
                          "hours",
                          e.target.value,
                          "escalation1_tat",
                          activeTab
                        )
                      }
                      style={{
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                        marginRight: "4px",
                      }}
                    >
                      {[...Array(24).keys()].map((i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")} hrs
                        </option>
                      ))}
                    </select>
                    <select
                      className="border-0"
                      name="minutes"
                      value={splitFormatTime(step.escalation1_tat).minutes}
                      onChange={(e) =>
                        handleTimeChange(
                          "minutes",
                          e.target.value,
                          "escalation1_tat",
                          activeTab
                        )
                      }
                      style={{
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {[...Array(60).keys()].map((i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")} mins
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* escalation 2  */}
          <div className="position-relative">
            <img
              style={{
                position: "absolute",
                right: "25%",
                top: "-10%",
                width: "420px",
              }}
              src={dashed}
              alt="dashed"
            />
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                right: "10%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              className="mb-0 position-absolute"
            >
              Escalation 2
            </p>
            <div
              className="col-8 px-5 py-4 mx-5 position-relative dash_line"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
                marginTop: "80px",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assign To
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <label
                    style={{
                      marginTop: "8px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                      fontFamily: "Montserrat",
                    }}
                    htmlFor={`escalation2`}
                    className="form-label"
                  >
                    Select Employee
                  </label>
                  <Select
                    id={`escalation2`}
                    name={`escalation2`}
                    value={empOptions.find(
                      (option) => Number(option.value) === step.escalation2
                    )}
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "escalation2",
                        selectedOption.value,
                        activeTab
                      )
                    }
                    options={empOptions}
                    placeholder="Select employee"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159,0.4)",
                        borderRadius: "10px",
                        marginTop: "8px",
                        color: "black",
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Montserrat",
                        outline: "none",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-8 mt-5 px-5 py-4 mx-5 position-relative dash_line2"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                If ticket status not closed in
              </h3>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-flex">
                    <select
                      className="p-2 border-0"
                      name="days"
                      value={splitFormatTime(step.escalation2_tat).days}
                      onChange={(e) =>
                        handleTimeChange(
                          "days",
                          e.target.value,
                          "escalation2_tat",
                          activeTab
                        )
                      }
                      style={{
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                        marginRight: "4px",
                      }}
                    >
                      {[...Array(30).keys()].map((i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")} days
                        </option>
                      ))}
                    </select>
                    <select
                      name="hours"
                      className="border-0 mx-3"
                      value={splitFormatTime(step.escalation2_tat).hours}
                      onChange={(e) =>
                        handleTimeChange(
                          "hours",
                          e.target.value,
                          "escalation2_tat",
                          activeTab
                        )
                      }
                      style={{
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                        marginRight: "4px",
                      }}
                    >
                      {[...Array(24).keys()].map((i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")} hrs
                        </option>
                      ))}
                    </select>
                    <select
                      name="minutes"
                      className="border-0"
                      value={splitFormatTime(step.escalation2_tat).minutes}
                      onChange={(e) =>
                        handleTimeChange(
                          "minutes",
                          e.target.value,
                          "escalation2_tat",
                          activeTab
                        )
                      }
                      style={{
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {[...Array(60).keys()].map((i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")} mins
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* escalation 3  */}
          <div className="position-relative">
            <img
              style={{
                position: "absolute",
                right: "25%",
                top: "-10%",
                width: "420px",
              }}
              src={dashed2}
              alt="dashed"
            />
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                right: "10%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              className="mb-0 position-absolute"
            >
              Escalation 3
            </p>
            <div
              className="col-8 px-5 py-4 mx-5 position-relative"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
                marginTop: "80px",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assign To
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <label
                    style={{
                      marginTop: "8px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                      fontFamily: "Montserrat",
                    }}
                    htmlFor={`escalation3`}
                    className="form-label"
                  >
                    Select Employee
                  </label>
                  <Select
                    id={`escalation3`}
                    name={`escalation3`}
                    value={empOptions.find(
                      (option) => Number(option.value) === step.escalation3
                    )}
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "escalation3",
                        selectedOption.value,
                        activeTab
                      )
                    }
                    options={empOptions}
                    placeholder="Select employee"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159,0.4)",
                        borderRadius: "10px",
                        marginTop: "8px",
                        color: "black",
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Montserrat",
                        outline: "none",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Header />
      <div className="py-4 overflow-y-auto bg-white my-2 flex-grow-1 position-relative container-fluid h-100">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Edit Workflow
          </h2>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "80px" }}
          >
            <label className="switch" onClick={handleClickActive}>
              <input
                type="checkbox"
                checked={isActive}
                onChange={handleToggleSwitch}
              />
              <span
                className="slider round"
                data-content={sliderContent}
                style={{
                  color: isActive ? "#AAAEB0" : "#AAAEB0",
                  fontWeight: "500",
                  paddingLeft: isActive ? "80px" : "10px",
                  paddingTop: "2px",
                }}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
            </label>
            <img
              className="ms-2 toggleUser"
              src={imageSource}
              alt={isActive ? "Inactive" : "Active"}
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </div>
        </div>

        {/* workflow details */}
        <div
          className="py-2 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
            marginLeft: "-12px",
          }}
        >
          Workflow Details
        </div>
        <div className="row justify-content-between mt-4 px-5">
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Name
            </p>
            <input
              type="text"
              name="workflow_name"
              className="p-2 w-100 mt-1"
              value={
                editableFields && editableFields.workflow_name
                  ? editableFields.workflow_name
                  : ""
              }
              onChange={(e) =>
                handleInputChange("workflow_name", e.target.value)
              }
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
            />
          </div>
          <div className="col-12">
            <label
              className="mb-0 mt-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              className="w-100 p-2 mt-2"
              style={{
                outline: "none",
                backgroundColor: "#FFF6D8",
                resize: "none",
                border: "none",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                fontSize: "14px",
              }}
              spellCheck={false}
              name="description"
              id="description"
              value={
                editableFields && editableFields.description
                  ? editableFields.description
                  : ""
              }
              onChange={(e) => handleInputChange("description", e.target.value)}
              rows="7"
            ></textarea>
          </div>
        </div>

        {/* rules  */}
        <div
          className="py-2 ps-3 mt-4"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
            marginLeft: "-12px",
          }}
        >
          Rules
        </div>

        {/* rules part 1  type, category, subcategory  */}
        <div className="m-4 px-5">
          <div className="row">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="type"
                className="form-label ms-2"
              >
                Type
              </label>
              <select
                name="type"
                value={
                  editableFields && editableFields.type
                    ? editableFields.type
                    : ""
                }
                onChange={(e) => handleInputChange("type", e.target.value)}
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                <option value="" disabled>
                  Select Type
                </option>
                {typeOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="category"
                className="form-label ms-2"
              >
                Category
              </label>

              <select
                name="category"
                value={
                  editableFields && editableFields.category
                    ? editableFields.category
                    : ""
                }
                onChange={(e) => handleInputChange("category", e.target.value)}
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                {categoryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="subcategory"
                className="form-label ms-2"
              >
                Sub Category
              </label>
              <select
                name="subcategory"
                value={
                  editableFields && editableFields.subcategory
                    ? editableFields.subcategory
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("subcategory", e.target.value)
                }
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                {subcategoryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-5">
            <div className="d-flex align-items-center">
              <p
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
              >
                Additional Field/'s
              </p>
              <img
                className="ms-3"
                style={{
                  height: "25px",
                  cursor: "pointer",
                  pointerEvents: "all",
                  marginTop: "-9px",
                }}
                src={stepAdd}
                alt="add-step"
                onClick={handleAddField}
              />
              {errorMessage && (
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginTop: "4px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontFamily: "Montserrat",
                    position: "absolute",
                  }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
            <div className="row d-flex align-items-center">
              {additionalFields.map((field, index) => (
                <div className="col-4 ms-3 position-relative" key={field.id}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                      fontFamily: "Montserrat",
                      marginTop: index >= 2 ? "18px" : "0",
                    }}
                    htmlFor={`additional_fields_${field.id}`}
                    className="form-label ms-2"
                  >
                    Field Type {index + 1}
                  </label>
                  <Select
                    id={`additional_fields_${field.id}`}
                    name={`additional_fields_${field.id}`}
                    placeholder="Select Field Type"
                    value={
                      field.value
                        ? { value: field.value, label: field.label }
                        : null
                    }
                    options={getAvailableOptions(index)}
                    onChange={(selectedOption) =>
                      handleFieldChange(selectedOption, index)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        padding: "2px 6px",
                        borderRadius: "10px",
                        border: "none",
                        boxShadow: "none",
                        "&:focus": {
                          outline: "none",
                        },
                      }),
                    }}
                  />
                  {
                    <MdDelete
                      onClick={() => handleDeleteField(index)}
                      style={{
                        position: "absolute",
                        top: index > 1 ? "68%" : "58%",
                        right: "-12px",
                        cursor: "pointer",
                        color: "grey",
                        fontSize: "20px",
                      }}
                      title="Delete Field"
                    />
                  }
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* ================================ || SUBJECT TICKET || =========================== */}
        <div className="mt-4 pt-3 container-sm mx-auto px-5">
          <div className="d-flex align-items-center">
            <p
              style={{
                marginTop: "4px",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
            >
              Template for Ticket’s Subject & Description:
            </p>
          </div>

          <div className="mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Subject Ticket"}
                id={"subject"}
                name="subject"
                value={editableFields.subject || ""}
                onChange={(event) =>
                  setEditableFields({
                    ...editableFields,
                    subject: event.target.value,
                  })
                }
              />
            </div>
            <label
              className="mb-0 mt-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="description"
            >
              Description
            </label>

            <TextEditor
              initialContent={template}
              placeholder="Write Your Description Here..."
              maxCharacters={5000}
              isFullScreen={false}
              onContentChange={(val) => setTemplate(val)}
            />
          </div>
        </div>

        {/* worflow tabs  */}
        <div className="pt-4">
          <h2
            className="mx-4 px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontSize: "16px",
            }}
          >
            Workflow Steps:
          </h2>
          <div className="mx-4 mt-3 px-4">
            {tabs.map((tab, index) => (
              <button
                style={{
                  border: "1.2px solid rgba(105, 102, 102, 1)",
                  borderTop: "1.2px solid rgba(105, 102, 102, 1)",
                  borderLeft: "1.2px solid rgba(105, 102, 102, 1)",
                  borderRight: "1.2px solid rgba(105, 102, 102, 1)",
                  width: "120px",
                  borderBottom:
                    index === activeTab
                      ? "none"
                      : "1.2px solid rgba(105, 102, 102, 1)",
                  borderRadius: index === activeTab ? "5px 5px 0 0" : "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(105, 102, 102, 1)",
                  padding: index === activeTab ? "10px" : "6px 10px",
                  backgroundColor: "#f5f8fd",
                }}
                key={index}
                className="me-2 position-relative"
                onClick={() => handleTabClick(index)}
              >
                {tab.title}

                {index === activeTab && (
                  <div className="workflow_selected_tab" />
                )}
              </button>
            ))}
          </div>
        </div>

        {/* content  */}
        <div>{renderContent()}</div>

        {/* buttons  */}
        <div className="d-flex justify-content-between align-itmes-center mt-5 pt-4 px-5">
          <button
            className="py-1 px-4 bg-white"
            style={{
              border: "1px solid rgba(22, 64, 127, 1)",
              color: "white",
              borderRadius: "10px",
              color: "rgba(22, 64, 127, 1)",
              fontSize: "18px",
            }}
            onClick={changePageFunc}
          >
            Back
          </button>
          <button
            className="py-1 px-4"
            style={{
              backgroundColor: "rgba(22, 64, 127, 1)",
              color: "white",
              borderRadius: "10px",
              fontSize: "18px",
              opacity: isButtonDisabled || isLoading ? 0.5 : 1,
              cursor: isButtonDisabled || isLoading ? "not-allowed" : "pointer",
              border: "1px solid rgba(22, 64, 127, 1)",
            }}
            onClick={handleClickUpdate}
            disabled={isButtonDisabled || isLoading}
          >
            {isLoading ? "Updating..." : "Update"}
          </button>

          <Alert
            show={showAlertUpdate}
            onHide={hideAlert}
            title="Confirmation"
            actionText="update"
            message={alertTextUpdate}
            walletColor={walletColor}
            onConfirm={handleUpdate}
            forTerm=" "
            term="Workflow"
          />
          <Alert
            show={showAlertActive}
            onHide={hideAlertActive}
            title="Confirmation"
            actionText={isActive ? "deactivate" : "activate"}
            message={alertTextActive}
            term="workflow"
            forTerm=" "
            walletColor={isActive ? "red" : "green"}
            onConfirm={() =>
              handleConfirmActive(isActive ? "Inactive" : "Active")
            }
          />
        </div>
      </div>
    </>
  );
};

export default WorkflowInfo;
