import React, { useEffect, useState } from "react";
import { busHistory } from "../../../../urls";
import API from "../../../../components/api2";
import { Modal, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CancelTicket from "./CancelTicket";

const BookedTickets = ({ setShowBookedTicket }) => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("upcoming_trips");
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [cancelTicket, setCancelTicket] = useState(false);
  const [refId, setRefId] = useState("");

  const handleViewBooking = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const fetchTickets = (tripType, newOffset = 0, isLoadMore = false) => {
    setLoading(true);
    setError(false);

    API({
      ...busHistory,
      params: { trip: tripType, offset: newOffset, limit: 10 },
      onSuccess: (res) => {
        setLoading(false);
        const newData = res?.data?.data?.table_data || [];

        if (newData.length > 0) {
          setFilteredTable(
            isLoadMore ? [...filteredData, ...newData] : newData
          );
          setOffset(newOffset); // Update offset for next load
        }

        // Check if more data is available
        setHasMore(newData.length === 10);
      },
      onError: (error) => {
        setLoading(false);
        setFilteredTable([]);
        setError(true);
      },
    });
  };
  useEffect(() => {
    fetchTickets(selectedTab, 0);
  }, [selectedTab]);

  const handleLoadMore = () => {
    fetchTickets(selectedTab, offset + 10, true);
  };

  const filteredData = filteredTable.filter((row) =>
    Object.values({
      refid: row.trip_id,
      passenger_name: row.passenger_name,
      passenger_mobile: row.passenger_mobile,
      bus_name: row.bus_name,
      source_name: row.source_name,
      destination_name: row.destination_name,
    })
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handleDownloadTicket = () => {
    if (!selectedBooking) return;

    const doc = new jsPDF();
    doc.setFont("League Spartan", "bold");
    doc.setFontSize(16);
    doc.text("Ticket Confirmation", 20, 20);

    doc.setFont("League Spartan", "normal");
    doc.setFontSize(12);
    doc.text(
      `Passenger Name: ${selectedBooking.title} ${selectedBooking.passenger_name}`,
      20,
      40
    );
    doc.text(`Mobile: ${selectedBooking.passenger_mobile || "N/A"}`, 20, 50);
    doc.text(`Email: ${selectedBooking.email || "N/A"}`, 20, 60);
    doc.text(`Age: ${selectedBooking.passenger_age}`, 20, 70);
    doc.text(`Gender: ${selectedBooking.passenger_gender}`, 20, 80);
    doc.text(`Address: ${selectedBooking.address}`, 20, 90);
    doc.text(
      `Bus Name: ${selectedBooking.bus_name || "Test Travels"}`,
      20,
      100
    );
    doc.text(`Seat Number: ${selectedBooking.seat_number}`, 20, 110);
    doc.text(`Amount Paid: ₹${selectedBooking.amount}`, 20, 120);
    doc.text(
      `Departure: ${selectedBooking.source_name} (${selectedBooking.booking_date} ${selectedBooking.booking_time})`,
      20,
      130
    );
    doc.text(`Destination: ${selectedBooking.destination_name}`, 20, 140);
    doc.text(`Date of Journey: ${selectedBooking.doj}`, 20, 150);
    doc.text(`Trip ID: ${selectedBooking.trip_id}`, 20, 160);
    doc.text(`Status: ${selectedBooking.status}`, 20, 170);

    doc.save(`Ticket_${selectedBooking.trip_id}.pdf`);
  };

  return (
    <>
      {cancelTicket ? (
        <CancelTicket
          setCancelTicket={setCancelTicket}
          setShowBookedTicket={setShowBookedTicket}
          refId={refId}
          bookingDetails={selectedBooking}
          setShowModal={setShowModal}
        />
      ) : (
        <div
          style={{ borderRadius: "10px", height: "calc(100% - 200px)" }}
          className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
        >
          <div className="pt-4" style={{ backgroundColor: "#194280" }}>
            <div className="d-flex align-items-center justify-content-between px-3">
              <div className="ms-4">
                <button
                  onClick={() => setShowBookedTicket(false)}
                  style={{
                    backgroundColor: "#194280",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                >
                  Book New Ticket
                </button>
              </div>
              {/* Search Bar */}
              <div className="d-flex align-items-center px-3">
                <input
                  type="text"
                  placeholder="Search for booking"
                  className="px-3 py-2"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ borderRadius: "5px" }}
                />
              </div>
            </div>
            {/* Tabs for filtering */}
            <div
              style={{ borderRadius: "10px 10px 0px 0px" }}
              className="d-flex bg-white justify-content-center gap-4 p-3 mx-4 mt-3"
            >
              {[
                { label: "UPCOMING", value: "upcoming_trips" },
                { label: "COMPLETED", value: "past_trips" },
                { label: "CANCELLED", value: "cancelled_trips" },
              ].map((tab) => (
                <button
                  key={tab.value}
                  onClick={() => setSelectedTab(tab.value)}
                  style={{
                    backgroundColor: "transparent",
                    color: selectedTab === tab.value ? "black" : "#4F575B",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    border: "none",
                    padding: "10px 15px",
                    borderBottom:
                      selectedTab === tab.value ? "2px solid #194280" : "none",
                    cursor: "pointer",
                    fontWeight: selectedTab === tab.value ? "bold" : "normal",
                  }}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          {/* displaying tickets */}
          <div
            style={{ height: "calc(100% - 150px)" }}
            className="px-3 mx-4 overflow-auto"
          >
            {loading ? (
              <div className="text-center py-3">Loading...</div>
            ) : error || filteredData.length === 0 ? (
              <div className="text-center py-3">No trips found</div>
            ) : (
              <div className="row">
                {filteredData.map((row) => (
                  <div key={row.trip_id} className="col-12 mb-3">
                    <div className="card shadow-sm p-3">
                      {/* Header Section */}
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 style={{ color: "#194280", marginBottom: 0 }}>
                          {row?.bus_name ? row.bus_name : "Test Travels"}
                        </h5>
                        <p className="mb-0">
                          <strong>Amount Paid: ₹{row.amount}</strong>
                          <p className="mb-1">
                            <strong>Mobile:</strong>{" "}
                            {row.passenger_mobile || "N/A"}
                          </p>
                        </p>
                      </div>

                      {/* Journey Details Section */}
                      <div className="d-flex align-items-center justify-content-between my-2">
                        <p className="mb-0">
                          <strong>
                            Departure: {row.source_name} ({row.booking_date}{" "}
                            {row.booking_time})
                          </strong>
                        </p>
                        <div
                          style={{
                            flexGrow: 1,
                            borderTop: "2px dotted black",
                            margin: "0 15px",
                          }}
                        ></div>
                        <p className="mb-0">
                          <strong>Destination: {row.destination_name}</strong>
                        </p>
                      </div>

                      {/* Date of Journey */}
                      <p className="mb-2 text-muted">
                        Date of Journey: {row.doj}
                      </p>

                      {/* Status & Button Section */}
                      <div className="d-flex justify-content-between align-items-center">
                        <span
                          className={`badge ${
                            row.status === "Confirm"
                              ? "bg-success"
                              : "bg-danger"
                          } p-2`}
                          style={{ letterSpacing: "1px" }}
                        >
                          STATUS: {row.status.toUpperCase()}
                        </span>
                        <button
                          className="p-2 text-white border-0"
                          style={{
                            backgroundColor: "#194280",
                            borderRadius: "5px",
                            letterSpacing: "1px",
                            fontSize: "14px",
                          }}
                          onClick={() => handleViewBooking(row)}
                        >
                          View Booking
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "#194280" }}>
                  Booking Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedBooking && (
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <p>
                          <strong>Passenger Name:</strong>{" "}
                          {selectedBooking.title}{" "}
                          {selectedBooking.passenger_name}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Mobile:</strong>{" "}
                          {selectedBooking.passenger_mobile || "N/A"}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Email:</strong>{" "}
                          {selectedBooking.email || "N/A"}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Age:</strong> {selectedBooking.passenger_age}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Gender:</strong>{" "}
                          {selectedBooking.passenger_gender}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Address:</strong> {selectedBooking.address}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Bus Name:</strong>{" "}
                          {selectedBooking.bus_name || "Test Travels"}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Seat Number:</strong>{" "}
                          {selectedBooking.seat_number}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Amount Paid:</strong> ₹
                          {selectedBooking.amount}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Departure:</strong>{" "}
                          {selectedBooking.source_name} (
                          {selectedBooking.booking_date}{" "}
                          {selectedBooking.booking_time})
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Destination:</strong>{" "}
                          {selectedBooking.destination_name}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Date of Journey:</strong>{" "}
                          {selectedBooking.doj}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Trip ID:</strong> {selectedBooking.trip_id}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          <strong>Status:</strong>{" "}
                          <span
                            className={`badge ${
                              selectedBooking.status === "Confirm"
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                          >
                            {selectedBooking.status}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{ backgroundColor: "#194280" }}
                  onClick={handleDownloadTicket}
                >
                  Download
                </Button>
                {selectedBooking?.status !== "Cancelled" && (
                  <Button
                    variant="danger"
                    onClick={() => {
                      setCancelTicket(true);
                      setRefId(selectedBooking.refid);
                    }}
                  >
                    Cancel Ticket
                  </Button>
                )}
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          {hasMore && filteredData.length > 0 && (
            <div className="text-center">
              <button
                className="border-0 px-2 py-1 text-white"
                onClick={handleLoadMore}
                style={{
                  backgroundColor: "#194280",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                disabled={loading}
              >
                {loading ? "Loading..." : "Load More"}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BookedTickets;
