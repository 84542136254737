import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFees,
  brandDropdown,
  getMerchantData,
  getComments,
  addComments,
  activeMerchant,
  freezeMerchant,
  getMerchWallet,
  newMerchWallet,
  merchantDropdown,
  updateMerchantData,
  getProfile,
} from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import {
  circles,
  pdfViewIcon,
  lock,
  unlock,
  active,
  inactive,
  noImage2,
  merchantWalletSuccess,
} from "../images";
import Button from "../components/Button";
import { changePage } from "../redux/slice/dashboardSlice";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Alert from "../components/Alert";
import { Modal } from "react-bootstrap";
import tickMark from "../gif/tickMark.gif";
import ImageViewer from "../components/ImageViewer";

const MerchantInfo = ({ roles }) => {
  const location = useLocation();
  const { service, merchantApp } = location.state || {};
  const merchantId = service?.merchant_id || merchantApp?.merchant_id;

  const [fee, setFee] = useState("");
  const [brandOption, setBrandOption] = useState([]);
  const [merchantAppData, setMerchantAppData] = useState({});
  const [comments, setComments] = useState([]);
  const [showAlertActive, setShowAlertActive] = useState(false);
  const [showAlertFreeze, setShowAlertFreeze] = useState(false);
  const [showAlertWallet, setShowAlertWallet] = useState(false);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextActive, setAlertTextActive] = useState("");
  const [alertTextFreeze, setAlertTextFreeze] = useState("");
  const [alertTextWallet, setAlertTextWallet] = useState("");
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [walletColor, setWalletColor] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [walletData, setWalletData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [rdMerchDrop, setRdMerchDrop] = useState([]);
  const [adMerchDrop, setAdMerchDrop] = useState([]);
  const [ldMerchDrop, setLdMerchDrop] = useState([]);
  const [employeeType, setEmployeeType] = useState([]);
  const [brandId, setBrandId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasWallet, setHasWallet] = useState(false);

  const [editableFields, setEditableFields] = useState({
    regional_distributor_id: "",
    area_distributor_id: "",
    local_distributor_id: "",
    merchant_type: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    pan_number: "",
    gst_number: "",
    aadhar_number: "",
    contact_no: "",
    contact_number: "",
    residential_mail_adres: "",
    residential_mailing_city: "",
    residential_mailing_state: "",
    residential_mailing_pin: "",
    kyc_contact_person: "",
    kyc_contact_email: "",
    kyc_contact_no: "",
    kyc_mailing_address: "",
    kyc_mailing_city: "",
    kyc_mailing_state: "",
    kyc_mailing_pin: "",
    business_mailing_address: "",
    business_mailing_city: "",
    business_mailing_state: "",
    business_mailing_pin: "",
    allow_to_create_business_scheme: "NO",
    allow_making_commission_structure: "",
    allow_working_as_agent: "",
    created_on: "",
    created_by: "",
    updated_on: "",
    updated_by: "",
    additional_info: "",
    active_status: "",
    status: "",
    business_name_field: "",
    legal_name_field: "",
    age: "",
    education: "",
    photo: "",
    pan_doc: "",
    gst_doc: "",
    adhar_doc: "",
    shop_photo: "",
    address_proof: "",
    gender: "",
    contact_person: "",
    contact_email: "",
    business_area: "",
    shop_area: "",
    finger_print_device: "",
    micro_atm_device: "",
    other_devices: "",
    brand_id: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // alert
  const hideAlert = () => {
    setShowAlertActive(false);
    setShowAlertFreeze(false);
    setShowAlertWallet(false);
    setShowAlertUpdate(false);
  };
  const showAlertWithTextActive = (text, walletColor) => {
    setAlertTextActive(text);
    setShowAlertActive(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextFreeze = (text, walletColor) => {
    setAlertTextFreeze(text);
    setShowAlertFreeze(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextWallet = (text, walletColor) => {
    setAlertTextWallet(text);
    setShowAlertWallet(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickActive = () => {
    showAlertWithTextActive("Are you sure you want to", "green");
  };
  const handleClickFreeze = () => {
    showAlertWithTextFreeze("Are you sure you want to", "blue");
  };
  const handleClickWallet = () => {
    showAlertWithTextWallet("Are you sure you want to", "green");
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  useEffect(() => {
    fetchFeeDetails();
    getSelectedMerchant();
    fetchComments();
    fetchWalletData();
  }, []);

  // fetch merchant wallet
  const fetchWalletData = () => {
    API({
      ...getMerchWallet,
      params: {
        merchant_id: merchantId,
      },
      onSuccess: (res) => {
        if (merchantId && res.data?.data?.wallet_id) {
          setWalletData(res.data.data.wallet_id);
          setHasWallet(true);
        } else {
          setHasWallet(false);
        }
      },
      onError: (error) => {
        console.error("Error fetching wallet: ", error);
        setHasWallet(false);
      },
    });
  };

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allMerchants",
      })
    );

    navigate("/");
  };
  const changePageFuncAllot = () => {
    dispatch(
      changePage({
        page: "allotMerchPackage",
        merchant_id: merchantAppData.merchant_id,
      })
    );

    navigate("/", { state: { merchantApp: merchantAppData } });
  };

  // brand options
  useEffect(() => {
    const fetchBusinessBrand = () => {
      API({
        ...brandDropdown,
        onSuccess: (res) => {
          const brand = res.data.data.map((b) => ({
            value: b.brand_id,
            label: `${b.brand_id}-${b.business_name}`,
          }));
          setBrandOption(brand);
        },

        onError: (error) => {
          console.log("error");
        },
      });
    };

    fetchBusinessBrand();
  }, []);

  // fee
  const fetchFeeDetails = () => {
    API({
      ...getFees,
      params: {
        application_no: merchantId,
        application_type: "MERCHANT",
      },
      onSuccess: (res) => {
        setFee(res.data.data[0]);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  };

  // get merchant based on merchant id
  const getSelectedMerchant = () => {
    if (merchantId) {
      API({
        ...getMerchantData,
        params: {
          merchant_id: merchantId,
        },
        onSuccess: (res) => {
          setMerchantAppData(res.data.data);
          setEditableFields(res.data.data);
        },
        onError: (error) => {
          console.log("Error fetching merchant data", error);
        },
      });
    } else {
      console.log("Merchant ID is not available");
    }
  };

  const handleInputChange = (fieldName, value) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  // comments
  const fetchComments = () => {
    API({
      ...getComments,
      params: {
        application_no: merchantId,
        user_type: "MERCHANT",
      },
      onSuccess: (res) => {
        setComments(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching comments: ", error);
      },
    });
  };
  const handleAddComment = () => {
    const comment = document.getElementById("comments").value;
    if (!comment.trim()) {
      Swal.fire({
        icon: "error",
        title: `comment cannot be empty`,
      });
      return;
    }
    API({
      ...addComments,
      body: {
        application_no: merchantAppData.merchant_id,
        user_type: "MERCHANT",
        comments: comment,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        console.error("Error adding comment: ", error);
      },
    });
  };

  // format date and time
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  const { active_status, wallet_status } = merchantAppData;
  const initialIsActive = active_status !== "A";

  const [isActive, setIsActive] = useState(initialIsActive);
  useEffect(() => {
    setIsActive(active_status !== "A");
  }, [active_status]);

  // active/inactive
  const handleConfirmActive = (actionText) => {
    const isActive = actionText === "Active" ? "1" : "0";
    API({
      ...activeMerchant,
      body: {
        merchant_id: merchantAppData.merchant_id,
        active_status: isActive,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertActive(false);
  };
  const sliderContent = isActive ? "Inactive" : "Active";
  const imageSource = isActive ? inactive : active;

  const handleToggleSwitch = () => {
    setIsActive((prev) => {
      return !prev;
    });
  };

  // freeze wallet
  const toggleLock = () => {
    setIsLocked(!isLocked);
  };

  const handleConfirmFreeze = (actionText) => {
    const walletConfirm = actionText === "Freeze Wallet" ? 0 : 1;
    API({
      ...freezeMerchant,
      body: { wallet_id: walletData, wallet_status: walletConfirm },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertFreeze(false);
  };

  // create wallet
  const handleConfirmWallet = () => {
    setLoading(true);
    API({
      ...newMerchWallet,
      body: {
        merchant_id: merchantAppData.merchant_id,
        brand_id: merchantAppData.brand_id,
      },
      onSuccess: (res) => {
        setLoading(false);
        setShowModal(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertWallet(false);
  };

  // merchant dropdown
  useEffect(() => {
    if (merchantAppData.brand_id) {
      API({
        ...merchantDropdown,
        params: {
          brand_id: merchantAppData.brand_id,
        },
        onSuccess: (res) => {
          const rdMerch = res.data.data.RD.map((merchant) => ({
            value: merchant.merchant_id,
            label: `${merchant.merchant_id}-${merchant.merchant_name}`,
          }));
          setRdMerchDrop(rdMerch);

          const adMerch = res.data.data.AD.map((merchant) => ({
            value: merchant.merchant_id,
            label: `${merchant.merchant_id}-${merchant.merchant_name}`,
          }));
          setAdMerchDrop(adMerch);

          const ldMerch = res.data.data.LD.map((merchant) => ({
            value: merchant.merchant_id,
            label: `${merchant.merchant_id}-${merchant.merchant_name}`,
          }));
          setLdMerchDrop(ldMerch);
        },
        onError: (error) => {
          console.error("Error fetching merchant dropdown: ", error);
        },
      });
    }
  }, [merchantAppData]);

  // update
  const handleConfirmUpdate = () => {
    setLoading(true);
    const { created_by, created_on, updated_by, updated_on, ...updateData } =
      editableFields;
    API({
      ...updateMerchantData,
      body: updateData,
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  const hasRole = (role) => roles.includes(role);

  useEffect(() => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setEmployeeType(res.data.data.employee_type);
        setBrandId(res.data.data.brand_id);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, []);

  // filter brand options
  const filteredBrandOptions =
    employeeType === "BRAND"
      ? brandOption.filter((option) => option.value === brandId)
      : brandOption;

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 px-3"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Merchant Information: {merchantId}
          </h2>
          <div
            style={{ marginRight: "60px" }}
            className="d-flex align-items-center"
          >
            {hasRole("MERCHANT_FREEZE") && hasWallet && (
              <div
                className="p-2"
                style={{
                  backgroundColor: "rgba(25, 66, 128, 1)",
                  borderRadius: "10px",
                  width: "160px",
                  cursor: "pointer",
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={toggleLock}
                    src={isLocked ? lock : unlock}
                    alt={isLocked ? "freeze-wallet" : "unfreeze-wallet"}
                  />
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: isLocked ? "rgba(195, 208, 224, 0.6)" : "white",
                    }}
                    className="mb-0 ms-2"
                    onClick={handleClickFreeze}
                  >
                    {isLocked ? "Freeze Wallet" : "Unfreeze Wallet"}
                  </p>
                </div>
              </div>
            )}

            {hasRole("MERCHANT_ACTIVE") && (
              <div
                onClick={handleClickActive}
                className="d-flex align-items-center ms-4"
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={handleToggleSwitch}
                  />
                  <span
                    className="slider round"
                    data-content={sliderContent}
                    style={{
                      color: isActive ? "#AAAEB0" : "#AAAEB0",
                      fontWeight: "500",
                      paddingLeft: isActive ? "80px" : "10px",
                      paddingTop: "2px",
                    }}
                  >
                    {isActive ? "Active" : "Inactive"}
                  </span>
                </label>
                <img
                  className="ms-2 toggleUser"
                  src={imageSource}
                  alt={isActive ? "Inactive" : "Active"}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    pointerEvents: "all",
                  }}
                />
              </div>
            )}
          </div>
          <Alert
            show={showAlertActive}
            onHide={hideAlert}
            title="Confirmation"
            actionText={isActive ? "deactivate" : "activate"}
            message={alertTextActive}
            term="Merchant"
            forTerm=""
            walletColor={isActive ? "red" : "green"}
            onConfirm={() =>
              handleConfirmActive(isActive ? "Inactive" : "Active")
            }
          />
          <Alert
            show={showAlertFreeze}
            onHide={hideAlert}
            title="Confirmation"
            actionText={isLocked ? "freeze" : "unfreeze"}
            message={alertTextFreeze}
            walletColor={walletColor}
            term="Merchant"
            onConfirm={() =>
              handleConfirmFreeze(
                isLocked ? "Freeze Wallet" : "Unfreeze Wallet"
              )
            }
          />
        </div>

        {/* contact details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-3 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Contact Details
          </h3>
          <div className="row align-items-center mt-4 px-5">
            <div className="col-8">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Mobile No
                </p>
                <input
                  type="text"
                  name="contact_number"
                  className="p-2 w-75 mt-1"
                  value={editableFields.contact_number}
                  onChange={(e) =>
                    handleInputChange("contact_number", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={10}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
                <p
                  className="mb-0 mt-4"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Email id
                </p>
                <input
                  type="text"
                  name="contact_email"
                  className="p-2 w-75 mt-1"
                  value={editableFields.contact_email}
                  onChange={(e) =>
                    handleInputChange("contact_email", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
            <div className="col-4">
              <div
                className="text-center"
                style={{
                  border: "1.5px solid rgba(217, 217, 217, 1)",
                  borderRadius: "22px",
                }}
              >
                <img
                  className="w-100"
                  style={{ height: "150px" }}
                  src={merchantAppData.photo}
                  alt="demo"
                />
              </div>
            </div>
          </div>
        </div>

        {/* kyc  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            KYC Details
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="row">
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    PAN
                  </p>

                  <input
                    type="text"
                    name="pan_number"
                    className="p-2 w-75 mt-1"
                    value={editableFields.pan_number}
                    onChange={(e) =>
                      handleInputChange("pan_number", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    maxLength={10}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    GSTIN
                  </p>
                  <input
                    type="text"
                    name="gst_number"
                    className="p-2 w-75 mt-1"
                    value={editableFields.gst_number}
                    onChange={(e) =>
                      handleInputChange("gst_number", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    maxLength={15}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Aadhar
                  </p>
                  <input
                    type="text"
                    name="aadhar_number"
                    className="p-2 w-75 mt-1"
                    value={editableFields.aadhar_number}
                    onChange={(e) =>
                      handleInputChange("aadhar_number", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    maxLength={12}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* personal details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Personal Details
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="row">
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    First Name
                  </p>
                  <input
                    type="text"
                    name="first_name"
                    className="p-2 w-75 mt-1"
                    value={editableFields.first_name}
                    onChange={(e) =>
                      handleInputChange("first_name", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Last Name
                  </p>
                  <input
                    type="text"
                    name="last_name"
                    className="p-2 w-75 mt-1"
                    value={editableFields.last_name}
                    onChange={(e) =>
                      handleInputChange("last_name", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Date of Birth
                  </p>
                  <input
                    type="date"
                    name="age"
                    className="p-2 w-75 mt-1"
                    value={editableFields.age}
                    onChange={(e) => handleInputChange("age", e.target.value)}
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Middle Name
                  </p>
                  <input
                    type="text"
                    name="middle_name"
                    className="p-2 w-75 mt-1"
                    value={editableFields.middle_name}
                    onChange={(e) =>
                      handleInputChange("middle_name", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Gender
                  </p>
                  <select
                    name="gender"
                    className="p-2 w-75 mt-1"
                    value={editableFields.gender}
                    onChange={(e) =>
                      handleInputChange("gender", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                      border: "0",
                    }}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  >
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                    <option value="TRANSGENDER">TRANSGENDER</option>
                  </select>
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Education
                  </p>

                  <select
                    name="education"
                    className="p-2 w-75 mt-1"
                    value={editableFields.education}
                    onChange={(e) =>
                      handleInputChange("education", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                      border: "0",
                    }}
                    disabled={!hasRole("MERCHANT_EDIT")}
                  >
                    <option value="10th">10th</option>
                    <option value="12th">12th</option>
                    <option value="graduate">Graduate</option>
                    <option value="post_graduate">Post-Graduate</option>
                    <option value="phd">PH.D</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mailing address  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Mailing Address
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>
                <input
                  type="text"
                  name="residential_mail_adres"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mail_adres}
                  onChange={(e) =>
                    handleInputChange("residential_mail_adres", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  City
                </p>
                <input
                  type="text"
                  name="residential_mailing_city"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mailing_city}
                  onChange={(e) =>
                    handleInputChange(
                      "residential_mailing_city",
                      e.target.value
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  State
                </p>
                <input
                  type="text"
                  name="residential_mailing_state"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mailing_state}
                  onChange={(e) =>
                    handleInputChange(
                      "residential_mailing_state",
                      e.target.value
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  PIN
                </p>
                <input
                  type="text"
                  name="residential_mailing_pin"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mailing_pin}
                  onChange={(e) =>
                    handleInputChange("residential_mailing_pin", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={6}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
          </div>
        </div>

        {/* permanent address  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Permanent Address
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>
                <input
                  type="text"
                  name="kyc_mailing_address"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_address}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_address", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  City
                </p>
                <input
                  type="text"
                  name="kyc_mailing_city"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_city}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_city", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  State
                </p>
                <input
                  type="text"
                  name="kyc_mailing_state"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_state}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_state", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  PIN
                </p>
                <input
                  type="text"
                  name="kyc_mailing_pin"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_pin}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_pin", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={6}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
          </div>
        </div>

        {/* business details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Business Details
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Business Name
                </p>
                <input
                  type="text"
                  name="business_name_field"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_name_field}
                  onChange={(e) =>
                    handleInputChange("business_name_field", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Shop Name
                </p>
                <input
                  type="text"
                  name="shop_area"
                  className="p-2 w-75 mt-1"
                  value={editableFields.shop_area}
                  onChange={(e) =>
                    handleInputChange("shop_area", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Legal Name
                </p>
                <input
                  type="text"
                  name="legal_name_field"
                  className="p-2 w-75 mt-1"
                  value={editableFields.legal_name_field}
                  onChange={(e) =>
                    handleInputChange("legal_name_field", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Business Area
                </p>
                <input
                  type="text"
                  name="business_area"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_area}
                  onChange={(e) =>
                    handleInputChange("business_area", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  GSTIN
                </p>
                <input
                  type="text"
                  name="gst_number"
                  className="p-2 w-75 mt-1"
                  value={editableFields.gst_number}
                  onChange={(e) =>
                    handleInputChange("gst_number", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={15}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
          </div>
        </div>

        {/* business communication  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Business Communication
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>
                <input
                  type="text"
                  name="business_mailing_address"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_address}
                  onChange={(e) =>
                    handleInputChange(
                      "business_mailing_address",
                      e.target.value
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  City
                </p>
                <input
                  type="text"
                  name="business_mailing_city"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_city}
                  onChange={(e) =>
                    handleInputChange("business_mailing_city", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  State
                </p>
                <input
                  type="text"
                  name="business_mailing_state"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_state}
                  onChange={(e) =>
                    handleInputChange("business_mailing_state", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  PIN
                </p>
                <input
                  type="text"
                  name="business_mailing_pin"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_pin}
                  onChange={(e) =>
                    handleInputChange("business_mailing_pin", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={6}
                  disabled={!hasRole("MERCHANT_EDIT")}
                />
              </div>
              {/* <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Contact No
                </p>
                <input
                  type="number"
                  name="contact_number"
                  className="p-2 w-75 mt-1"
                  value={editableFields.contact_number}
                  onChange={(e) =>
                    handleInputChange("contact_number", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>

        {/* fee details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Payment Detail
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Payment Date
              </p>
              <p
                className="mb-0 mt-1"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                }}
              >
                {fee?.tran_date}
              </p>
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Payment Amount
              </p>
              <p
                className="mb-0 mt-1"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                }}
              >
                {fee?.transaction_amount}
              </p>
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Payment Mode
              </p>
              <p
                className="mb-0 mt-1"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                }}
              >
                {fee?.payment_mode}
              </p>
            </div>
          </div>
        </div>

        {/* merchant business info  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-3 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Merchant Business Info
          </h3>
          <div className="row mt-4 px-5">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Business Brand
              </p>
              <select
                name="brand_id"
                value={editableFields.brand_id}
                onChange={(e) => handleInputChange("brand_id", e.target.value)}
                className="p-2 w-100 mt-2"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              >
                <option value="">Select a brand</option>{" "}
                {filteredBrandOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Merchant Type
              </p>
              <select
                name="merchant_type"
                value={editableFields.merchant_type}
                onChange={(e) =>
                  handleInputChange("merchant_type", e.target.value)
                }
                className="p-2 w-100 mt-2"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              >
                {" "}
                <option value="">Select Type</option>
                <option value="AD">Master Distributor</option>
                <option value="RD">Super Distributor</option>
                <option value="LD">Distributor</option>
                <option value="AG">Retailer</option>
              </select>
            </div>
          </div>
          <div className="row mt-3 px-5">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Super Distributor
              </p>
              <select
                name="regional_distributor_id"
                value={editableFields.regional_distributor_id}
                onChange={(e) =>
                  handleInputChange("regional_distributor_id", e.target.value)
                }
                className="p-2 w-100 mt-2"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              >
                <option value="">Select Super Distributor</option>
                {rdMerchDrop.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  paddingBottom: "2px",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Finger Print Device
              </p>
              <input
                type="text"
                name="finger_print_device"
                className="p-2 w-100 mt-1"
                value={editableFields.finger_print_device}
                onChange={(e) =>
                  handleInputChange("finger_print_device", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              />
            </div>
          </div>
          <div className="row mt-3 px-5">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Master Distributor
              </p>
              <select
                name="area_distributor_id"
                value={editableFields.area_distributor_id}
                onChange={(e) =>
                  handleInputChange("area_distributor_id", e.target.value)
                }
                className="p-2 w-100 mt-2"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              >
                <option value="">Select Master Distributor</option>
                {adMerchDrop.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  paddingBottom: "2px",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Micro ATM Device
              </p>

              <input
                type="text"
                name="micro_atm_device"
                className="p-2 w-100 mt-1"
                value={editableFields.micro_atm_device}
                onChange={(e) =>
                  handleInputChange("micro_atm_device", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              />
            </div>
          </div>
          <div className="row mt-3 px-5">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Distributor
              </p>
              <select
                name="local_distributor_id"
                value={editableFields.local_distributor_id}
                onChange={(e) =>
                  handleInputChange("local_distributor_id", e.target.value)
                }
                className="p-2 w-100 mt-2"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              >
                <option value="">Select Distributor</option>
                {ldMerchDrop.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  paddingBottom: "2px",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Other Device
              </p>

              <input
                type="text"
                name="other_devices"
                className="p-2 w-100 mt-1"
                value={editableFields.other_devices}
                onChange={(e) =>
                  handleInputChange("other_devices", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                disabled={!hasRole("MERCHANT_EDIT")}
              />
            </div>
          </div>
          {/* checkboxes  */}
          {employeeType === "BRAND" ||
            (employeeType === "PLATFORM" && (
              <div className="px-5 my-2">
                <div className="row">
                  {/* <div className="col-6">
                    <div className="d-flex align-items-center p-0">
                      <input
                        type="checkbox"
                        id="allow_to_create_business_scheme"
                        name="allow_to_create_business_scheme"
                        checked={
                          editableFields.allow_to_create_business_scheme ===
                          "YES"
                        }
                        onChange={(e) =>
                          handleInputChange(
                            "allow_to_create_business_scheme",
                            e.target.checked ? "YES" : "NO"
                          )
                        }
                        style={{ backgroundColor: "white", marginTop: "32px" }}
                        disabled={!hasRole("MERCHANT_EDIT")}
                      />
                      <label
                        htmlFor="allow_to_create_business_scheme"
                        style={{ color: "#122158", marginTop: "35px" }}
                        className="mb-0"
                      >
                        Allow to create own Business Scheme
                      </label>
                    </div>
                  </div> */}
                  <div className="col-6">
                    <div className="d-flex align-items-center p-0">
                      <input
                        type={"checkbox"}
                        id={"allow_making_commission_structure"}
                        name="allow_making_commission_structure"
                        checked={
                          editableFields.allow_making_commission_structure ===
                          "YES"
                        }
                        style={{ backgroundColor: "white", marginTop: "32px" }}
                        onChange={(e) =>
                          handleInputChange(
                            "allow_making_commission_structure",
                            e.target.checked ? "YES" : "NO"
                          )
                        }
                        disabled={!hasRole("MERCHANT_EDIT")}
                      />
                      <label
                        htmlFor="allow_making_commission_structure"
                        style={{ color: "#122158", marginTop: "32px" }}
                        className="mb-0"
                      >
                        Allow to create own Commission Structure
                      </label>
                    </div>
                  </div>
                  {editableFields.merchant_type !== "AG" && (
                    <div className="col-6">
                      <div className="d-flex align-items-center p-0">
                        <input
                          type={"checkbox"}
                          id={"allow_working_as_agent"}
                          name="allow_working_as_agent"
                          checked={
                            editableFields.allow_working_as_agent === "YES"
                          }
                          style={{
                            backgroundColor: "white",
                            marginTop: "32px",
                          }}
                          onChange={(e) =>
                            handleInputChange(
                              "allow_working_as_agent",
                              e.target.checked ? "YES" : "NO"
                            )
                          }
                          disabled={!hasRole("MERCHANT_EDIT")}
                        />
                        <label
                          htmlFor="allow_working_as_agent"
                          style={{ color: "#122158", marginTop: "32px" }}
                          className="mb-0"
                        >
                          Allowed to work as Retailer
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>

        {/* Attachment */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Attachment
          </h3>
          <div className="row mx-4 mt-4">
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  GST Certificate
                </p>
                <ImageViewer
                  className="w-100 px-3 py-2"
                  src={
                    merchantAppData.gst_doc === ""
                      ? noImage2
                      : merchantAppData?.gst_doc
                  }
                  alt="gst_docs"
                  thumbnailHeight="105px"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  PAN Certificate
                </p>
                <ImageViewer
                  className="w-100 px-3 py-2"
                  src={merchantAppData?.pan_image}
                  alt="pan_docs"
                  thumbnailHeight="105px"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  Aadhar Certificate
                </p>
                <ImageViewer
                  className="w-100 px-3 py-2"
                  src={merchantAppData?.adhar_doc}
                  alt="aadhar_doc"
                  thumbnailHeight="105px"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  Shop Photo
                </p>
                <ImageViewer
                  className="w-100 px-3 py-2"
                  src={merchantAppData?.shop_photo}
                  alt="shop_photo_url"
                  thumbnailHeight="105px"
                />
              </div>
            </div>
          </div>
        </div>

        {/* comment  */}
        <div className="mt-4 pb-3">
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Comment
          </h3>
          <div
            className="mt-4 mx-5 px-5 py-3"
            style={{
              backgroundColor: "rgba(231, 232, 233, 1)",
              height: "260px",
              borderRadius: "14px",
              overflowY: "auto",
            }}
          >
            <div className="row d-flex justify-content-around">
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  User
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Comment
                </p>
              </div>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Commented on
                </p>
              </div>
            </div>
            {comments.map((comment) => (
              <div
                key={comment.comment_id}
                className="row d-flex justify-content-around"
              >
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.username}
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.comments}
                  </p>
                </div>
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {formatDate(comment.created_on)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="px-5">
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 mt-3 ms-3"
            >
              Comment
            </p>
            <div
              style={{
                backgroundColor: "#FFF6D8",
                borderRadius: "14px",
                border: "1.5px solid #D9D9D9",
              }}
            >
              <div className="d-flex align-items-center py-2">
                <textarea
                  className="w-100 px-3"
                  style={{
                    outline: "none",
                    backgroundColor: "#FFF6D8",
                    resize: "none",
                    border: "none",
                    borderRadius: "14px",
                  }}
                  name="comments"
                  placeholder="Write"
                  id="comments"
                  rows="4"
                ></textarea>
                <button
                  style={{
                    borderRadius: "24px",
                    backgroundColor: "#FFF6D8",
                    border: "1px solid black",
                  }}
                  className="mx-3 px-3 py-1"
                  onClick={handleAddComment}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* buttons  */}
        <div className="mt-5 d-flex align-items-center justify-content-between px-5">
          <Button onClick={changePageFunc} children={"Back"}></Button>
          <div>
            {hasRole("MERCHANT_EDIT") && (
              <button
                className="py-2 px-3 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Monteserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "15px",
                  opacity: loading ? "0.6" : "1",
                  width: "100px",
                }}
                onClick={handleClickUpdate}
                disabled={loading}
              >
                {loading ? "Loading..." : "Update"}
              </button>
            )}
            {hasRole("MERCHANT_WALLET") && (
              <button
                className="py-2 px-3 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Monteserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
                onClick={handleClickWallet}
                disabled={
                  wallet_status === "ACTIVE" ||
                  wallet_status === "FREEZE" ||
                  loading
                }
              >
                {wallet_status === "ACTIVE" || wallet_status === "FREEZE"
                  ? "Wallet Created"
                  : "Create Wallet"}
              </button>
            )}
            <Alert
              show={showAlertWallet}
              onHide={hideAlert}
              title="Confirmation"
              actionText="create"
              message={alertTextWallet}
              term="Merchant"
              walletColor={walletColor}
              onConfirm={handleConfirmWallet}
            />

            {hasRole("MP_ALLOT") && (
              <button
                className="py-2 px-3 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Monteserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
                onClick={changePageFuncAllot}
                disabled={wallet_status != "ACTIVE"}
              >
                Allot Package
              </button>
            )}
          </div>
          <Alert
            show={showAlertUpdate}
            onHide={hideAlert}
            title="Confirmation"
            actionText={"update"}
            message={alertTextUpdate}
            term="Merchant"
            forTerm=""
            walletColor={walletColor}
            onConfirm={handleConfirmUpdate}
          />
        </div>
        <Modal
          className="custom-modal"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <div className="position-relative">
            <img
              onClick={() => setShowModal(false)}
              style={{
                width: "300px",
                cursor: "pointer",
                pointerEvents: "all",
                margin: "150px 0 0 330px",
              }}
              src={merchantWalletSuccess}
              alt="merchant-wallet"
            />
            <img
              style={{ width: "65px", left: "56%", top: "51%" }}
              className="position-absolute"
              src={tickMark}
              alt="tick"
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: state.user.roles,
});

export default connect(mapStateToProps)(MerchantInfo);
