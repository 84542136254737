import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomerList from "../../Recharge/CustomerList";
import { serviceCircle } from "../../../../images";
import { IoIosSwap } from "react-icons/io";
import { busSourceCity, availableTrips, busTopDest } from "../../../../urls";
import API from "../../../../components/api2";
import Select from "react-select";
import { FaArrowRightLong } from "react-icons/fa6";
import SeasonalOffers from "./SeasonalOffers";
import tripRender from "../../../../gif/busSearch.gif";
import AvailableTrips from "./AvailableTrips";
import Swal from "sweetalert2";
import debounce from "lodash.debounce";
import { IoMdArrowRoundBack } from "react-icons/io";
import BookedTickets from "./BookedTickets";

const InputField = React.memo(
  ({
    label,
    id,
    type,
    onChange,
    placeholder,
    additionalInfo,
    minDate,
    value,
    options,
  }) => {
    const [inputValue, setInputValue] = useState(value ? value.label : "");
    const [locationOptions, setLocationOptions] = useState(options || []);
    const [isLoading, setIsLoading] = useState(false);
    const [initialData, setInitialData] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Fetch initial cities on component mount
    const fetchSourceCities = () => {
      setIsLoading(true);
      API({
        ...busTopDest,
        onSuccess: (res) => {
          const options = res?.data?.data?.matched_cities?.map((city) => ({
            value: city.id,
            label: city.name,
          }));
          setInitialData(options);
          setIsLoading(false);
        },
        onError: (error) => {
          console.error("Error fetching top destination cities:", error);
        },
      });
    };

    useEffect(() => {
      if (value && type === "date") {
        setInputValue(value);
      }
    }, [value, type]);

    useEffect(() => {
      if (type === "select" && (isInitialLoad || initialData?.length === 0)) {
        fetchSourceCities();
        setIsInitialLoad(false);
      }
    }, [type]);

    const debouncedInputChange = useCallback(
      debounce((inputValue) => {
        if (inputValue.trim() !== "") {
          setIsLoading(true);
          API({
            ...busSourceCity,
            params: { key: inputValue },
            onSuccess: (res) => {
              const options = res?.data?.data?.map((city) => ({
                value: city.id,
                label: city.name,
              }));
              setIsLoading(false);
              setLocationOptions(options);
            },
          });
        }
      }, 800),
      []
    );

    const handleInputChange = (newValue) => {
      setInputValue(newValue);

      if (!isInitialLoad) {
        debouncedInputChange(newValue);
      }
    };

    const dataOptions = useMemo(() => {
      if (inputValue) {
        return locationOptions;
      } else {
        return initialData;
      }
    }, [locationOptions, initialData]);

    const handleDateChange = (event) => {
      const selectedDate = event.target.value;
      setInputValue(selectedDate);
      onChange(selectedDate);
    };

    return (
      <div className="px-3 py-2" style={inputStyle}>
        <label
          htmlFor={id}
          className="form-label mb-0"
          style={{ color: "#696666" }}
        >
          {label}
        </label>
        {type === "select" ? (
          <Select
            id={id}
            className="w-100"
            value={value ? { value: value.id, label: value.name } : null}
            options={dataOptions}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            isLoading={isLoading}
            onChange={(selectedOption) => onChange(selectedOption)}
            placeholder={placeholder}
            styles={customSelectStyles}
          />
        ) : (
          <input
            type={type}
            id={id}
            className="w-100 pt-1 pb-2"
            value={inputValue}
            onChange={
              type === "date"
                ? handleDateChange
                : (e) => handleInputChange(e.target.value)
            }
            placeholder={placeholder}
            required
            min={type === "date" ? minDate : undefined}
          />
        )}
        {additionalInfo && (
          <p
            className="position-absolute"
            style={{ color: "#696666", fontSize: "14px", bottom: "2px" }}
          >
            {additionalInfo}
          </p>
        )}
      </div>
    );
  }
);

const inputStyle = {
  border: "1px solid #D9D9D9",
  borderRadius: "5px",
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const BusBookHome = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [isRotated, setIsRotated] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [trips, setTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSelectSeats, setShowSelectSeats] = useState(false);
  const [sourceId, setSourceId] = useState(null);
  const [destinationId, setDestinationId] = useState(null);
  const [departureDay, setDepartureDay] = useState(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split("T")[0];
  });
  const [showBookedTicket, setShowBookedTicket] = useState(false);

  const today = new Date().toISOString().split("T")[0];

  const handleSwapLocations = () => {
    setIsRotated((prev) => !prev);
    setFromLocation((prevFrom) => {
      setToLocation(prevFrom);
      return toLocation;
    });
  };

  const getDayName = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: "long" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  // Function to handle search buses
  const handleSearchBuses = () => {
    setIsLoading(true);
    const formData = {
      source_id: fromLocation.value,
      // source_id: 3,
      destination_id: toLocation.value,
      // destination_id: 6,
      date_of_journey: departureDay,
    };
    setSourceId(fromLocation.value);
    setDestinationId(toLocation.value);

    API({
      ...availableTrips,
      body: formData,
      onSuccess: (res) => {
        const trips = res?.data?.data?.availableTrips || [];
        setTrips(trips);
        setIsLoading(false);
        setIsSearchClicked(true);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setIsLoading(false);
      },
    });
  };

  const BusSearchSection = React.memo(() => (
    <div
      className="position-relative px-4"
      style={{ border: "1px solid #D9D9D9", borderRadius: "10px" }}
    >
      <img
        className="position-absolute end-0 top-0"
        src={serviceCircle}
        style={{ width: "100px" }}
        alt="circle"
      />
      <div className="row align-items-center justify-content-between my-3">
        {/* Locations Section */}
        <div className="col-6">
          <div className="row align-items-center position-relative">
            <button
              className="position-absolute bg-white swap-hover"
              style={{
                border: "1px solid #D9D9D9",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="button"
              onClick={handleSwapLocations}
            >
              <IoIosSwap
                style={{ transition: "transform 0.5s ease" }}
                className={`${isRotated ? "rotate-180" : ""}`}
              />
            </button>

            {/* From Location */}
            <div className="col-6">
              <InputField
                label="From"
                id="fromLocation"
                type="select"
                value={fromLocation}
                options={locationOptions}
                onChange={(selectedOption) => setFromLocation(selectedOption)}
                placeholder="Select departure"
              />
            </div>

            {/* To Location */}
            <div className="col-6">
              <InputField
                label="To"
                id="toLocation"
                type="select"
                value={toLocation}
                options={locationOptions}
                onChange={(selectedOption) => setToLocation(selectedOption)}
                placeholder="Select destination"
              />
            </div>
          </div>
        </div>

        {/* Date Section */}
        <div className="col-6">
          <div className="row align-items-center">
            <div className="col-6">
              <InputField
                label="Departure"
                id="depart"
                type="date"
                value={departureDay}
                onChange={(selectedDate) => setDepartureDay(selectedDate)}
                additionalInfo={departureDay && getDayName(departureDay)}
                minDate={today}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Search button */}
      <button
        className="px-3 py-2 border-0 text-white position-absolute"
        style={{
          backgroundColor: fromLocation && toLocation ? "#063E70" : "#A9A9A9",
          borderRadius: "10px",
          right: "10%",
          bottom: "-10%",
        }}
        onClick={handleSearchBuses}
        disabled={!fromLocation && !toLocation}
      >
        Search Buses <FaArrowRightLong />
      </button>
    </div>
  ));

  return (
    <>
      {!selectedCustomer ? (
        <CustomerList handleCustomerClick={setSelectedCustomer} />
      ) : showBookedTicket ? (
        <BookedTickets
          setShowBookedTicket={setShowBookedTicket}
          onItemSelect={() => setShowBookedTicket(false)}
        />
      ) : (
        <div className="container h-100 mt-1">
          <h3
            onClick={() => setShowBookedTicket(true)}
            className="text-end me-2"
            style={{
              fontFamily: "Montserrat",
              fontSize: "16px",
              letterSpacing: "1px",
              color: "black",
              cursor: "pointer",
              fontWeight: "600",
            }}
          >
            My Trips
          </h3>
          {isLoading ? (
            <div className="text-center mt-4">
              <div
                style={{ fontWeight: "600", fontSize: "20px" }}
                className="mb-0"
              >
                {fromLocation?.label}{" "}
                <IoIosSwap
                  className="mx-2 text-black"
                  style={{ fontSize: "24px" }}
                />{" "}
                {toLocation?.label}
              </div>
              <img
                style={{ maxWidth: "400px" }}
                src={tripRender}
                alt="loading..."
              />
            </div>
          ) : isSearchClicked ? (
            <>
              {!showSelectSeats && <BusSearchSection />}
              <AvailableTrips
                setShowSelectSeats={setShowSelectSeats}
                showSelectSeats={showSelectSeats}
                departureDay={departureDay}
                trips={trips}
                setTrips={setTrips}
                sourceId={sourceId}
                destinationId={destinationId}
                setIsSearchClicked={setIsSearchClicked}
              />
            </>
          ) : (
            <>
              <BusSearchSection />
              <div className="mt-3">
                <h2 style={{ fontSize: "20px" }}>Seasonal Offers</h2>
                <SeasonalOffers />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default BusBookHome;
