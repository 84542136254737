import React, { useEffect, useState } from "react";
import { paySprintCredit, paySprintDebit } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles, paySprintLogo } from "../images";

const VendorWallets = () => {
  const [creditBalance, setCreditBalance] = useState("0.00");
  const [debitBalance, setDebitBalance] = useState("0.00");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API({
      ...paySprintCredit,
      onSuccess: (res) => {
        if (res?.data?.data?.credit_balance) {
          setCreditBalance(res.data.data.credit_balance);
        }
      },
      onError: (error) =>
        console.error("Error fetching credit balance: ", error),
    });

    API({
      ...paySprintDebit,
      onSuccess: (res) => {
        if (res?.data?.data?.debit_balance) {
          setDebitBalance(res.data.data.debit_balance);
        }
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching debit balance: ", error);
        setLoading(false);
      },
    });
  }, []);

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-2"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-3"
          >
            Vendor Wallets
          </h2>
        </div>

        <div className="vendor-section px-3">
          <div className="vendor-header d-flex align-items-center gap-2">
            <img
              src={paySprintLogo}
              style={{ width: "80px" }}
              alt="PaySprint Logo"
              className="vendor-logo"
            />
          </div>
          <div className="d-flex justify-content-center gap-4 mt-4">
            <div className="wallet-card credit">
              <h3>Credit Balance</h3>
              <p>₹ {loading ? "Loading..." : creditBalance}</p>
            </div>
            <div className="wallet-card debit">
              <h3>Debit Balance</h3>
              <p>₹ {loading ? "Loading..." : debitBalance}</p>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .vendor-section {
          background: #f8f9fa;
          padding: 20px;
          border-radius: 12px;
          margin-top: 10px;
        }
        .vendor-header {
          display: flex;
          align-items: center;
        }
        .vendor-logo {
          width: 40px;
          height: auto;
        }
        .vendor-title {
          font-size: 20px;
          font-weight: 600;
        }
        .wallet-card {
          background: white;
          padding: 20px;
          border-radius: 12px;
          border: 2px solid #dadada;
          text-align: center;
          width: 250px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .wallet-card h3 {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #333;
        }
        .wallet-card p {
          font-size: 22px;
          font-weight: bold;
        }
        .credit p {
          color: #28a745;
        }
        .debit p {
          color: #dc3545;
        }
      `}</style>
    </>
  );
};

export default VendorWallets;
