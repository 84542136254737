import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "../components/api2";
import { getProfile, getQuestions, resetSecQues, verifyPass } from "../urls";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Header from "../components/Header";

const ResetSecurityQues = () => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [showSelectedQuestions, setShowSelectedQuestions] = useState(false);
  const [disableSelect, setDisableSelect] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getUsername();
  }, []);
  const getUsername = () => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setUsername(res.data.data.username);
        requestPassword();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const requestPassword = async () => {
    const result = await Swal.fire({
      title: "Enter Your Password",
      input: "password",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off",
      },
      showCancelButton: false,
      confirmButtonText: "Proceed",
      allowOutsideClick: false,
      showCloseButton: true,
      inputValidator: (value) => {
        return !value ? "Password is required!" : null;
      },
    });

    if (result.dismiss === Swal.DismissReason.close) {
      changePageFunc();
      return;
    }

    const enteredPassword = result.value;

    if (enteredPassword) {
      Swal.fire({
        title: "Authenticating...",
        text: "Please wait while we verify your password",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      API({
        ...verifyPass,
        body: {
          password: enteredPassword,
        },
        onSuccess: (res) => {
          Swal.close();
          setPassword(enteredPassword);
          setIsAuthenticated(true);
          Swal.fire({
            icon: "success",
            title: `${res.data.message}`,
          });
        },
        onError: (error) => {
          const errorMessage =
            error.response?.data?.message || "Something went wrong!";

          Swal.fire({
            icon: "error",
            title: "Authentication Failed",
            text: errorMessage,
            showCloseButton: true,
            confirmButtonText: "Retry",
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.close) {
              changePageFunc();
            } else {
              getUsername();
            }
          });

          if (
            errorMessage ===
            "Invalid username or password. Please check and try again."
          ) {
            setIsAuthenticated(false);
          }
        },
      });
    }
  };

  const fetchQuestions = () => {
    API({
      ...getQuestions,
      onSuccess: (res) => {
        setQuestions(res.data.data);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsVerifying(true);

    const indexedAnswers = selectedQuestions.reduce((acc, question, index) => {
      acc[`new_answer${index + 1}`] = answers[question.value] || "";
      return acc;
    }, {});
    const indexedQuestions = selectedQuestions.reduce(
      (acc, question, index) => {
        acc[`new_question${index + 1}`] = question.value;
        return acc;
      },
      {}
    );

    const postData = {
      username: username,
      ...indexedQuestions,
      ...indexedAnswers,
    };

    API({
      ...resetSecQues,
      body: postData,
      onSuccess: (res) => {
        setIsVerifying(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setIsVerifying(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "home",
      })
    );
    navigate("/", {
      state: {},
    });
  };

  if (!isAuthenticated) return null;

  return (
    <>
      <Header />
      <section className="bg-worker overflow-y-scroll flex-grow-1 position-relative container-fluid">
        <div className="px-4">
          <h2
            className="fw-semibold text-center mt-5"
            style={{ color: "rgba(79, 87, 91, 1)", fontSize: "20px" }}
          >
            {showSelectedQuestions
              ? "Provide Answers for Selected Questions"
              : "Select any 3 Questions and have some Answer"}
          </h2>
          <div className="col-8 mx-auto mt-4">
            {!showSelectedQuestions && (
              <form onSubmit={(e) => e.preventDefault()}>
                <Select
                  key={resetKey}
                  options={questions.map((question) => ({
                    value: question.questions,
                    label: question.questions,
                  }))}
                  isMulti
                  placeholder="Select any 3 questions"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "lightgray",
                      outline: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "#D9D9D9",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "black" : "#D9D9D9",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }),
                  }}
                  onChange={(selectedOptions) => {
                    setSelectedQuestions(selectedOptions);
                    setDisableSelect(selectedOptions.length === 3);
                    setShowResetButton(selectedOptions.length === 3);
                  }}
                  isDisabled={disableSelect}
                />
                {showResetButton && (
                  <div className="text-end mt-3">
                    <button
                      type="button"
                      className="border-0 text-white py-2 px-4 mt-4 col-2"
                      style={{
                        backgroundColor: "#FF0000",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setSelectedQuestions([]);
                        setDisableSelect(false);
                        setShowResetButton(false);
                        setResetKey(resetKey + 1);
                      }}
                    >
                      Reset
                    </button>
                  </div>
                )}
                {selectedQuestions.length === 3 && (
                  <div className="text-end mt-3">
                    <button
                      type="button"
                      className="border-0 text-white py-2 px-4 mt-4 col-2"
                      style={{
                        backgroundColor: "#194280",
                        borderRadius: "10px",
                      }}
                      onClick={() => setShowSelectedQuestions(true)}
                    >
                      Next
                    </button>
                  </div>
                )}
              </form>
            )}
            {showSelectedQuestions && (
              <div className="pt-3">
                <div
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  className="border-0 text-end"
                  onClick={() => {
                    setSelectedQuestions([]);
                    setDisableSelect(false);
                    setShowResetButton(false);
                    setResetKey(resetKey + 1);
                    setShowSelectedQuestions(false);
                  }}
                >
                  Change Questions
                </div>
                {selectedQuestions.map((question, index) => (
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      color: "#194280",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                    key={question.value}
                  >
                    <p className="mb-0 mt-3">{question.label}</p>
                    <input
                      type="text"
                      autoComplete="off"
                      className="p-2"
                      style={{ width: "100%" }}
                      value={answers[question.value] || ""}
                      onChange={(e) => {
                        setAnswers((prev) => ({
                          ...prev,
                          [question.value]: e.target.value,
                        }));
                        setIsFormValid(
                          selectedQuestions.every((q) => e.target.value.trim())
                        );
                      }}
                    />
                  </div>
                ))}
                <div className="text-center mt-2">
                  <button
                    type="submit"
                    className="border-0 text-white py-2 px-4 mt-4 col-3"
                    style={{
                      backgroundColor: "#194280",
                      borderRadius: "10px",
                      opacity: isVerifying || !isFormValid ? 0.5 : 1,
                    }}
                    onClick={handleSubmit}
                    disabled={isVerifying || !isFormValid}
                  >
                    {isVerifying ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetSecurityQues;
