import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Swal from "sweetalert2";
import API from "../components/api2";
import { backArrow, circles } from "../images";
import { getProfile, myNetworkWallet, myNetworkWalletPb } from "../urls";
import { changePage } from "../redux/slice/dashboardSlice";
import { connect, useDispatch } from "react-redux";
import loaderGif from "../gif/buffer.gif";
import Select from "react-select";

const MyNetworkInfo = ({ roles }) => {
  const location = useLocation();
  const service = location.state?.service || null;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasRole = (role) => roles.includes(role);

  const [message, setMessage] = useState("");
  const [rechargeAmount, setRechargeAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(null);
  const [empType, setEmpType] = useState("");

  const userTypeOptions = [
    { value: "Brand", label: "Brand" },
    { value: "Merchant", label: "Merchant" },
  ];

  const handleTransaction = (type) => {
    const amount = type === "withdraw" ? withdrawAmount : rechargeAmount;

    if (Number(amount) <= 0) {
      setMessage("Enter a valid amount.");
      return;
    }
    if (type === "withdraw" && Number(amount) > service.main_closing_balance) {
      setMessage("Insufficient balance for withdrawal.");
      return;
    }

    Swal.fire({
      title: `Are you sure you want to ${type} ₹${amount}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enter your 6-digit UPIN",
          input: "password",
          inputAttributes: {
            maxlength: 6,
            minlength: 6,
            autocapitalize: "off",
            autocorrect: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
        }).then((upinResult) => {
          if (upinResult.isConfirmed && upinResult.value) {
            const upin = upinResult.value;

            setLoading(true);

            const requestBody = {
              transaction_amount: amount,
              transaction_type: type === "withdraw" ? "withdrawal" : "recharge",
              upin,
              ...(empType === "PLATFORM"
                ? {
                    user_type: userType?.value,
                    ...(userType?.value === "Brand"
                      ? { brand_id: service.brand_id }
                      : { merchant_id: service.merchant_id }),
                  }
                : { merchant_id: service.merchant_id }),
            };

            API({
              ...(empType === "PLATFORM" || empType === "BRAND"
                ? myNetworkWalletPb
                : myNetworkWallet),
              body: requestBody,
              onSuccess: (res) => {
                setLoading(false);
                Swal.fire({
                  title: `${res.data.message}`,
                }).then(() => {
                  window.location.reload();
                });
                setMessage(`Transaction successful: ${type} ₹${amount}`);
                setRechargeAmount("");
                setWithdrawAmount("");
              },
              onError: (error) => {
                setLoading(false);
                setMessage("Transaction failed. Please try again.");
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          } else {
            setMessage("Transaction cancelled.");
          }
        });
      }
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "myNetwork",
      })
    );
    navigate("/");
  };

  useEffect(() => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setEmpType(res.data.data.employee_type);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, []);

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center  pb-4"
        >
          <img
            className="ps-2"
            style={{ cursor: "pointer", pointerEvents: "all" }}
            src={backArrow}
            alt="back"
            onClick={changePageFunc}
          />
          <h2
            className="mb-0 ps-3"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            My Network Information
          </h2>
        </div>
        {loading ? (
          <img
            src={loaderGif}
            alt="Loading..."
            style={{
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%)",
            }}
          />
        ) : (
          <div className="mt-4 pb-3">
            <h3
              className="px-4"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Merchant Info
            </h3>

            <div className="row mt-4 px-5">
              {empType === "PLATFORM" ? (
                <>
                  <div className="col-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Choose User Type
                    </p>
                    <Select
                      options={userTypeOptions}
                      value={userType}
                      placeholder="Select User Type"
                      onChange={setUserType}
                      className="mt-1"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "rgba(255, 231, 159,0.4)",
                          borderRadius: "10px",
                          border: "none",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        }),
                      }}
                    />
                  </div>

                  {userType?.value === "Brand" && (
                    <div className="col-4">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "600",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          color: "rgba(173, 131, 82, 1)",
                        }}
                      >
                        Brand ID
                      </p>
                      <input
                        type="text"
                        value={service?.brand_id}
                        className="p-2 w-100 mt-1"
                        style={{
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  )}

                  {userType?.value === "Merchant" && (
                    <div className="col-4">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "600",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          color: "rgba(173, 131, 82, 1)",
                        }}
                      >
                        Merchant ID
                      </p>
                      <input
                        type="text"
                        value={service?.merchant_id}
                        className="p-2 w-100 mt-1"
                        style={{
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="col-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Merchant ID
                  </p>
                  <input
                    type="text"
                    value={service?.merchant_id}
                    className="p-2 w-100 mt-1"
                    style={{
                      fontFamily: "Montserrat",
                    }}
                    readOnly
                  />
                </div>
              )}

              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Main Wallet Balance
                </p>
                <input
                  type="text"
                  className="p-2 w-100 mt-1"
                  value={`₹ ${
                    service?.main_closing_balance
                      ? service.main_closing_balance
                      : "0"
                  }`}
                  style={{
                    fontFamily: "Montserrat",
                  }}
                  readOnly
                />
              </div>

              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  AEPS Wallet Balance
                </p>
                <input
                  type="text"
                  className="p-2 w-100 mt-1"
                  value={`₹ ${
                    service?.aeps_closing_balance
                      ? service.aeps_closing_balance
                      : "0"
                  }`}
                  style={{
                    fontFamily: "Montserrat",
                  }}
                  readOnly
                />
              </div>
            </div>

            <div className="row mt-5 px-5">
              {hasRole("Network Recharge") && (
                <div className="col-4">
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "green",
                    }}
                  >
                    Recharge
                  </p>
                  <input
                    type="number"
                    className="p-2 w-100 mt-1"
                    placeholder="Enter amount"
                    value={rechargeAmount}
                    onChange={(e) => setRechargeAmount(e.target.value)}
                    style={{
                      backgroundColor: "rgba(200, 255, 200, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />

                  <button
                    onClick={() => handleTransaction("recharge")}
                    className="btn btn-success mt-3"
                    disabled={loading || (empType === "PLATFORM" && !userType)}
                    style={{
                      opacity:
                        loading || (empType === "PLATFORM" && !userType)
                          ? 0.6
                          : 1,
                    }}
                  >
                    Recharge
                  </button>
                </div>
              )}
              {hasRole("Network Withdrawal") && (
                <div className="col-4">
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    Withdrawal
                  </p>
                  <input
                    type="number"
                    className="p-2 w-100 mt-1"
                    placeholder="Enter amount"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                    style={{
                      backgroundColor: "rgba(255, 200, 200, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                  <button
                    onClick={() => handleTransaction("withdraw")}
                    className="btn btn-danger mt-3"
                    disabled={loading || (empType === "PLATFORM" && !userType)}
                    style={{
                      opacity:
                        loading || (empType === "PLATFORM" && !userType)
                          ? 0.6
                          : 1,
                    }}
                  >
                    Withdraw
                  </button>
                </div>
              )}
            </div>

            {message && (
              <p
                className="mt-4 px-5"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                {message}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: state.user.roles,
});

export default connect(mapStateToProps)(MyNetworkInfo);
