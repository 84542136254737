import React, { useEffect, useState } from "react";
import miniBusGif from "../../../../gif/miniBusGif.gif";
import { dottedLine } from "../../../../images";

const SelectLocation = ({
  selectedSeatNames,
  tripId,
  trips,
  selectedSeatFares,
  setProceedToPay,
  handleSelectedLocation,
}) => {
  const [activeTab, setActiveTab] = useState("boarding");
  const [selectedBoardingPoint, setSelectedBoardingPoint] = useState(null);
  const [selectedDroppingPoint, setSelectedDroppingPoint] = useState(null);
  const [boardingTimesData, setBoardingTimesData] = useState([]);
  const [droppingTimesData, setDroppingTimesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isProceedingToBook, setIsProceedingToBook] = useState(false);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      const currentTrip = trips.find((trip) => trip.id === tripId.tripId);

      const boardingTimes = Array.isArray(currentTrip?.boardingTimes)
        ? currentTrip.boardingTimes
        : [currentTrip?.boardingTimes];

      const droppingTimes = Array.isArray(currentTrip?.droppingTimes)
        ? currentTrip.droppingTimes
        : [currentTrip?.droppingTimes];

      setBoardingTimesData(boardingTimes);
      setDroppingTimesData(droppingTimes);

      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [tripId.tripId, trips]);

  const handleProceed = () => {
    if (isProceedingToBook) {
      setProceedToPay(true);
    } else if (activeTab === "boarding") {
      setActiveTab("dropping");
    } else if (activeTab === "dropping") {
      setIsProceedingToBook(true);
    }
  };
  const isButtonDisabled = () => {
    if (activeTab === "boarding" && !selectedBoardingPoint) {
      return true;
    }
    if (activeTab === "dropping" && !selectedDroppingPoint) {
      return true;
    }
    return false;
  };

  const canSwitchToDropping = () => {
    return !!selectedBoardingPoint;
  };

  const getTotalFare = () => {
    return selectedSeatFares
      .map((seat) => parseFloat(seat.fare) || 0)
      .reduce((total, fare) => total + fare, 0);
  };

  const styles = {
    container: {
      borderRadius: "5px",
      backgroundColor: "white",
      padding: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "320px",
    },
    tabs: {
      display: "flex",
      justifyContent: "space-around",
      marginBottom: "20px",
    },
    tab: (isActive, isDisabled) => ({
      border: "none",
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: "transparent",
      color: isActive ? "#023685" : "#333",
      transition: "border-bottom 0.3s ease, color 0.3s ease",
      fontWeight: "bold",
      borderBottom: isActive ? "3px solid #023685" : "3px solid transparent",
      borderRadius: "0px",
      opacity: isDisabled ? 0.5 : 1,
    }),
    tabContent: (isVisible) => ({
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translateY(0)" : "translateY(20px)",
      transition: "opacity 0.5s ease, transform 0.5s ease",
      display: isVisible ? "block" : "none",
      flexGrow: 1,
      marginTop: "10px",
    }),
    radioLabel: { marginLeft: "10px" },
    radioSelection: {
      marginBottom: "10px",
      fontSize: "1rem",
      fontWeight: "500",
    },
    proceedButton: {
      marginTop: "auto",
      padding: "10px 40px",
      backgroundColor: "#FFA201",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      alignSelf: "center",
      opacity: isButtonDisabled() ? 0.6 : 1,
      pointerEvents: isButtonDisabled() ? "none" : "auto",
    },

    proceedButtonHover: { backgroundColor: "#FFC000" },
    loader: { width: "100%" },
  };

  const handleSelectionChange = (boarding, dropping) => {
    setSelectedBoardingPoint(boarding);
    setSelectedDroppingPoint(dropping);
    handleSelectedLocation(boarding, dropping);
  };

  return (
    <div className="mt-2" style={styles.container}>
      {loading ? (
        <div className="text-center">
          <img src={miniBusGif} alt="Loading..." style={styles.loader} />
          <p
            className="mb-0 mt-2"
            style={{
              color: "#194280",
              fontFamily: "Montserrat",
              fontWeight: "600",
            }}
          >
            LOADING...
          </p>
        </div>
      ) : (
        <>
          <div style={styles.tabs}>
            {isProceedingToBook === false ? (
              <>
                <div
                  style={styles.tab(activeTab === "boarding", false)}
                  onClick={() => setActiveTab("boarding")}
                >
                  <p className="mb-0">Boarding Points</p>
                  <p
                    style={{ color: "#757575", fontSize: "13px" }}
                    className="mb-0 text-center"
                  >
                    Select Location
                  </p>
                </div>
                <div
                  style={styles.tab(
                    activeTab === "dropping",
                    !canSwitchToDropping()
                  )}
                  onClick={() =>
                    canSwitchToDropping() && setActiveTab("dropping")
                  }
                >
                  <p className="mb-0">Dropping Points</p>
                  <p
                    style={{ color: "#757575", fontSize: "13px" }}
                    className="mb-0 text-center"
                  >
                    Select Location
                  </p>
                </div>
              </>
            ) : (
              <div
                style={{ borderBottom: "1.5px solid black" }}
                className="d-flex justify-content-between align-items-center pb-2"
              >
                <p style={{ fontWeight: "600" }} className="mb-0">
                  Boarding & Dropping
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    color: "#194280",
                    cursor: "pointer",
                  }}
                  className="mb-0 ms-5"
                  onClick={() => setIsProceedingToBook(false)}
                >
                  Change
                </p>
              </div>
            )}
          </div>

          {/* boarding and dropping point selection  */}
          {isProceedingToBook === false ? (
            <>
              <div style={styles.tabContent(activeTab === "boarding")}>
                {activeTab === "boarding" && (
                  <div className="ms-3">
                    <div style={{ overflowY: "auto", maxHeight: "150px" }}>
                      {boardingTimesData.map((boardingPoint) => (
                        <div
                          key={boardingPoint.bpId}
                          style={styles.radioSelection}
                        >
                          <label>
                            <input
                              type="radio"
                              value={boardingPoint.bpId}
                              checked={
                                selectedBoardingPoint === boardingPoint.bpId
                              }
                              onChange={() =>
                                handleSelectionChange(
                                  boardingPoint.bpId,
                                  selectedDroppingPoint
                                )
                              }
                            />
                            <span className="mb-0 ms-3">
                              {boardingPoint.time}
                            </span>
                            <span style={styles.radioLabel}>
                              {boardingPoint.bpName}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div style={styles.tabContent(activeTab === "dropping")}>
                {activeTab === "dropping" && (
                  <div className="ms-3">
                    <div style={{ overflowY: "auto", maxHeight: "150px" }}>
                      {droppingTimesData.map((droppingPoint) => (
                        <div
                          key={droppingPoint.bpId}
                          style={styles.radioSelection}
                        >
                          <label>
                            <input
                              type="radio"
                              value={droppingPoint.bpId}
                              checked={
                                selectedDroppingPoint === droppingPoint.bpId
                              }
                              onChange={() =>
                                handleSelectionChange(
                                  selectedBoardingPoint,
                                  droppingPoint.bpId
                                )
                              }
                            />
                            <span className="mb-0 ms-3">
                              {droppingPoint.time}
                            </span>
                            <span style={styles.radioLabel}>
                              {droppingPoint.bpName}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                style={{ borderBottom: "1px solid #BBBBBB" }}
                className="ms-4"
              >
                <div className="d-flex">
                  <img
                    style={{ height: "100px" }}
                    src={dottedLine}
                    alt="joining-line"
                  />
                  <div className="d-flex flex-column justify-content-between">
                    <div className="d-flex ms-3">
                      <p
                        className="mb-0"
                        style={{ color: "#194280", fontWeight: "600" }}
                      >
                        {boardingTimesData
                          .filter(
                            (boardingPoint) =>
                              boardingPoint.bpId === selectedBoardingPoint
                          )
                          .map((boardingPoint) => boardingPoint.bpName)}
                      </p>
                      {boardingTimesData
                        .filter(
                          (boardingPoint) =>
                            boardingPoint.bpId === selectedBoardingPoint
                        )
                        .map((boardingPoint) => (
                          <div key={boardingPoint.bpId}>
                            <p
                              style={{ color: "#194280", fontWeight: "600" }}
                              className="mb-0 ms-1"
                            >
                              {boardingPoint.time}
                            </p>
                          </div>
                        ))}
                    </div>
                    <div className="d-flex ms-3">
                      <p
                        className="mb-0"
                        style={{ color: "#194280", fontWeight: "600" }}
                      >
                        {droppingTimesData
                          .filter(
                            (droppingPoint) =>
                              droppingPoint.bpId === selectedDroppingPoint
                          )
                          .map((droppingPoint) => droppingPoint.bpName)}
                      </p>
                      {droppingTimesData
                        .filter(
                          (droppingPoint) =>
                            droppingPoint.bpId === selectedDroppingPoint
                        )
                        .map((droppingPoint) => (
                          <div key={droppingPoint.bpId}>
                            <p
                              style={{ color: "#194280", fontWeight: "600" }}
                              className="mb-0 ms-1"
                            >
                              {droppingPoint.time}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p style={{ fontWeight: "500" }} className="mb-0">
                    Seat No
                  </p>
                  <p
                    style={{ fontWeight: "500" }}
                    className="seat-names-scroll mb-0"
                  >
                    {selectedSeatNames.join(", ")}
                  </p>
                </div>
              </div>

              <div className="text-center mt-3">
                <p
                  style={{
                    fontWeight: "500",
                    color: "#50555C",
                    fontSize: "14px",
                  }}
                  className="mb-0"
                >
                  Total Amount To be Paid{" "}
                  <span
                    className="ms-1"
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    ₹{getTotalFare()}
                  </span>
                </p>
              </div>
            </>
          )}

          <div className="text-center">
            <button
              style={styles.proceedButton}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor =
                  styles.proceedButtonHover.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor =
                  styles.proceedButton.backgroundColor)
              }
              onClick={handleProceed}
              disabled={isButtonDisabled()}
            >
              {isProceedingToBook ? "Proceed To Book" : "Proceed"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectLocation;
