import React, { useEffect, useState } from "react";
import {
  busCancellationPolicy,
  cancelTicket,
  cancelTicketData,
} from "../../../../urls";
import API from "../../../../components/api2";
import loaderGif from "../../../../gif/buffer.gif";
import Swal from "sweetalert2";
import { busIconCancel } from "../../../../images";
const CancelTicket = ({
  refId,
  setCancelTicket,
  bookingDetails,
  setShowModal,
}) => {
  const [cancellationDetails, setCancellationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [seatsToCancel, setSeatsToCancel] = useState([]);

  useEffect(() => {
    setLoading(true);
    API({
      ...cancelTicketData,
      body: { refid: refId },
      onSuccess: (res) => {
        setCancellationDetails(res?.data?.data);
        setLoading(false);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        }).then(() => {
          setCancelTicket(false);
          setShowModal(false);
        });
        setLoading(false);
      },
    });
  }, [refId]);

  const handleCheckboxChange = (seat) => {
    setSeatsToCancel((prevSeats) => {
      if (prevSeats.includes(seat)) {
        return prevSeats.filter((item) => item !== seat);
      } else {
        return [...prevSeats, seat];
      }
    });
  };

  const handleCancel = (e) => {
    if (seatsToCancel?.length === 0) {
      Swal.fire({
        icon: "error",
        title: `Please select a seat to cancel`,
      });
      return;
    }
    setLoading(true);

    API({
      ...cancelTicket,
      body: {
        refid: refId,
        seatsToCancel,
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          setCancelTicket(false);
          setShowModal(false);
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleCancellationPolicy = async () => {
    API({
      ...busCancellationPolicy,
      // params: { trip: tripType, offset: newOffset, limit: 10 },
      onSuccess: (res) => {},
      onError: (error) => {},
    });
  };

  return (
    <div
      style={{ borderRadius: "10px", height: "calc(100% - 200px)" }}
      className="bg-white pb-2 d-flex flex-column position-relative"
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img src={loaderGif} alt="Loading..." style={{ width: "50px" }} />
        </div>
      ) : (
        <>
          <div className="py-2" style={{ backgroundColor: "#194280" }}>
            <button
              className="ms-4"
              onClick={() => {
                setCancelTicket(false);
                setShowModal(false);
              }}
              style={{
                backgroundColor: "#194280",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
            >
              My Trips
            </button>
          </div>
          <div
            style={{
              borderRadius: "10px 10px 0px 0px",
              boxShadow: "0px 4px 4px 0px #0000001A",
            }}
            className="mx-4 px-4 py-3"
          >
            <div className="d-flex row justify-content-between">
              <div className="col-8">
                <div
                  className="p-3"
                  style={{ border: "1px solid #D9D9D9", borderRadius: "5px" }}
                >
                  {/* Trip Details */}
                  <div
                    style={{
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#194280",
                      }}
                    >
                      {bookingDetails.source_name} →{" "}
                      {bookingDetails.destination_name}{" "}
                      <span style={{ fontSize: "14px", color: "#777" }}>
                        {bookingDetails.doj}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "14px", color: "#333" }}>
                        {bookingDetails.booking_time} |{" "}
                        {bookingDetails.source_name}
                      </div>
                      <div style={{ fontSize: "14px", color: "#333" }}>
                        {bookingDetails.booking_time} |{" "}
                        {bookingDetails.destination_name}
                      </div>
                    </div>
                    <div className="mt-2 d-flex justify-content-between align-items-center">
                      <p
                        className="mb-0"
                        style={{ color: "#194280", fontWeight: "500" }}
                      >
                        {bookingDetails.bus_name}
                      </p>
                      <div
                        className="d-flex align-items-start justify-content-between px-3 py-1"
                        style={{ backgroundColor: "#FFE79F69", width: "344px" }}
                      >
                        <img src={busIconCancel} alt="bus-icon" />
                        <p
                          style={{ fontSize: "14px", color: "#5A5959" }}
                          className="mb-0 ms-2"
                        >
                          Bus and Tracking Details will be shared before on the
                          day of Journey on +91{bookingDetails.passenger_mobile}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Passenger Details */}
                  <div
                    style={{
                      padding: "10px 0",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      Passenger: {bookingDetails.passenger_name}
                    </div>
                    <div style={{ fontSize: "12px", color: "#777" }}>
                      {bookingDetails.passenger_gender}, Age{" "}
                      {bookingDetails.passenger_age}
                    </div>
                    <div style={{ fontSize: "14px", color: "#777" }}>
                      Seats:
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="checkbox"
                          id={`seat-${bookingDetails.seat_number}`}
                          value={bookingDetails.seat_number}
                          checked={seatsToCancel.includes(
                            bookingDetails.seat_number
                          )}
                          style={{ marginRight: "5px" }}
                          onChange={() =>
                            handleCheckboxChange(bookingDetails.seat_number)
                          }
                        />
                        <label
                          style={{ fontSize: "14px", marginTop: "4px" }}
                          htmlFor={`seat-${bookingDetails.seat_number}`}
                        >
                          {bookingDetails.seat_number}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Contact Information */}
                  <div
                    style={{
                      padding: "10px 0",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      Contact Information
                    </div>
                    <div style={{ fontSize: "12px", color: "#777" }}>
                      📧 {bookingDetails.email || "N/A"}
                    </div>
                    <div style={{ fontSize: "12px", color: "#777" }}>
                      📞 {bookingDetails.passenger_mobile || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              {/* Cancellation Details */}
              <div className="col-4">
                <div
                  className="px-3 pb-2"
                  style={{
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                    backgroundColor: "#ECF3FF",
                  }}
                >
                  {cancellationDetails && (
                    <>
                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          backgroundColor: "#ECF3FF",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "500",
                            color: "#4F575B",
                          }}
                        >
                          Ticket Refund Amount
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "14px",
                            color: "#777",
                            paddingTop: "8px",
                          }}
                        >
                          <span>Amount Paid</span>
                          <span>
                            ₹
                            {Array.isArray(cancellationDetails.fares.entry)
                              ? cancellationDetails.fares.entry
                                  .reduce(
                                    (total, fare) =>
                                      total + parseFloat(fare.value),
                                    0
                                  )
                                  .toFixed(2)
                              : parseFloat(
                                  cancellationDetails.fares.entry?.value || 0
                                ).toFixed(2)}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "14px",
                            color: "#777",
                            paddingTop: "6px",
                          }}
                        >
                          <span>Cancellation Charges</span>
                          <span>
                            ₹{cancellationDetails.totalCancellationCharge}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#50555C",
                            paddingTop: "8px",
                          }}
                        >
                          <span>Total Refund</span>
                          <span>₹{cancellationDetails.totalRefundAmount}</span>
                        </div>
                        <ul className="mt-3">
                          <li style={{ fontSize: "14px", color: "#50555C" }}>
                            Certain fees collected are not refundable
                          </li>
                          <li style={{ fontSize: "14px", color: "#50555C" }}>
                            Deductions are done as per{" "}
                            <span
                            // onClick={handleCancellationPolicy}
                            // style={{ cursor: "pointer", color: "#023685" }}
                            >
                              cancellation policy
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "15px",
                        }}
                      >
                        <button
                          onClick={handleCancel}
                          className="w-100"
                          style={{
                            backgroundColor: "#ff9800",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          Confirm Cancellation
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CancelTicket;
