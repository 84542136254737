import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import InputForm from "../components/InputForm";
import { fullScreen, smallScreen } from "../images";
import { FaPhone, FaEnvelope, FaRegEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  addTicket,
  ticketCategory,
  helpSelectedDropdownRefined,
  ticketAdditionalFields,
  getProfile,
  customDropdown,
} from "../urls";
import API from "./api2";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import loaderGif from "../gif/buffer.gif";
import "quill-table-ui/dist/index.css";
import QuillTableUI from "quill-table-ui";
import { fetchHtmlContent } from "../utils/CommonFunctions";
import { connect } from "react-redux";

Quill.register(
  {
    "modules/tableUI": QuillTableUI,
  },
  true
);

const HelpDeskModal = ({ show, onClose, username, isMenuVisible }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentCharacters, setCurrentCharacters] = useState(0);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  const [businessInfo, setBusinessInfo] = useState({});

  const [pageData, setPageData] = useState({
    subject: "",
    type: "",
    category: "",
    subcategory: "",
    ticket_desc: "",
    attachments: [],
  });

  const quillRef = useRef(null);

  // dropdowns for type, category, sub-category
  const handleTicketTypeChange = (selectedOption) => {
    setPageData({
      ...pageData,
      type: selectedOption.value,
      category: "",
      subcategory: "",
    });
    // handleResetTemplate();
    setCategoryOptions([]);
    setSubcategoryOptions([]);
    fetchCategories(selectedOption.value);
  };
  const handleCategoryChange = (selectedOption) => {
    setPageData({
      ...pageData,
      category: selectedOption ? selectedOption.value : "",
      subcategory: "",
    });
    setSubcategoryOptions([]);
    if (selectedOption) {
      fetchSubcategories(pageData.type, selectedOption.value);
    }
  };

  const handleSubcategoryChange = (selectedOption) => {
    const subcategoryValue = selectedOption ? selectedOption.value : "";
    setPageData({
      ...pageData,
      subcategory: subcategoryValue,
    });
  };
  useEffect(() => {
    const fetchType = () => {
      API({
        ...ticketCategory,
        onSuccess: (res) => {
          setTypeOptions(
            res.data.data.map((t) => ({
              value: t.type,
              label: t.type,
            }))
          );
        },
        onError: (error) => {
          console.error("Error fetching type:", error);
        },
      });
    };
    fetchType();
  }, []);

  const fetchCategories = (ticketType) => {
    API({
      ...helpSelectedDropdownRefined,
      params: { type: ticketType },
      onSuccess: (res) => {
        setCategoryOptions(
          res.data.data.category_data.map((category) => ({
            value: category.category,
            label: category.category,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    });
  };

  const fetchSubcategories = (type, category) => {
    API({
      ...helpSelectedDropdownRefined,
      params: { type: type, category: category },
      onSuccess: (res) => {
        setSubcategoryOptions(
          res.data.data.subcategory_data?.map((subcategory) => ({
            value: subcategory.subcategory,
            label: subcategory.subcategory,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching subcategories:", error);
      },
    });
  };

  // ===========================|| FETCH ADDITIONAL FIELDS OR SET SUBJECT OR TEMPLATE
  const fetchAdditionalFields = (type, category, subcategory) => {
    API({
      ...ticketAdditionalFields,
      params: { type, category, subcategory },
      onSuccess: (res) => {
        if (!res.data || !res.data.data) {
          console.error(
            "Invalid response structure from additional fields API"
          );
          return;
        }

        const responseData = res?.data.data;
        setHtmlData(responseData);
      },
      onError: (error) => {
        console.error("Error fetching additional fields:", error);
      },
    });
  };

  const setHtmlData = async (data) => {
    const { additional_fields, subject, template } = await data;
    let dataHtml = null;

    if (template) {
      const getHtml = await fetchHtmlContent(template);
      dataHtml = getHtml;
    }

    setAdditionalFields(additional_fields);
    setPageData((prev) => ({ ...prev, subject, ticket_desc: dataHtml }));

    const quill = quillRef.current.__quill;
    quill.clipboard.dangerouslyPasteHTML(dataHtml);
  };

  const handleResetTemplate = () => {
    setPageData((prev) => ({ ...prev, subject: "", ticket_desc: "" }));
    const quill = quillRef.current.__quill;
    quill.clipboard.dangerouslyPasteHTML("");
  };

  const fetchCustomDropdown = (
    selectedBranch,
    searchTerm = "",
    selectedCenter = null,
    searchType = ""
  ) => {
    const params = {};

    if (selectedBranch && selectedBranch.branch_id && !selectedCenter) {
      params.branch_id = selectedBranch.branch_id;
      params.search_center = searchTerm;
    }

    if (selectedCenter) {
      params.center_id = selectedCenter;

      if (searchType === "Client ID") {
        params.search_customer = searchTerm;
      } else if (searchType === "Loan ID") {
        params.search_loan = searchTerm;
      }
    }

    API({
      ...customDropdown,
      params: params,
      onSuccess: (res) => {
        if (!res.data || !res.data.data) {
          console.error("Invalid response structure from custom dropdown API");
          return;
        }

        const centersData = res.data.data.centers;
        const loan_data = res.data.data.loan_accounts;

        setDropdownData((prevDropdownData) => {
          const updatedDropdownData = {
            ...prevDropdownData,
            "Center ID":
              centersData?.map((center) => ({
                value: center.center_id,
                label: `${center.center_id} - ${center.center_name}`,
              })) || [],
          };

          if (selectedCenter) {
            updatedDropdownData["Client ID"] = loan_data.map((l) => ({
              value: l.cust_id,
              label: `Client ID: ${l.cust_id}`,
            }));

            updatedDropdownData["Loan ID"] = loan_data.map((l) => ({
              value: l.loan_id,
              label: `Loan ID: ${l.loan_id}`,
            }));
          }

          console.log("Updated Dropdown Data:", updatedDropdownData);
          return updatedDropdownData;
        });
      },
      onError: (error) => {
        console.error("Error fetching custom dropdown data:", error);
      },
    });
  };

  useEffect(() => {
    if (pageData.type && pageData.category && pageData.subcategory) {
      fetchAdditionalFields(
        pageData.type,
        pageData.category,
        pageData.subcategory
      );
    }
  }, [pageData.type, pageData.category, pageData.subcategory]);

  // attachments
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "image/gif",
      "video/mp4",
      "video/mpeg",
      "video/quicktime",
    ];

    const validFiles = files.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        Swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Some files were not uploaded because they are not a valid type (PDF, Word, Excel, image, or video).",
        });
        return false;
      }

      if (file.size > 5 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "File Size Exceeded",
          text: "Some files were not uploaded because they exceed the maximum file size of 5MB.",
        });
        return false;
      }

      return true;
    });

    setLoading(true);
    const uploadingFiles = validFiles.map((file) => ({
      file,
      isUploading: true,
    }));
    setUploadingFiles([...uploadingFiles]);

    const uploadPromises = uploadingFiles.map((uploadingFile, index) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadingFiles((prevFiles) =>
            prevFiles.map((file, idx) =>
              idx === index ? { ...file, isUploading: false } : file
            )
          );
          resolve();
        };
        reader.readAsDataURL(uploadingFile.file);
      });
    });

    Promise.all(uploadPromises).then(() => {
      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setLoading(false);
    });
  };

  const handleRemoveAttachment = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (file, index) => index !== indexToRemove
    );
    const updatedUploadingFiles = uploadingFiles.filter(
      (file, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
    setUploadingFiles(updatedUploadingFiles);
  };

  // toggle screen
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    if (quillRef.current && !quillRef.current.__quill) {
      const handleFileUpload = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute(
          "accept",
          "image/*,application/pdf,.doc,.docx,.xls,.xlsx,video/*"
        );
        input.click();

        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            const allowedTypes = [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "image/jpeg",
              "image/png",
              "image/gif",
              "video/mp4",
              "video/mpeg",
              "video/quicktime",
            ];

            if (!allowedTypes.includes(file.type)) {
              Swal.fire({
                icon: "error",
                title: "Invalid File Type",
                text: "Please upload a valid file type (PDF, Word, Excel, image, or video).",
              });
              return;
            }

            if (file.size > 5 * 1024 * 1024) {
              Swal.fire({
                icon: "error",
                title: "File Size Exceeded",
                text: "The file exceeds the maximum size limit of 5MB.",
              });
              return;
            }

            setLoading(true);
            const reader = new FileReader();
            reader.onload = (e) => {
              setSelectedFiles((prevFiles) => [...prevFiles, file]);
              setLoading(false);
            };
            reader.readAsDataURL(file);
          }
        };
      };

      const quill = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline"],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link", "image", "video", "table", "code-block"],
              [{ align: [] }],
              [{ reset: "Reset" }],
            ],
            handlers: {
              reset: handleResetTicketDesc,
              image: handleFileUpload,
              file: handleFileUpload,
            },
          },
          table: true,
          tableUI: true,
        },
        placeholder: "Write your description here...",
        clipboard: {
          matchVisual: false,
        },
      });

      quillRef.current.__quill = quill;

      const customButton = quillRef.current.querySelector(".ql-reset");
      if (customButton) {
        customButton.addEventListener("click", () => {
          quill.root.innerHTML = "";
          setPageData((prevData) => ({
            ...prevData,
            ticket_desc: "",
          }));
        });
      }

      quill.on("text-change", (delta, oldDelta, source) => {
        const text = quill.getText();
        const strippedText = quill.root.innerText.trim();
        const characters = strippedText.length;

        if (characters > 5000) {
          const excessCharacters = characters - 5000;
          quill.deleteText(5000, excessCharacters);
          Swal.fire({
            icon: "warning",
            title: "Character Limit Exceeded",
            text: "You have reached the maximum character limit (5000 characters).",
          });
        }

        setPageData((prevData) => ({
          ...prevData,
          ticket_desc: quill.root.innerHTML,
        }));
        setCurrentCharacters(characters);
      });
    }
  }, []);

  const isFormValid = () => {
    const requiredFieldsFilled =
      pageData.subject &&
      pageData.type &&
      pageData.category &&
      pageData.subcategory &&
      pageData.ticket_desc;

    const additionalFieldsValid = additionalFields?.every((field) => {
      return !!pageData[field.field_label];
    });

    return requiredFieldsFilled && additionalFieldsValid;
  };

  const handleResetTicketDesc = () => {
    setPageData({
      ...pageData,
      ticket_desc: "",
    });

    const quill = quillRef.current.__quill;
    quill.setText("");
  };

  const handleInputChange = (fieldLabel, value) => {
    setPageData((prevData) => ({
      ...prevData,
      [fieldLabel]: value,
    }));
  };
  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    const formData = new FormData();
    formData.append("category", pageData.category);
    formData.append("subcategory", pageData.subcategory);
    formData.append("subject", pageData.subject);
    formData.append("type", pageData.type);
    let ticketDescription = pageData.ticket_desc.replace(/<img[^>]*>/g, "");
    formData.append("ticket_desc", ticketDescription);

    Object.keys(pageData).forEach((key) => {
      if (key !== "type" && key !== "category" && key !== "subcategory") {
        formData.append(key, pageData[key]);
      }
    });

    selectedFiles.forEach((file) => {
      formData.append("attachments", file);
    });
    setLoading(true);

    API({
      ...addTicket,
      isFormData: true,
      body: formData,
      onSuccess: (res) => {
        setLoading(false);
        onClose();
        setTimeout(() => {
          Swal.fire({
            title: `${res.data.message}`,
          });
        }, 2000);
        setPageData({
          subject: "",
          type: "",
          ticket_desc: "",
          category: "",
          subcategory: "",
        });
        setSelectedFiles([]);
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  useEffect(() => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setBusinessInfo(res.data.data.business_info || {});
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, []);
  return (
    <>
      {showOptions ? (
        <Modal
          show={show}
          onHide={onClose}
          backdropClassName="modal-backdrop-transparent"
          className={`modal-right help-modal
         ${isFullScreen ? "full-screen" : ""} ${
            isMenuVisible && isFullScreen ? "menu-visible-full-screen" : ""
          }`}
          style={{ marginTop: "3px" }}
        >
          <div className="d-flex align-items-center justify-content-between mx-2 py-2">
            <div></div>
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "15px",
              }}
              className="mb-0 text-center pt-2"
            >
              Hi {""}
              <span style={{ color: "#F45858", fontFamily: "Montserrat" }}>
                {username}
              </span>
              , How can we help ?
            </p>
            <img
              style={{ width: "20px", cursor: "pointer", pointerEvents: "all" }}
              src={isFullScreen ? smallScreen : fullScreen}
              alt="fullscreen"
              onClick={toggleFullScreen}
            />
          </div>
          <Modal.Body
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {/* Call Us */}
            <a
              href={`tel:${businessInfo.contact_support_phone}`}
              style={{
                textDecoration: "none",
                color: "#28a745",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#E6F4EA",
                padding: "15px",
                borderRadius: "12px",
                width: "280px",
                fontWeight: "600",
                transition: "all 0.3s ease",
                marginBottom: "15px",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaPhone size={26} />
              <p style={{ marginTop: "8px" }}>
                {businessInfo.contact_support_phone || "0124-4002821"}
              </p>
            </a>

            {/* Mail Us */}
            <a
              href={`mailto:${
                businessInfo.contact_support_email ||
                "info.prayaspe@prayasfinance.com"
              }`}
              style={{
                textDecoration: "none",
                color: "#007bff",
                display: "flex",
                width: "280px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#E7F1FF",
                padding: "15px",
                borderRadius: "12px",
                fontWeight: "600",
                transition: "all 0.3s ease",
                marginBottom: "15px",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaEnvelope size={26} />
              <p style={{ marginTop: "8px" }}>
                {businessInfo.contact_support_email ||
                  "info.prayaspe@prayasfinance.com"}
              </p>
            </a>

            {/* Raise Ticket */}
            <div
              onClick={() => setShowOptions(false)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#EBDADA",
                padding: "15px",
                borderRadius: "12px",
                width: "280px",
                fontWeight: "600",
                transition: "all 0.3s ease",
                cursor: "pointer",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FaRegEdit size={26} color="#F45858" />
              <p style={{ marginTop: "8px", color: "#F45858" }}>Raise Ticket</p>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={show}
          onHide={onClose}
          backdropClassName="modal-backdrop-transparent"
          className={`modal-right help-modal
${isFullScreen ? "full-screen" : ""}
${isMenuVisible && isFullScreen ? "menu-visible-full-screen" : ""}`}
          style={{ marginTop: "3px" }}
        >
          <div className="d-flex align-items-center justify-content-between mx-2 py-2">
            <div></div>
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "15px",
              }}
              className="mb-0 text-center pt-2"
            >
              Hi {""}
              <span style={{ color: "#F45858", fontFamily: "Montserrat" }}>
                {username}
              </span>
              , How can we help ?
            </p>
            <img
              style={{ width: "20px", cursor: "pointer", pointerEvents: "all" }}
              src={isFullScreen ? smallScreen : fullScreen}
              alt="fullscreen"
              onClick={toggleFullScreen}
            />
          </div>
          <Modal.Body
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              padding: "0px 10px",
            }}
            className={isFullScreen ? "mx-4" : "mx-2"}
          >
            <div className="row px-2">
              {/* type */}
              <div className={isFullScreen ? "col-3 mt-2" : "col-6 mt-2"}>
                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="type"
                  className="form-label ms-2"
                >
                  Select Type
                </label>
                <Select
                  id="type"
                  name="type"
                  placeholder="Select Type"
                  value={
                    pageData.type
                      ? {
                          value: pageData.type,
                          label: pageData.type,
                        }
                      : null
                  }
                  onChange={handleTicketTypeChange}
                  options={typeOptions}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      padding: "2px 6px",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  isSearchable={false}
                />
              </div>
              {/* category */}
              <div className={isFullScreen ? "col-3 mt-2" : "col-6 mt-2"}>
                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="category"
                  className="form-label ms-2"
                >
                  Issue Category
                </label>
                <Select
                  id="category"
                  name="category"
                  placeholder="Select Category"
                  value={
                    pageData.category
                      ? {
                          value: pageData.category,
                          label: pageData.category,
                        }
                      : null
                  }
                  onChange={handleCategoryChange}
                  options={categoryOptions}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      padding: "2px 6px",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  isSearchable={false}
                />
              </div>

              {/* sub category */}
              <div className={isFullScreen ? "col-3 mt-2" : "col-6 mt-2"}>
                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="category"
                  className="form-label ms-2"
                >
                  Issue Sub Category
                </label>
                <Select
                  id="subcategory"
                  name="subcategory"
                  placeholder="Sub Category"
                  value={
                    pageData.subcategory
                      ? {
                          value: pageData.subcategory,
                          label: pageData.subcategory,
                        }
                      : null
                  }
                  onChange={handleSubcategoryChange}
                  options={subcategoryOptions}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      padding: "2px 6px",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  isSearchable={false}
                />
              </div>

              {/* subject */}
              <div className={isFullScreen ? "col-9 mt-2" : "col-12 mt-2"}>
                <InputForm
                  type={"text"}
                  placeholder={"Issue Subject"}
                  id={"subject"}
                  name="subject"
                  value={pageData.subject}
                  onChange={(e) =>
                    setPageData({ ...pageData, subject: e.target.value })
                  }
                />
              </div>

              {additionalFields?.map((field) => (
                <div key={field.id} className="mt-2">
                  {dropdownData[field.field_label] ||
                  ["Center ID", "Client ID", "Loan ID"].includes(
                    field.field_label
                  ) ? (
                    <>
                      <label
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgba(105, 102, 102, 1)",
                          fontFamily: "Montserrat",
                        }}
                        htmlFor={field.field_label}
                        className="form-label ms-2"
                      >
                        {field.field_label}
                      </label>
                      <span
                        style={{
                          color: "red",
                          marginLeft: "5px",
                        }}
                      >
                        *
                      </span>
                      {/* <Select
id={field.field_label}
name={field.field_label}
placeholder={`Select ${field.field_label}`}
value={
field.field_label === "Branch"
? {
value: pageData[field.field_label],
label: dropdownData["Branch"].find(
(item) =>
item?.branch_id === pageData[field.field_label]
)?.branch_name,
}
: pageData[field.field_label]
? {
value: pageData[field.field_label],
label: `${pageData[field.field_label]} `,
}
: null
}
onChange={(selectedOption) => {
handleInputChange(
field.field_label,
selectedOption ? selectedOption.value : ""
);

if (field.field_label === "Branch" && selectedOption) {
const selectedBranch = dropdownData["Branch"]?.find(
(option) =>
option.branch_name === selectedOption.value
);
fetchCustomDropdown(selectedBranch);
}

if (field.field_label === "Center ID" && selectedOption) {
const selectedBranch = dropdownData["Branch"]?.find(
(option) => option.branch_name === pageData["Branch"]
);

if (selectedBranch) {
fetchCustomDropdown(
selectedBranch,
"",
selectedOption.value
);
}
}
}}
onInputChange={(inputValue) => {
const fieldLabel = field?.field_label;

if (fieldLabel === "Center ID" && inputValue) {
const selectedBranch = dropdownData["Branch"]?.find(
(option) => option.branch_name === pageData["Branch"]
);

if (selectedBranch) {
fetchCustomDropdown(selectedBranch, inputValue);
}
} else if (
fieldLabel === "Client ID" ||
fieldLabel === "Loan ID"
) {
const selectedCenter = pageData["Center ID"];
if (selectedCenter) {
fetchCustomDropdown(
null,
inputValue,
selectedCenter,
fieldLabel
);
}
}
}}
required={true}
options={
field.field_label === "Center ID"
? dropdownData["Center ID"]
: field.field_label === "Client ID" ||
field.field_label === "Loan ID"
? dropdownData[field.field_label] || []
: dropdownData[field.field_label]?.map((option) => {
if (field.field_label === "Employee ID") {
return {
value: `${option.employee_id}`,
label: `${option.employee_id} - ${option.employee_name}`,
};
} else if (field.field_label === "Branch") {
return {
value: `${option.branch_id}`,
label: `${option.branch_id}-${option.branch_name} `,
};
} else {
return {
value:
option[
`${field.field_label.toLowerCase()}_name`
],
label:
option[
`${field.field_label.toLowerCase()}_name`
],
};
}
}) || []
}
styles={{
control: (provided) => ({
...provided,
backgroundColor: "rgba(255, 231, 159,0.4)",
padding: "2px 6px",
borderRadius: "10px",
border: "none",
boxShadow: "none",
"&:focus": {
outline: "none",
},
}),
}}
/> */}

                      <Select
                        id={field.field_label}
                        name={field.field_label}
                        placeholder={`Select ${field.field_label}`}
                        value={
                          // value changed
                          field.field_label === "Branch"
                            ? pageData[field.field_label] && {
                                value: pageData[field.field_label],
                                label: dropdownData["Branch"].find(
                                  (item) =>
                                    item.branch_id ===
                                    pageData[field.field_label]
                                )?.branch_name,
                              }
                            : pageData[field.field_label]
                            ? {
                                value: pageData[field.field_label],
                                label: `${pageData[field.field_label]} `,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          handleInputChange(
                            field.field_label,
                            selectedOption ? selectedOption.value : ""
                          );

                          if (
                            field.field_label === "Branch" &&
                            selectedOption
                          ) {
                            const selectedBranch = dropdownData["Branch"]?.find(
                              (option) =>
                                option.branch_id === selectedOption.value
                            );
                            fetchCustomDropdown(selectedBranch);
                          }

                          if (
                            field.field_label === "Center ID" &&
                            selectedOption
                          ) {
                            const selectedBranch = dropdownData["Branch"]?.find(
                              (option) =>
                                option.branch_id === pageData["Branch"]
                            );

                            if (selectedBranch) {
                              fetchCustomDropdown(
                                selectedBranch,
                                "",
                                selectedOption.value
                              );
                            }
                          }
                        }}
                        onInputChange={(inputValue) => {
                          const fieldLabel = field?.field_label;

                          if (fieldLabel === "Center ID" && inputValue) {
                            const selectedBranch = dropdownData["Branch"]?.find(
                              (option) =>
                                option.branch_id === pageData["Branch"]
                            );

                            if (selectedBranch) {
                              fetchCustomDropdown(selectedBranch, inputValue);
                            }
                          } else if (
                            fieldLabel === "Client ID" ||
                            fieldLabel === "Loan ID"
                          ) {
                            const selectedCenter = pageData["Center ID"];
                            if (selectedCenter) {
                              fetchCustomDropdown(
                                null,
                                inputValue,
                                selectedCenter,
                                fieldLabel
                              );
                            }
                          }
                        }}
                        required={true}
                        options={
                          field.field_label === "Center ID"
                            ? dropdownData["Center ID"]
                            : field.field_label === "Client ID" ||
                              field.field_label === "Loan ID"
                            ? dropdownData[field.field_label] || []
                            : dropdownData[field.field_label]?.map((option) => {
                                if (field.field_label === "Employee ID") {
                                  return {
                                    value: `${option.employee_id}`,
                                    label: `${option.employee_id} - ${option.employee_name}`,
                                  };
                                } else {
                                  return {
                                    value:
                                      option[
                                        `${field.field_label.toLowerCase()}_id`
                                      ],
                                    label: `${
                                      option[
                                        `${field.field_label.toLowerCase()}_id`
                                      ]
                                    } - ${
                                      option[
                                        `${field.field_label.toLowerCase()}_name`
                                      ]
                                    }`,
                                  };
                                }
                              }) || []
                        }
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "rgba(255, 231, 159,0.4)",
                            padding: "2px 6px",
                            borderRadius: "10px",
                            border: "none",
                            boxShadow: "none",
                            "&:focus": {
                              outline: "none",
                            },
                          }),
                        }}
                      />

                      {/* =====================================|| SELECT FIELD UPDATED ||===================================== */}
                      {/* <Select
id={field.field_label}
name={field.field_label}
placeholder={`Select ${field.field_label}`}
value={
pageData[field.field_label]
? {
value: pageData[field.field_label],
label: pageData[field.field_label],
}
: null
}
onChange={(selectedOption) => {
handleInputChange(
field.field_label,
selectedOption ? selectedOption.value : ""
);

if (field.field_label === "Branch" && selectedOption) {
const selectedBranch = dropdownData["Branch"]?.find(
(option) => option.branch_id === selectedOption.value
);
fetchCustomDropdown(selectedBranch);
}
console.log("here...", field.field_label);

if (field.field_label === "Center ID" && selectedOption) {
const selectedBranch = dropdownData["Branch"]?.find(
(option) => option.branch_id === pageData["Branch"]
);
console.log(dropdownData["Center ID"], "dropdrop");
if (selectedBranch) {
console.log("hreb", selectedBranch);

fetchCustomDropdown(
selectedBranch,
"",
selectedOption.value
);
}
}
}}
onInputChange={(inputValue) => {
const fieldLabel = field?.field_label;

if (fieldLabel === "Center ID" && inputValue) {
const selectedBranch = dropdownData["Branch"]?.find(
(option) => option.branch_name === pageData["Branch"]
);

if (selectedBranch) {
fetchCustomDropdown(selectedBranch, inputValue);
}
} else if (
fieldLabel === "Client ID" ||
fieldLabel === "Loan ID"
) {
const selectedCenter = pageData["Center ID"];
if (selectedCenter) {
fetchCustomDropdown(
null,
inputValue,
selectedCenter,
fieldLabel
);
}
}
}}
options={
field.field_label === "Center ID"
? dropdownData["Center ID"]
: field.field_label === "Client ID" ||
field.field_label === "Loan ID"
? dropdownData[field.field_label] || []
: dropdownData[field.field_label]?.map((option) => {
if (field.field_label === "Employee ID") {
return {
value: `${option.employee_id}`,
label: `${option.employee_id} - ${option.employee_name}`,
};
} else if (field.field_label === "Branch") {
return {
value: `${option.branch_id}`,
label: `${option.branch_id}-${option.branch_name} `,
};
} else {
return {
value:
option[
`${field.field_label.toLowerCase()}_name`
],
label:
option[
`${field.field_label.toLowerCase()}_name`
],
};
}
}) || []
}
styles={{
control: (provided) => ({
...provided,
backgroundColor: "rgba(255, 231, 159,0.4)",
padding: "2px 6px",
borderRadius: "10px",
border: "none",
boxShadow: "none",
"&:focus": {
outline: "none",
},
}),
}}
/> */}
                    </>
                  ) : (
                    <InputForm
                      type="text"
                      placeholder={`${field.field_label}`}
                      id={`${field.field_label}`}
                      name={`${field.field_label}`}
                      value={pageData[field.field_label] || ""}
                      onChange={(e) =>
                        handleInputChange(field.field_label, e.target.value)
                      }
                      asterisk={true}
                      required={true}
                    />
                  )}
                </div>
              ))}
            </div>

            {/* description */}
            <div className="mt-2 px-2">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="ticket_desc"
                className="form-label ms-2"
              >
                Description
              </label>

              <div
                ref={quillRef}
                style={{
                  backgroundColor: "#FFF6D8",
                  borderRadius: "14px",
                  minHeight: isFullScreen ? "257px" : "150px",
                }}
              ></div>
              <div style={{ fontSize: "14px", color: "red" }}>
                Character count: {currentCharacters}/5000
              </div>
            </div>

            {/* attachment */}
            <div className="mt-2 px-2">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="attachments"
                className="form-label ms-2"
              >
                Attach Document
              </label>
              <input
                type="file"
                id="attachments"
                name="attachments"
                multiple
                accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,video/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label
                htmlFor="attachments"
                className={"custom-upload-btn d-block col-8"}
              >
                Upload Supporting Documents
              </label>
              <div className="file-container">
                {selectedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="file-box"
                    style={{ position: "relative", cursor: "pointer" }}
                    onClick={() => handleRemoveAttachment(index)}
                  >
                    {file.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`file_${index}`}
                        className="uploaded-image"
                      />
                    ) : (
                      <div className="file-icon">
                        <i className="fa fa-file-text-o" aria-hidden="true"></i>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end align-items-center mt-2">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                className="mb-0"
                onClick={onClose}
              >
                cancel
              </p>

              <button
                className="border-0 text-white ms-3"
                style={{
                  backgroundColor: "rgba(61, 145, 137, 1)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "14px",
                  padding: "6px 18px",
                  opacity: isFormValid() ? 1 : 0.5,
                  cursor: isFormValid() ? "pointer" : "not-allowed",
                }}
                onClick={handleSubmit}
                disabled={!isFormValid() || isLoading}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
          {isLoading && (
            <img
              style={{
                position: "absolute",
                top: "45%",
                left: "50%",
                width: "60px",
                transform: "translate(-50%)",
              }}
              src={loaderGif}
              alt="Loading..."
            />
          )}
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  username: state.user.username,
  name: state.user.name,
});
export default connect(mapStateToProps)(HelpDeskModal);
