export async function discoverAvdm() {
  var GetCustomDomName = "127.0.0.1";
  var primaryUrl = "http://" + GetCustomDomName + ":";

  try {
    var protocol = window.location.href;
    if (protocol.indexOf("https") >= 0) {
      primaryUrl = "https://" + GetCustomDomName + ":";
    }
  } catch (e) {}

  const fetchData = async (i, oldPort) => {
    try {
      const res = await fetch(`${primaryUrl}${i}`, {
        method: "RDSERVICE",
        headers: {
          "Content-Type": "text/xml; charset=utf-8",
        },
        cache: "no-cache",
        mode: "cors",
      });

      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await res.text();
      return data;
      //   setData(responseData);
    } catch (error) {
      if (i === "8005" && oldPort == true) {
        // Retry with a new port value
        return await fetchData("11099", false);
      } else {
        throw error;
      }
    }
  };

  const thenFunc = (data, i) => {
    // document.getElementById("txtDeviceInfo").value = data;
    var finalUrl = primaryUrl + i.toString();
    var MethodInfo, MethodCapture;

    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(data, "text/xml");

    var CmbData1 = xmlDoc.querySelector("RDService").getAttribute("status");
    var CmbData2 = xmlDoc.querySelector("RDService").getAttribute("info");

    if (
      RegExp("\\b" + "Mantra" + "\\b").test(CmbData2) == true ||
      RegExp("\\b" + "Morpho_RD_Service" + "\\b").test(CmbData2) == true ||
      RegExp("\\b" + "SecuGen India Registered device Level 0" + "\\b").test(
        CmbData2
      ) == true ||
      RegExp(
        "\\b" + "Precision - Biometric Device is ready for capture" + "\\b"
      ).test(CmbData2) == true ||
      RegExp(
        "\\b" +
          "RD service for Startek FM220 provided by Access Computech" +
          "\\b"
      ).test(CmbData2) == true ||
      RegExp("\\b" + "NEXT" + "\\b").test(CmbData2) == true ||
      RegExp("\\b" + "IDEMIA_L1_RDSERVICE" + "\\b").test(CmbData2) == true ||
      RegExp(
        "\\b" + "RD service for L1 provided by Access Computech" + "\\b"
      ).test(CmbData2) == true
    ) {
      var interfaceElements = xmlDoc.querySelectorAll("Interface");
      var firstInterfacePath = interfaceElements[0].getAttribute("path");
      var secondInterfacePath = interfaceElements[1].getAttribute("path");

      if (RegExp("\\b" + "Mantra" + "\\b").test(CmbData2) == true) {
        console.log("mantra here");
        if (firstInterfacePath == "/rd/capture") {
          MethodCapture = firstInterfacePath;
        }
        if (secondInterfacePath == "/rd/capture") {
          MethodCapture = secondInterfacePath;
        }
        if (firstInterfacePath == "/rd/info") {
          MethodInfo = firstInterfacePath;
        }
        if (secondInterfacePath == "/rd/info") {
          MethodInfo = secondInterfacePath;
        }
      } else if (
        RegExp("\\b" + "Morpho_RD_Service" + "\\b").test(CmbData2) == true
      ) {
        MethodCapture = firstInterfacePath;
        MethodInfo = secondInterfacePath;
      } else if (
        RegExp("\\b" + "SecuGen India Registered device Level 0" + "\\b").test(
          CmbData2
        ) == true
      ) {
        MethodCapture = firstInterfacePath;
        MethodInfo = secondInterfacePath;
      } else if (
        RegExp("\\b" + "IDEMIA_L1_RDSERVICE" + "\\b").test(CmbData2) == true
      ) {
        MethodCapture = firstInterfacePath;
        MethodInfo = secondInterfacePath;
      } else if (
        RegExp(
          "\\b" + "Precision - Biometric Device is ready for capture" + "\\b"
        ).test(CmbData2) == true
      ) {
        MethodCapture = firstInterfacePath;
        MethodInfo = secondInterfacePath;
      } else if (
        RegExp(
          "\\b" +
            "RD service for Startek FM220 provided by Access Computech" +
            "\\b"
        ).test(CmbData2) == true
      ) {
        MethodCapture = firstInterfacePath;
        MethodInfo = secondInterfacePath;
      } else if (
        RegExp(
          "\\b" + "RD service for L1 provided by Access Computech" + "\\b"
        ).test(CmbData2) == true
      ) {
        MethodCapture = firstInterfacePath;
        MethodInfo = secondInterfacePath;
      } else if (RegExp("\\b" + "NEXT" + "\\b").test(CmbData2) == true) {
        MethodCapture = firstInterfacePath;
        MethodInfo = secondInterfacePath;
      }
      if (CmbData1 == "READY") {
        console.log();
        // $('#method').val(finalUrl + MethodCapture);
        // $('#info').val(finalUrl + MethodInfo);

        // document.querySelector("#method").value = finalUrl + MethodCapture;
        // document.querySelector("#info").value = finalUrl + MethodInfo;

        // alert("Device detected successfully");

        return {
          method: finalUrl + MethodCapture,
          info: finalUrl + MethodInfo,
          deviceInfo: CmbData2.replace(
            "RD service for Startek FM220 provided by Access Computech",
            "Startek FM220"
          )
            .replace("IDEMIA_L1_RDSERVICE", "MORPHO L1 device")
            .replace(
              "RD service for L1 provided by Access Computech",
              "Startek AST300"
            ),
        };
      } else if (CmbData1 == "USED") {
        // $('#method').val(finalUrl + MethodCapture);
        // $('#info').val(finalUrl + MethodInfo);

        document.querySelector("#method").value = finalUrl + MethodCapture;
        document.querySelector("#info").value = finalUrl + MethodInfo;

        // alert("Device detected successfully");

        return {
          method: finalUrl + MethodCapture,
          info: finalUrl + MethodInfo,
        };
      } else if (CmbData1 == "NOTREADY") {
        // alert("Device Not Discover");
        return false;
      }

      // alert("RDSERVICE Discover Successfully");
    }

    document.querySelector("select#ddlAVDM").selectedIndex = 0;
  };

  for (var i = 11100; i <= 11112; i++) {
    try {
      const data = await fetchData(i, true);
      const result = thenFunc(data, i);
      if (result) {
        return result;
      }
    } catch (error) {
      //   console.error(`Error fetching data for ${i}:`, error);
    }

    // return await fetchData(i, true)
    //   .then(thenFunc)
    //   .catch((error) => {
    //     // console.log("polk", error);
    //     // alert("Connection failed Please try again.");
    //   });
  }
  return null;
}

export async function CaptureAvdm(rd, options = { wadh: false }) {
  var DString = "";
  var device = "mantra";

  var strWadh = options?.wadh
    ? `wadh="18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw="`
    : ``;
  var strOtp = "";

  var XML =
    `<?xml version="1.0"?>
     <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" ${strWadh}/> ` +
    DString +
    '<CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>';

  var finUrl = rd["method"];
  var verb = "CAPTURE";

  try {
    const res = await fetch(finUrl, {
      method: "CAPTURE",
      headers: {
        "Content-Type": "text/xml; charset=utf-8",
      },
      body: XML,
      cache: "no-cache",
      mode: "cors",
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.text();

    if (device == "morpho") {
      var xmlString = new XMLSerializer().serializeToString(data); //morpho
    } else if (device == "mantra") {
      var xmlString = data; //mantra
      var xmlString = data; //mantra
    } else if (device == "secugen") {
      var xmlString = new XMLSerializer().serializeToString(data); //secugen
    } else if (device == "precision") {
      var xmlString = new XMLSerializer().serializeToString(data); //precision
    } else if (device == "startek") {
      var xmlString = new XMLSerializer().serializeToString(data); //startek
    } else if (device == "nextrd") {
      var xmlString = new XMLSerializer().serializeToString(data); //next rd
    } else if (device == "IDEMIA_L1_RDSERVICE") {
      var xmlString = new XMLSerializer().serializeToString(data);
    }

    var doc = data;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(doc, "application/xml");

    const respElement = xmlDoc.querySelector("Resp");

    const errInfo = respElement ? respElement.getAttribute("errInfo") : null;
    const errCode = respElement ? respElement.getAttribute("errCode") : null;

    if (errCode == 0) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(doc, "application/xml");

      const respElement = xmlDoc.querySelector("Resp");

      const Message = respElement ? respElement.getAttribute("errInfo") : null;

      // alert(Message);
      return xmlString;
    } else {
      alert("Capture Failed");
      // window.location.reload();
    }
  } catch (error) {
    alert(error);
  }
}
