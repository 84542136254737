import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { GrPowerReset } from "react-icons/gr";
import Swal from "sweetalert2";
import API from "../../../components/api2";
import { airtelBbpsHistory } from "../../../urls";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { bharatConnectLogo } from "../../../images";

const TransactionHistory = ({ service }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const handleSearch = () => {
    setIsLoading(true);

    const params = { service };
    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;

    API({
      ...airtelBbpsHistory,
      params,
      onSuccess: (res) => {
        setIsLoading(false);
        setTransactions(res?.data?.data || []);
      },
      onError: (error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
  };

  const handlePrint = (transaction) => {
    const printWindow = window.open("", "", "width=600,height=400");
    printWindow.document.write(
      `<html><head><title>Print Transaction</title></head><body>`
    );
    printWindow.document.write(`<h3>Transaction Details</h3>`);
    printWindow.document.write(
      `<p><strong>Ref ID:</strong> ${transaction.reference_id}</p>`
    );
    printWindow.document.write(
      `<p><strong>Customer Name:</strong> ${transaction.customer_name}</p>`
    );
    printWindow.document.write(
      `<p><strong>Amount:</strong> ₹${transaction.amount}</p>`
    );
    printWindow.document.write(
      `<p><strong>Service:</strong> ${transaction.service}</p>`
    );
    printWindow.document.write(
      `<p><strong>Operator:</strong> ${transaction.operator_name || "N/A"}</p>`
    );
    printWindow.document.write(
      `<p><strong>Date:</strong> ${transaction.created_on}</p>`
    );
    printWindow.document.write(`</body></html>`);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div
      style={{
        boxShadow: "0px 4px 40px 10px rgba(0, 0, 0, 0.15)",
        borderRadius: "20px",
        border: "1px solid rgba(217, 217, 217, 1)",
        padding: "15px",
      }}
    >
      <div
        className="py-3 px-3"
        style={{
          backgroundColor: "rgba(217, 217, 217, 1)",
          borderRadius: "20px 20px 0px 0px",
          textAlign: "center",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h3
            className="mb-0 login_text fw-bold"
            style={{ fontSize: "14px", fontFamily: "Montserrat" }}
          >
            Transaction History
          </h3>
          <img
            style={{ width: "60px" }}
            src={bharatConnectLogo}
            alt="bharatConnectLogo"
          />
        </div>
      </div>

      <div className="d-flex justify-content-center gap-3 mt-3">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control"
          style={{ maxWidth: "160px" }}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="form-control"
          style={{ maxWidth: "160px" }}
        />
        <button
          className="px-3 py-1 text-white"
          onClick={handleSearch}
          style={{
            backgroundColor: "#194280",
            border: "none",
            borderRadius: "5px",
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters
              className="spin"
              style={{ fontSize: "18px" }}
            />
          ) : (
            "Apply"
          )}
        </button>
        <GrPowerReset
          onClick={handleReset}
          style={{ fontSize: "18px", cursor: "pointer", marginTop: "10px" }}
        />
      </div>

      <div
        style={{
          height: "195px",
          overflowY: "auto",
          marginTop: "10px",
          padding: "10px",
        }}
        className="py-2"
      >
        {transactions.length > 0 ? (
          transactions.map((transaction, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "12px",
                marginBottom: "10px",
                backgroundColor: "#fff",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #eee",
                  paddingBottom: "6px",
                  marginBottom: "6px",
                }}
              >
                <strong style={{ fontSize: "14px", color: "#333" }}>
                  Ref ID: {transaction.reference_id}
                </strong>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    padding: "4px 8px",
                    borderRadius: "5px",
                    backgroundColor:
                      transaction.status === "SUCCESS"
                        ? "#d4edda"
                        : transaction.status === "PENDING"
                        ? "#fff3cd"
                        : "#f8d7da",
                    color:
                      transaction.status === "SUCCESS"
                        ? "#155724"
                        : transaction.status === "PENDING"
                        ? "#856404"
                        : "#721c24",
                  }}
                >
                  {transaction.status}
                </span>
              </div>

              <p style={{ marginBottom: "4px", fontSize: "14px" }}>
                <strong>Customer Name:</strong> {transaction.customer_name}
              </p>
              <p style={{ marginBottom: "4px", fontSize: "14px" }}>
                <strong>Amount:</strong> ₹{transaction.amount}
              </p>
              <p style={{ marginBottom: "4px", fontSize: "14px" }}>
                <strong>Service:</strong> {transaction.service}
              </p>
              <p style={{ marginBottom: "4px", fontSize: "14px" }}>
                <strong>Operator:</strong> {transaction.operator_name || "N/A"}
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "14px",
                    color: "#555",
                  }}
                >
                  <strong>Date:</strong> {transaction.created_on}
                </p>
                <LiaFileInvoiceDollarSolid
                  style={{
                    fontSize: "25px",
                    cursor: "pointer",
                    color: "#3886E1",
                  }}
                  onClick={() => handlePrint(transaction)}
                />
              </div>
            </div>
          ))
        ) : (
          <p
            className="text-center login_text"
            style={{
              fontSize: "14px",
              padding: "10px",
              backgroundColor: "#f8f9fa",
              borderRadius: "5px",
              fontFamily: "Montserrat",
            }}
          >
            No Record Found
          </p>
        )}
      </div>
    </div>
  );
};

export default TransactionHistory;
