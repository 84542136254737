import React, { useEffect, useState } from "react";
import API from "../../../components/api2";
import Select from "react-select";
import Modal from "react-modal";
import { serviceCircle } from "../../../images";
import { bbpsOperatorList, fetchGasBill, payLpgBill } from "../../../urls";
import { AiOutlineLoading3Quarters } from "react-icons/ai"; // Loading icon
import Swal from "sweetalert2";

Modal.setAppElement("#root");

const GasBill = () => {
  const [formData, setFormData] = useState({
    canumber: "",
    billers: null,
  });

  const [billersList, setBillersList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [isPaying, setIsPaying] = useState(false);

  useEffect(() => {
    API({
      ...bbpsOperatorList,
      params: { service: "LPG" },
      onSuccess: (res) => {
        const formattedBillers = res?.data?.data?.map((biller) => ({
          value: biller.id,
          label: biller.name,
        }));
        setBillersList(formattedBillers);
      },
      onError: (error) => {
        console.error("Error fetching operators:", error);
      },
    });
  }, []);

  const handleInputChange = ({ target: { name, value } }) =>
    setFormData((prev) => ({ ...prev, [name]: value }));

  const handleSelectChange = (selectedOption) =>
    setFormData((prev) => ({ ...prev, billers: selectedOption }));

  const handleFetchGasBill = () => {
    setIsSubmitting(true);
    API({
      ...fetchGasBill,
      body: {
        canumber: formData.canumber,
        operator: formData.billers?.value,
        operator_name: formData.billers?.label,
        service: "LPG",
      },
      onSuccess: (res) => {
        setBillDetails(res?.data?.data);
        setIsModalOpen(true);
        setIsSubmitting(false);
      },
      onError: (error) => {
        console.error("Error fetching gas bill:", error);
        setIsSubmitting(false);
      },
    });
  };

  const handlePay = () => {
    if (!navigator.geolocation) {
      Swal.fire(
        "Error!",
        "Geolocation is not supported by your browser.",
        "error"
      );
      return;
    }

    Swal.fire({
      title: "Enter UPIN",
      input: "password",
      inputAttributes: {
        maxlength: "6",
        autocapitalize: "off",
        autocorrect: "off",
        inputmode: "numeric",
      },
      showCancelButton: true,
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      inputValidator: (value) => {
        if (!value || value.length !== 6 || isNaN(value)) {
          return "Please enter a valid 6-digit UPIN.";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const upin = result.value;
        setIsPaying(true);

        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            API({
              ...payLpgBill,
              body: {
                canumber: billDetails.canumber,
                operator: billDetails.operator,
                operator_name: formData.billers?.label,
                amount: billDetails.amount,
                service: billDetails.service,
                latitude: latitude,
                longitude: longitude,
                mode: "online",
                upin: upin,
                bill_fetch: {
                  billAmount: billDetails.amount || "",
                  billnetamount: billDetails.amount || "",
                  billdate: billDetails.duedate || "",
                  dueDate: billDetails.duedate || "",
                  acceptPayment: true,
                  acceptPartPay: false,
                  cellNumber: billDetails.canumber || "",
                  userName: billDetails.name || "",
                },
              },
              onSuccess: (res) => {
                setIsModalOpen(false);
                Swal.fire({
                  title: `${res.data.message}`,
                }).then(() => {
                  window.location.reload();
                });
              },
              onError: (error) => {
                setIsPaying(false);
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          },
          (error) => {
            setIsPaying(false);
            Swal.fire(
              "Error!",
              "Unable to fetch location. Please enable location access.",
              "error"
            );
          }
        );
      }
    });
  };

  const isButtonDisabled = !formData.canumber || !formData.billers;

  return (
    <div className="mx-5 mt-5 px-2">
      <div className="row mb-3">
        <div className="col-7">
          <div
            className="px-4 position-relative"
            style={{
              backgroundColor: "#194280",
              borderRadius: "20px",
              padding: "20px",
            }}
          >
            <img
              src={serviceCircle}
              alt="circle"
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                width: "120px",
              }}
            />
            <h2
              className="text-white"
              style={{ fontSize: "18px", fontWeight: "500" }}
            >
              Book LPG Gas Cylinder
            </h2>
            <div className="mx-2 pb-4 col-6">
              <label
                className="ms-2 login_text"
                style={{
                  color: "#ADACAC",
                  fontSize: "14px",
                  opacity: formData.canumber ? 1 : 0,
                }}
              >
                Registered ID
              </label>
              <input
                type="number"
                name="canumber"
                value={formData.canumber}
                onChange={handleInputChange}
                placeholder="Enter mobile or ID number"
                style={{
                  backgroundColor: "transparent",
                  borderBottom: "1.5px solid #ADACAC",
                  width: "100%",
                  color: "white",
                  padding: "0px 8px",
                }}
                autoComplete="off"
                className="login_text"
              />

              <label
                className="ms-2 login_text"
                style={{
                  color: "#ADACAC",
                  fontSize: "14px",
                  opacity: formData.billers ? 1 : 0,
                  paddingTop: "10px",
                }}
              >
                Select Biller
              </label>
              <Select
                options={billersList}
                value={formData.billers}
                onChange={handleSelectChange}
                placeholder="Select Biller"
                className="recharge_select login_text"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "transparent",
                    borderWidth: "0 0 1.5px 0",
                    borderBottom: formData.billers
                      ? "1.5px solid white"
                      : "1.5px solid #ADACAC",
                    width: "100%",
                    color: "white",
                    borderRadius: "0px",
                    boxShadow: "none",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                }}
              />

              <div className="mt-4">
                <button
                  className="px-4 py-2 login_text w-100"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "500",
                    opacity: isButtonDisabled || isSubmitting ? 0.5 : 1,
                    cursor:
                      isButtonDisabled || isSubmitting
                        ? "not-allowed"
                        : "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleFetchGasBill}
                  disabled={isButtonDisabled || isSubmitting}
                >
                  {isSubmitting ? (
                    <AiOutlineLoading3Quarters
                      className="spin"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    "Proceed"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Confirmation */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          content: {
            width: "400px",
            margin: "auto",
            padding: "25px",
            borderRadius: "12px",
            height: "330px",
            textAlign: "center",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
            border: "none",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        {billDetails && (
          <div style={{ fontSize: "15px" }}>
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-medium">Name:</span>
              <span>{billDetails.name}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span>Consumer No:</span>
              <span>{billDetails.canumber}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-medium">Operator:</span>
              <span>{billDetails.operator_name}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-medium">Amount:</span>
              <span>₹{billDetails.amount}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-medium">Due Date:</span>
              <span>{billDetails.duedate}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-medium">Transaction ID:</span>
              <span>{billDetails.ad2}</span>
            </div>
          </div>
        )}
        <div className="mt-5 flex justify-center">
          <button
            className="px-3 py-2 text-white border-0"
            style={{
              backgroundColor: "#194280",
              width: "80px",
              fontWeight: "500",
              borderRadius: "8px",
            }}
            onClick={handlePay}
            disabled={isPaying}
          >
            {isPaying ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Pay"
            )}
          </button>
          <button
            className="px-3 py-2 text-white border-0 ms-3"
            onClick={() => setIsModalOpen(false)}
            style={{
              backgroundColor: "#194280",
              width: "80px",
              fontWeight: "500",
              borderRadius: "8px",
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default GasBill;
