import React, { useEffect, useState } from "react";
import API from "../../../components/api2";
import Select from "react-select";
import Modal from "react-modal";
import { bharatConnectLogo, serviceCircle } from "../../../images";
import {
  airtelBbpsOperatorList,
  fetchBill,
  payBill,
  validateBill,
} from "../../../urls";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Swal from "sweetalert2";
import TransactionHistory from "./TransactionHistory";

Modal.setAppElement("#root");

const Donation = () => {
  const [formData, setFormData] = useState({
    billers: null,
  });
  const [billersList, setBillersList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feSessionId, setFeSessionId] = useState("");
  const [referenceInputs, setReferenceInputs] = useState({});
  const [paymentAmount, setPaymentAmount] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    API({
      ...airtelBbpsOperatorList,
      params: { subcat: "DONATION" },
      onSuccess: (res) => {
        setFeSessionId(res?.data?.data?.fe_session_id);
        if (res?.data?.data?.data?.billers) {
          const formattedBillers = res?.data?.data?.data?.billers?.map(
            (biller) => ({
              value: biller.billerCode,
              label: biller.billerName,
              fetchAndPay: biller.billPayType.fetchAndPay,
              billerObject: biller,
            })
          );
          setBillersList(formattedBillers);
        }
      },
      onError: (error) => {
        console.error("Error fetching operators:", error);
      },
    });
  }, []);

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, billers: selectedOption }));
    const billerObject = selectedOption.billerObject;

    const initialInputs = {};
    billerObject?.references?.values?.forEach((ref) => {
      initialInputs[ref.apiKey] = "";
    });
    setReferenceInputs(initialInputs);
  };

  const handleInputChange = (key, value) => {
    setReferenceInputs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    if (!formData.billers) return;
    setIsSubmitting(true);

    const payload = {
      biller_object: formData.billers.billerObject,
      references: referenceInputs,
      billerid: formData.billers.value,
      fe_session_id: feSessionId,
      operator_name: formData.billers.label,
      service: "DONATION",
      ...(formData.billers.fetchAndPay === false && { paymentAmount }),
    };

    const apiToCall = formData.billers.fetchAndPay ? fetchBill : validateBill;

    API({
      ...apiToCall,
      body: payload,
      onSuccess: (res) => {
        setApiResponse(res?.data?.data);
        setIsModalOpen(true);
        setIsSubmitting(false);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setIsSubmitting(false);
      },
    });
  };

  const handlePay = () => {
    if (!apiResponse?.data) return;

    Swal.fire({
      title: "Enter UPIN",
      input: "password",
      inputPlaceholder: "Enter 6-digit UPIN",
      inputAttributes: {
        maxlength: "6",
        minlength: "6",
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value || value.length !== 6 || isNaN(value)) {
          return "Please enter a valid 6-digit UPIN";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(true);

        const payload = {
          biller: apiResponse?.data?.billerId,
          ...(formData.billers.fetchAndPay === false
            ? { customerName }
            : { customerName: apiResponse?.data?.customerName }),
          ...(formData.billers.fetchAndPay === false
            ? { amount: paymentAmount }
            : { amount: apiResponse?.data?.billAmount }),
          ccf2a: apiResponse?.data?.ccf2 || "",
          upin: result.value,
          fe_session_id: apiResponse?.data?.feSessionId,
          service: "DONATION",
          operator_name: formData.billers.label,
          partnerRefId: apiResponse?.data?.feSessionId,
          references: referenceInputs,
          labels: apiResponse.labels,
        };

        API({
          ...payBill,
          body: payload,
          onSuccess: (res) => {
            setIsSubmitting(false);
            setIsModalOpen(false);
            Swal.fire({
              title: `${res.data.message}`,
            }).then(() => {
              window.location.reload();
            });
          },
          onError: (error) => {
            setIsSubmitting(false);
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          },
        });
      }
    });
  };

  const isButtonDisabled =
    !formData.billers ||
    Object.values(referenceInputs ?? {}).every((value) => value === "") ||
    (formData.billers?.fetchAndPay === false &&
      (!customerName || !paymentAmount)) ||
    isSubmitting;

  return (
    <div className="mx-5 mt-4 px-2">
      <div className="row mb-3">
        <div className="col-5">
          <div
            className="px-4 position-relative"
            style={{
              backgroundColor: "#194280",
              borderRadius: "20px",
              padding: "20px",
            }}
          >
            <img
              src={serviceCircle}
              alt="circle"
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                width: "120px",
              }}
            />
            <div className="d-flex align-items-center">
              <h2
                className="text-white"
                style={{ fontSize: "20px", fontWeight: "500" }}
              >
                Donation
              </h2>
              <img
                style={{ width: "70px", marginLeft: "170px" }}
                src={bharatConnectLogo}
                alt="bharatConnectLogo"
              />
            </div>
            <div style={{ overflowY: "auto", height: "calc(100vh - 300px)" }}>
              <div className="mx-2 pb-2 col-6">
                <label
                  className="ms-2 login_text"
                  style={{
                    color: "#ADACAC",
                    fontSize: "14px",
                    opacity: formData.billers ? 1 : 0,
                    paddingTop: "10px",
                  }}
                >
                  Select Biller/ NGO
                </label>
                <Select
                  options={billersList}
                  value={formData.billers}
                  onChange={handleSelectChange}
                  placeholder="Select Biller/ NGO"
                  className="recharge_select login_text"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                      borderWidth: "0 0 1.5px 0",
                      borderBottom: formData.billers
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: "white",
                      borderRadius: "0px",
                      boxShadow: "none",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                  }}
                />

                {formData.billers?.billerObject?.references?.values?.map(
                  (ref, index) => {
                    const minLength = ref.validations?.minLength?.value || 1;
                    const maxLength = ref.validations?.maxLength?.value || 20;
                    const fixedLength =
                      ref.validations?.fixedLength?.value || null;
                    const errorMessage =
                      ref.validations?.maxLength?.errorMessage ||
                      ref.validations?.fixedLength?.errorMessage ||
                      "";

                    return (
                      <div key={index} className="mt-4">
                        <label className="text-white">{ref.label}</label>
                        <input
                          type={ref.type === "N" ? "number" : "text"}
                          value={referenceInputs[ref.apiKey] || ""}
                          onChange={(e) =>
                            handleInputChange(ref.apiKey, e.target.value)
                          }
                          className="form-control"
                          placeholder={`Enter ${ref.label}`}
                          minLength={minLength}
                          maxLength={maxLength}
                          style={{ textTransform: "uppercase" }}
                        />
                        {referenceInputs[ref.apiKey] &&
                          ((fixedLength &&
                            referenceInputs[ref.apiKey].length !==
                              fixedLength) ||
                            referenceInputs[ref.apiKey].length > maxLength) && (
                            <p style={{ color: "yellow", fontSize: "12px" }}>
                              {errorMessage}
                            </p>
                          )}
                      </div>
                    );
                  }
                )}
                {formData.billers?.fetchAndPay === false && (
                  <div className="mt-3">
                    <label className="text-white">Payment Amount</label>
                    <input
                      type="text"
                      value={paymentAmount}
                      onChange={(e) => setPaymentAmount(e.target.value)}
                      className="form-control"
                      placeholder="Enter Payment Amount"
                    />
                    <label className="text-white mt-3">Customer Name</label>
                    <input
                      type="text"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      className="form-control"
                      placeholder="Enter Customer Name"
                    />
                  </div>
                )}

                <div className="mt-5">
                  <button
                    className="px-4 py-2 login_text w-100"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      border: "none",
                      fontSize: "15px",
                      fontWeight: "500",
                      opacity: isSubmitting || isButtonDisabled ? 0.5 : 1,
                      cursor:
                        isSubmitting || isButtonDisabled
                          ? "not-allowed"
                          : "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleSubmit}
                    disabled={isSubmitting || isButtonDisabled}
                  >
                    {isSubmitting ? (
                      <AiOutlineLoading3Quarters
                        className="spin"
                        style={{ fontSize: "18px" }}
                      />
                    ) : (
                      "Proceed"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-7">
          <TransactionHistory service="DONATION" />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          content: {
            width: "950px",
            padding: "30px",
            borderRadius: "12px",
            height: "auto",
            textAlign: "center",
            backgroundColor: "#fff",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.3)",
            border: "none",
            animation: "fadeIn 0.3s ease-in-out",
            fontFamily: "Montserrat",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            position: "relative",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        <button
          onClick={() => setIsModalOpen(false)}
          style={{
            position: "absolute",
            top: "10px",
            right: "15px",
            background: "none",
            border: "none",
            fontSize: "35px",
            color: "#194280",
            cursor: "pointer",
          }}
        >
          &times;
        </button>

        <h2
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#333",
            fontFamily: "Montserrat",
            marginBottom: "20px",
          }}
        >
          Service Details
        </h2>

        {apiResponse?.data ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "12px",
              textAlign: "left",
              padding: "20px",
              overflowY: "auto",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              fontFamily: "Montserrat",
            }}
          >
            {apiResponse.service && (
              <div
                style={{
                  fontSize: "16px",
                  color: "#4F575B",
                  fontWeight: "500",
                }}
              >
                <strong
                  style={{
                    color: "#194280",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  Service:
                </strong>
                <br />
                {apiResponse.service}
              </div>
            )}

            {apiResponse.operator_name && (
              <div
                style={{
                  fontSize: "16px",
                  color: "#4F575B",
                  fontWeight: "500",
                }}
              >
                <strong
                  style={{
                    color: "#194280",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  Operator Name:
                </strong>
                <br />
                {apiResponse.operator_name}
              </div>
            )}

            {Object.entries(apiResponse.data).map(([key, value]) => (
              <div
                key={key}
                style={{
                  fontSize: "16px",
                  color: "#4F575B",
                  fontWeight: "500",
                }}
              >
                <strong
                  style={{
                    color: "#194280",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  {key.replace(/([A-Z])/g, " $1")}:
                </strong>
                <br />
                {value}
              </div>
            ))}
          </div>
        ) : (
          <p
            style={{
              color: "#888",
              fontSize: "15px",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            No data available
          </p>
        )}
        {apiResponse?.data && (
          <button
            onClick={handlePay}
            style={{
              marginTop: "20px",
              padding: "10px 25px",
              fontSize: "15px",
              backgroundColor: "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              fontWeight: "500",
              transition: "0.3s ease",
              opacity: isSubmitting ? 0.5 : 1,
              cursor: isSubmitting ? "not-allowed" : "pointer",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#218838")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#28a745")}
          >
            {isSubmitting ? (
              <AiOutlineLoading3Quarters
                className="spin"
                style={{ fontSize: "18px" }}
              />
            ) : (
              "Recharge"
            )}
          </button>
        )}
      </Modal>
    </div>
  );
};

export default Donation;
